import React from 'react';
import './style.css';
import { Icon, Col, Row, Avatar, Badge, Alert, Input } from 'antd';

class SidebarChatComponent extends React.Component{
  constructor(props) {
    super(props);
    this.chatCollapseON = this.chatCollapseON.bind(this);
    this.chatCollapseOff = this.chatCollapseOff.bind(this);
    this.chatShowOff = this.chatShowOff.bind(this);
    this.chatShowOn = this.chatShowOn.bind(this);
    this.groupShowOff = this.groupShowOff.bind(this);
    this.groupShowOn = this.groupShowOn.bind(this);
    this.state = {
      isOpen: true,
      chatWidth: 216,
      chatShow: true,
      groupShow: true,
      onChat: false,
      marginRight: 216,
    };
  };

  chatCollapseON() {
    // this.props.chatCollapseON()
    this.setState({
      isOpen: !this.state.isOpen,  
      chatWidth: 216
    });
  };

  chatCollapseOff() {
    // this.props.chatCollapseOff()
    this.setState({
      isOpen: !this.state.isOpen,  
      chatWidth: 70,
    });
  };
    
  chatShowOn() {
    this.setState({
      chatShow: true
    })
  }

  chatShowOff() {
    this.setState({
      chatShow: false
    })
  }

  groupShowOn() {
    this.setState({
      groupShow: true,
    })
  }

  groupShowOff() {
    this.setState({
      groupShow: false,
    })
  }    

  onChat = (e) => {
    this.setState({
      onChat: !this.state.onChat,
    })
  }

  chatClose = () => {
    this.setState({
      onChat: false
    })
  }

  render(){
    const groupChats = [{
      key: "group-1",
      imgMain: "https://uinames.com/api/photos/female/23.jpg",
      imgOther: "https://uinames.com/api/photos/female/14.jpg",
      name: "Менежмент групп",
      badge: null
    },{
      key: "group-2",
      imgMain: "https://uinames.com/api/photos/female/3.jpg",
      imgOther: "https://uinames.com/api/photos/female/9.jpg",
      name: "Маркетинг групп",
      badge: 1
    },
    {
      key: "group-3",
      imgMain: "https://uinames.com/api/photos/female/5.jpg",
      imgOther: "https://uinames.com/api/photos/female/7.jpg",
      name: "Маркетинг хэлтэс",
      badge: 1
    }];

    const chatUsers = [{
      key: "user-1",
      img: "https://uinames.com/api/photos/female/23.jpg",
      name: "Цэнгүүн",
      type: "Техник",
      badge: 3
    },
    {
      key: "user-2",
      img: "https://uinames.com/api/photos/male/5.jpg",
      name: "Дорж",
      type: "Систем",
      badge: 2
    },        
    {
      key: "user-3",
      img: "https://uinames.com/api/photos/male/13.jpg",
      name: "Энх",
      type: "Техник",
      badge: 0
    },  
    {
      key: "user-4",
      img: "https://uinames.com/api/photos/male/7.jpg",
      name: "Чингүүн",
      type: "Техник",
      badge: 0
    },
    {
      key: "user-5",
      img: "https://uinames.com/api/photos/male/3.jpg",
      name: "Өгөөдэй",
      type: "Техник",
      badge: 0
    },                    
    {
      key: "user-6",
      img: "https://uinames.com/api/photos/male/2.jpg",
      name: "Өгөөдэй",
      type: "Техник",
      badge: 0
    },
    {
      key: "user-7",
      img: "https://uinames.com/api/photos/female/2.jpg",
      name: "Аз жаргал",
      type: "Борлуулалт",
      badge: 0
    },
    {
      key: "user-8",
      img: "https://uinames.com/api/photos/male/8.jpg",
      name: "Шихихутаг",
      type: "Борлуулалт",
      badge: 0
    },
    {
      key: "user-9",
      img: "https://uinames.com/api/photos/female/8.jpg",
      name: "Хэрлэн",
      type: "Борлуулалт",
      badge: 5
    },        
    {
      key: "user-10",
      img: "https://uinames.com/api/photos/male/14.jpg",
      name: "Билгүүн",
      type: "Борлуулалт",
      badge: 4
    },  
    {
      key: "user-11",
      img: "https://uinames.com/api/photos/male/15.jpg",
      name: "Энх",
      type: "Техник",
      badge: 0
    },
    {
      key: "user-12",
      img: "https://uinames.com/api/photos/male/17.jpg",
      name: "Батхүү",
      type: "Техник",
      badge: 0
    },
    {
      key: "user-13",
      img: "https://uinames.com/api/photos/male/17.jpg",
      name: "Готов",
      type: "Техник",
      badge: 0
    },
    {
      key: "user-14",
      img: "https://uinames.com/api/photos/male/17.jpg",
      name: "Гомбо",
      type: "Техник",
      badge: 0
    },
    {
      key: "user-15",
      img: "https://uinames.com/api/photos/male/17.jpg",
      name: "Дорж",
      type: "Техник",
      badge: 0
    },
    {
      key: "user-16",
      img: "https://uinames.com/api/photos/male/17.jpg",
      name: "Дэлгэр",
      type: "Техник",
      badge: 0
    },    
    {
      key: "user-17",
      img: "https://uinames.com/api/photos/male/17.jpg",
      name: "Даш",
      type: "Техник",
      badge: 0
    }, 
    {
      key: "user-18",
      img: "https://uinames.com/api/photos/male/17.jpg",
      name: "Цэцэгээ",
      type: "Техник",
      badge: 0
    }, 
    {
      key: "user-19",
      img: "https://uinames.com/api/photos/male/17.jpg",
      name: "Энэ содон нэртэй баагий",
      type: "Техник",
      badge: 0
    },     
  ];


return(
<div className="sidebarChat" style={{ width: this.state.chatWidth }}>
    {
      this.state.onChat === false
      ?
      null
      :
      <div className="collapsedChat">
        <Col>
            <div className="chatWindowTop">
                <Col span={10}>
                    <img src="https://uinames.com/api/photos/female/26.jpg" alt="" className="chatWindowImg" />
                    <p>Батцэцэг</p>
                    <span>Маркетинг</span>
                </Col>
                <Col span={14}>
                    <ul className="chatWindowCtrl">
                        <li>
                            <Icon type="video-camera" />
                        </li>
                        <li>
                            <Icon type="phone" />
                        </li>
                        <li>
                            <Icon type="setting" />
                        </li>
                        <li>
                            <Icon type="close" onClick={this.chatClose} />
                        </li>
                    </ul>
                </Col>
            </div>
        </Col>
        <Row className="chatWindowBox">
            <Col span={18} className="chatWindowWrap">
                <Col span={4}>
                    <img src="https://uinames.com/api/photos/female/26.jpg" alt="" className="chatWindowImg" style={{ marginTop: 19 }}  />
                </Col>
                <Col span={20}>
                    <div className="chatBody" >
                        Сайн байна уу. Таны ажил сайн уу ?
                    </div> 
                </Col>
            </Col>
            <Col span={18} className="chatWindowWrap">
                <Col span={4}>
                    <img src="https://uinames.com/api/photos/female/26.jpg" alt="" className="chatWindowImg" style={{ marginTop: 19 }}  />
                </Col>
                <Col span={20}>
                    <div className="chatBody" >
                        Бид 2 #123654 тикет дээр хуваарилагдсан байна.
                    </div> 
                </Col>
            </Col>                            
            <Col span={18} className="chatWindowWrap">
                <Col span={4}>
                    <img src="https://uinames.com/api/photos/female/26.jpg" alt="" className="chatWindowImg" style={{ marginTop: 19 }}  />
                </Col>
                <Col span={20}>
                    <div className="chatBody" >
                        Бид 2 #123654 тикет дээр хуваарилагдсан байна.
                    </div> 
                </Col>
            </Col>                             
            <Col span={18} className="chatWindowWrap">
                <Col span={4}>
                    <img src="https://uinames.com/api/photos/female/26.jpg" alt="" className="chatWindowImg" style={{ marginTop: 19 }}  />
                </Col>
                <Col span={20} style={{ marginTop: 10 }}>
                    <div className="orderCard">
                      <div className="cardHeader">
                          <div className="sourceVendor">
                          <img src="https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Viber-20.png" alt=""/>
                          88974586
                          <Icon type="more" onClick={this.showModal} className="cardMore" />
                          </div> 
                      </div>
                      <div className="cardDetail">
                          <span className="cardDate">2019 - 06 - 01</span>
                          <span className="cardId">#153426</span>
                          <span className="cardPrice">35,000 ₮</span>
                          <span className="cardQ">12ш</span>
                          <Alert message="Бэлтгэгдэж байна" type="error" style={{ float: 'left' }} />
                      </div>
                      <Row className="cardFooter">
                          <Col span={16}>
                              <img src="https://uinames.com/api/photos/female/16.jpg" alt="" className="operatorImg" />
                              <span className="operatorName">Оператор 1</span>
                          </Col>
                          <Col span={8} style={{ textAlign: 'right' }}>
                              <span className="cardMoment">
                              <Icon type="clock-circle" />
                              1 мин
                              </span>
                          </Col>
                          </Row>
                      </div>
                </Col>
            </Col>                             
          </Row>
          <Row className="chatWindowBottom">
              <Col span={4}>
                  <img src="https://uinames.com/api/photos/male/16.jpg" alt="" className="chatWindowImg" />
              </Col>
              <Col span={20}>
                  <Input placeholder="Чат бичих" />
                  <div className="chatUser">
                    <ul className="chatUserInput">
                      <li>
                          <Icon type="audio" />
                      </li>
                      <li>
                          <Icon type="paper-clip" />
                      </li>
                      <li>
                          <Icon type="smile" />
                      </li>
                    </ul>
                  </div>
              </Col>
          </Row>                        
      </div>
    }  

    {
      this.state.chatWidth === 216
      ?
      <div>
          <div className="sideChatHeader">
              <div>
                  <h3>Ажлын чат</h3>
                  <ul className="sideToolkit">
                      <li>
                          <span>
                              <Icon type="setting" size={16}  />
                          </span>
                      </li>
                      <li>
                          <span>
                              <Icon type="edit" size={16} />
                          </span>
                      </li>                        
                  </ul>
              </div>
          </div>  
          <div className="sideSearch">
              <input type="text" placeholder="Хайх" className="sideInput"/>
          </div> 
      </div>
      :
      <div style={{ height: 80 }}></div>
    }     
    <div className="scrollable-Chat">
    {
      this.state.chatWidth === 216
      ?
      <div>
      {
        this.state.groupShow === true
        ?
        <div className="sideChatType" onClick={this.groupShowOff}>
          <h3>Групп чат</h3>
          <div className="collapseType">
            <Icon type="up" />
          </div>
        </div> 
        :
        <div className="sideChatType" onClick={this.groupShowOn}>
          <h3>Групп чат</h3>
          <div className="collapseType">
            <Icon type="down" />
          </div>
        </div> 
      }
      </div>
      :
      <div style={{ height: 40 }}></div>
    }                 
    <div className="sideGroups">
      {
          this.state.groupShow === true
          ?
          <div>
              {groupChats.map(gChat => (
              <Row className="sideChatUser" key={gChat.key}>
                  <Col span={6}>
                      <img src={gChat.imgMain} className="groupChatImg" alt="Avatar" />
                      <img src={gChat.imgOther} className="ChatImgMore"  alt="Avatar" />
                  </Col>
                  {
                      this.state.chatWidth === 216
                      ?
                      <Col span={15}>
                          <h3 className="chatGroupName">{gChat.name}</h3>
                      </Col>
                      :
                      null

                  }
                  {
                      this.state.chatWidth === 216
                      ?
                      <Col span={3}>
                          {
                              gChat.badge > 0
                              ?
                              <Badge
                              count={5}
                              className="chatGroupBadge"
                              />
                              :
                              null
                          }
                      </Col>
                      :
                      null

                  }
              </Row>))
              }
          </div>
          :
          null                                
      }          
    </div>                
    {
      this.state.chatWidth === 216
      ?
      <div>
      {
        this.state.chatShow === true
        ?
        <div className="sideChatType" onClick={this.chatShowOff}>
          <h3>Зурвас</h3>
          <div className="collapseType">
            <Icon type="up" />
          </div>
        </div> 
        :
        <div className="sideChatType" onClick={this.chatShowOn}>
          <h3>Зурвас</h3>
          <div className="collapseType">
            <Icon type="down" />
          </div>
        </div> 
      }
      </div>
      :
      <div style={{ height: 40 }}></div>
    }    
    <div className="sideChats" id="chatScroll">
      {
          this.state.chatShow === true
          ?
          <div>
              {chatUsers.map(cUser => (
              <Row className="sideChatUser" key={cUser.key} onClick={(e) => this.onChat(cUser.key)}>
                  <Col span={6}>
                      <Avatar src={cUser.img} className="chatUserImg" />
                  </Col>
                  {
                      this.state.chatWidth === 216
                      ?
                      <Col span={15}>
                          <h3 className="chatUserName">{cUser.name}</h3>
                          <span className="chatUserType">{cUser.type}</span>
                      </Col>
                      :
                      null

                  }
                  {
                      this.state.chatWidth === 216
                      ?
                      <Col span={3}>
                          {
                              cUser.badge > 0
                              ?
                              <Badge
                              count={cUser.badge}
                              className="chatUserBadge"
                              />
                              :
                              null
                          }
                      </Col>
                      :
                      null

                  }
              </Row>))
              }
          </div>
          :
          null                                
      }          
      </div>
    </div>   
    <div className="chatBottom">
      <div className="chatCollapse">
      {
          this.state.isOpen === true
          ?

          <button onClick={this.chatCollapseOff} className="chatCollapseBtn">
              <Icon type="right" />
          </button>
          :
          <button  onClick={this.chatCollapseON} className="chatCollapseBtn">
              <Icon type="left" />
          </button>
      }
      </div>
    </div>              
  </div>

)
}

};

export default SidebarChatComponent;
