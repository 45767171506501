import React from 'react';
import { Layout, Row, Col, Input, Icon, Button, Modal, message } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import './style.css';
import CallProLogo from '../../../assets/logo@2x.png';
import homeImg from '../../../assets/hero.png';
import heroSVG from '../../../assets/hero-wave.svg';
import fbSVG from '../../../assets/icons/fb.svg';
import googleSVG from '../../../assets/icons/google.svg';
import iconAll from '../../../assets/icons/icon-all.svg';
import iconCheck from '../../../assets/icons/icon-check.svg';
import iconFeedback from '../../../assets/icons/icon-feedback.svg';
import iconKnowledge from '../../../assets/icons/icon-knowledge.svg';
import iconReport from '../../../assets/icons/icon-report.svg';
import iconWorkflow from '../../../assets/icons/icon-workflow.svg';
import axios from 'axios';
import { CONN } from '../../../constant/types';
import SendBirdWidget from './../../../components/SidebarChatComponent/index';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = ({
      Username: '',
      visible: false,
      loginForm: true,
      forgetForm: false,
      onUsername: '',
      password: '',
      onPassword: '',
      token: '',
      visibleUserPanel: false,
    })
  }

  showVideo = () => {
    this.setState({
      visible: true,
    });
  };

  closeVideo = () => {
    this.setState({
      visible: false
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this.checkLoginStatus();
  }

  checkLoginStatus() {
    const token = localStorage.getItem('token');

    if (token && token !== null && token != "" && typeof token !== 'undefined') {
      console.log('YOU ARE LOGGED IN');

      var me = this;

      axios({
        method: 'GET',
        url: `${CONN}/check_session`,
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      })
        .then(function (response) {
          var r = response.data;
          console.log('LOGINOOO', r);

          if(r.status == 'ok') {
            console.log('YOU ARE LOGGED IN AND TOKEN ALIVE');


            me.setState({
              loginForm: false,
              visibleUserPanel: true,
            })            
          } else {
            console.log('YOU ARE LOGGED IN BUT TOKEN DEAD!');
            // me.logout();
          }
        })
        .catch(function (error) {
          console.log(error);

          if(error && error.response && typeof error.response.status !== undefined && error.response.status == 401){
            // me.logout();
          }
        })
        .finally(function () {
          // always executed
        });
    } else {
      console.log('YOU ARE NOT LOGGED IN!!!!!!!!!!!!');
    }
  }

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  goDashboard = () => {
    this.props.history.push({
      pathname: '/admin/ticket/list',
    });
  }

  loginCRM = e => {
    e.preventDefault()
    console.log(this.state)
    axios
      .post(`${CONN}/login`, this.state)
      .then((response) => {
        //console.log(response);
        this.setState({ token: response.data.access_token });
        console.log("USER: " + JSON.stringify(response.data))
        localStorage.setItem('token', response.data.access_token)
        localStorage.setItem('local_number', response.data.user.local_number);
        localStorage.setItem('user_id', response.data.user.id);
        localStorage.setItem('sb_id', response.data.user.sb_id);
        localStorage.setItem('user_name', response.data.user.name);


        
        if (response.data.access_token != null) {
          // this.props.history.push({
          //   pathname: '/admin/ticket/dashboard',
          // });

          const config = {
            headers: {
              'Authorization': `Bearer  ${response.data.access_token}`
            }
          };

          axios
          .get(`${CONN}/ticket_statuses`,config)
          .then(response => {
            console.log(response.data)
            localStorage.setItem("statuses", JSON.stringify(response.data))
            //Төрөлийн мэдээллийг авах
            axios
            .get(`${CONN}/ticket_topics`,config)
            .then(response => {
              localStorage.setItem("topics", JSON.stringify(response.data))
              //Түвшний мэдээллийг авах
              axios
              .get(`${CONN}/ticket_topics`,config)
              .then(response => {
                localStorage.setItem("priorities", JSON.stringify(response.data))
                //Source_id мэдээллийг авах
                axios
                .get(`${CONN}/sources`,config)
                .then(response => {
                  localStorage.setItem("sources", JSON.stringify(response.data))
                  let users=[];
                  axios
                  .get(`${CONN}/users`,config)
                  .then(response=>{
                    console.log(response)
                    users.push(...response.data.data)
                    console.log(users)
                    if(response.data.meta.last_page > 2){
                    for(var i=2; i<=response.data.meta.last_page; i++){
                      axios
                      .get(`https://crm.onlime.mn/backend/api/v1/users?page=${i}`, config)
                      .then(response=>{
                        users.push(...response.data.data)
                        console.log(users)
                        localStorage.setItem('users', JSON.stringify(users))
                      })
                      if(i <= response.data.meta.last_page){
                        axios
                        .get(`${CONN}/department`, config)
                        .then((res) => {
                          localStorage.setItem("departments", JSON.stringify(res.data.data))
                          const user_id = localStorage.getItem('sb_id');
                          const user_name = localStorage.getItem('user_name')
                          SendBirdWidget.startWithConnect('19F90214-F3A3-4338-AF57-529A98CFE914', user_id, user_name);
                          this.goDashboard();
                        })
                      }
                    }}else{
                      localStorage.setItem('users', JSON.stringify(users))
                      axios
                      .get(`${CONN}/department`, config)
                      .then((res) => {
                        localStorage.setItem("departments", JSON.stringify(res.data.data))
                        this.goDashboard();
                      })
                    }
                    
                  })
                  //getting departments
                })
              })
            })
          })
        }
        else {
          message.info('Invalid username or password. Please try again!');
        }
      })
      .catch((error) => {
        console.log(error);
        message.info('Invalid username or password. Please try again!');
      })
  }

  handleUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  forgetPassword = () => {
    this.setState({
      loginForm: false,
      forgetPassword: true
    })
  }

  successSend = () => {
    message.success('Амжилттай email илгээгдлээ. Та inbox-оо шалгаарай.');
    this.setState({
      loginForm: true,
      forgetPassword: false
    })
  }

  render() {

    const homeFeature = [{
      key: "homeFeature-1",
      icon: iconAll,
      title: "All in One",
      desc: "Дуудлага, мессеж, сошиал сувгууд гэх мэт дурын сувгаас хүсэлт, захилга үүсгэх боломжтой",
    }, {
      key: "homeFeature-2",
      icon: iconCheck,
      title: "Захиалга",
      desc: "Харилцагч тус бүрийн захиалгыг бүртгэж авахаас эхлээд захиалга эзэндээ очих хүртэлх процессийг бүртгэнэ",
    }, {
      key: "homeFeature-3",
      icon: iconFeedback,
      title: "Санал хүсэлт",
      desc: "Харилцагчид үзүүлж буй үйлчилгээнд тулгарсан асуудлыг олон сувгаар цаг алдалгүй хүлээн авч шийдвэрлэх хүртэлх процессийг бүртгэнэ",
    }, {
      key: "homeFeature-4",
      icon: iconWorkflow,
      title: "Workchat",
      desc: "Дотоод ажилчдын work messaging хамтад нь шийдэж өгсөн. Ажилчид хоорондоо чатлах, видзе хурал хийх боломжтой",
    }, {
      key: "homeFeature-5",
      icon: iconReport,
      title: "Тайлан",
      desc: "Их хэмжээний мэдээлэл цугларах тул тухайн мэдээлэлд дүн шинжилгээ хийж олон төрлийн тайланг гарган харуулах, татаж авах, анализ хийх боломжтой",
    }, {
      key: "homeFeature-6",
      icon: iconKnowledge,
      title: "Мэдлэгийн сан",
      desc: "Урьдчилж бэлдсэн санг ашиглах тул асуудал тулгарахад эксперт шаардахгүйгээр шийдэх боломжтой"
    }]

    return (
      <Layout className="homeApp">
        {/* <div className="homeTopbar">
          <Link to="/">
            <img src={CallProLogo} alt="" className="topBarLogo" />
          </Link>
          <ul className="homeTopRight">
            <li>
              <Link to="about" className="homeLinkGhost">Тухай</Link>
            </li>
            <li>
              <Link to="contact">Холбогдох</Link>
            </li>
          </ul>
        </div> */}
        <div className="homeHero">
          <div className="heroWrap">
            <img src={homeImg} alt="" className="heroImg" />
            <img src={heroSVG} alt="" className="heroWave" />
          </div>
          <div className="homeInfo">
            <Col span={8} offset={2} className="homeCallPro">
              <img src={CallProLogo} alt="Call Pro CRM" />
              <p className="homeDesc">
                Харилцаа холбооны боломжуудыг нэг дороос ашиглах бүрэн боломжийг олгосон CRM таны ажлыг хөнгөвчилж, цаг хэмнэн, харилцагчдаа халамжлахад тусална.
            </p>
              <Col span={24}>
                <Link to="contact" className="homeCallBtn">
                  Холбогдох
              </Link>
              </Col>
              <Col span={24}>
                <span className="showVideo" onClick={this.showVideo}>
                  <Icon type="play-circle" />
                  Танилцуулга видео үзэх
              </span>
              </Col>
            </Col>
            <Col span={6} offset={6}>
              {
                this.state.loginForm === true
                  ?
                  <div className="loginForm">
                    <ul className="socialList">
                      <li>
                        <Link to="">
                          <img src={fbSVG} alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src={googleSVG} alt="" />
                        </Link>
                      </li>
                    </ul>
                    <div className="loginheader">
                      Эсвэл
              </div>
                    <div className="loginInput">
                      <Input name="email" onChange={this.changeHandler} size="large" placeholder="N-мэйл хаяг"
                        suffix={
                          <Icon type="mail" style={{ color: '#60698E' }} />
                        }
                      />
                      <Input type="password" name="password" size="large" onChange={this.changeHandler} placeholder="Нууц үг" style={{ marginTop: 8 }}
                        suffix={<Icon type="lock" style={{ color: '#60698E' }} />}
                        onPressEnter={this.loginCRM}
                      />
                      <Button block className="loginConfirm" size="large" block
                        onClick={this.loginCRM}
                      >Нэвтрэх</Button>
                      <div className="loginReset">
                        <Link to="" onClick={this.forgetPassword}>Нууц үг сэргээх</Link>
                      </div>
                    </div>
                  </div>
                  :
                  null
              }
              {
                this.state.forgetPassword == true
                  ?
                  <div className="loginForm">
                    <div className="loginInput">
                      <div className="passHeader loginheader">
                        Нууц үг сэргээх
                </div>
                      <Input size="large" placeholder="N-мэйл хаяг"
                        suffix={
                          <Icon type="mail" style={{ color: '#60698E' }} />
                        }
                      />
                      <Button block className="passReset" size="large" block
                        onClick={this.successSend}
                      >Сэргээх</Button>
                    </div>
                  </div>
                  :
                  null
              }

              {
                this.state.visibleUserPanel == true
                  ?
                  <div className="loginForm">
                    <div className="loginheader">
                      <div><b>{localStorage.getItem('local_number')}</b> та нэвтэрсэн байна</div>
                      <a onClick={this.goDashboard}>Энд дарж шилжинэ үү</a>
                    </div>
                  </div>
                  :
                  null
              }
            </Col>
          </div>
        </div>
        <Row className="homeRow">
          <Col span={24}>
            <h2 className="homeHeading">Яагаад Onlime гэж?</h2>
            <div className="homeGrid">
              <Row>
                {
                  homeFeature.map(fList => (
                    <Col span={8} gutter="md" className="GridRow">
                      <div className="GridList">
                        <img src={fList.icon} alt={fList.title} />
                        <h3>{fList.title}</h3>
                        <p>{fList.desc}</p>
                      </div>
                    </Col>
                  ))
                }
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="homeFooter">
          <Col span={12}>
            <img src={CallProLogo} alt="Call Pro CRM" className="footerLogo" />
            <span className="homeCopyright">| Copyright &copy; 2020 Onlime</span>
          </Col>
          <Col span={12}>
            <ul className="footerSocial">
              <li>
                <a href="#">
                  <Icon type="facebook" />
                </a>
              </li>
              <li>
                <a href="#">
                  <Icon type="twitter" />
                </a>
              </li>
              <li>
                <a href="#">
                  <Icon type="instagram" />
                </a>
              </li>
              <li>
                <a href="#">
                  <Icon type="youtube" />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        {/* Modal */}
        {
          this.state.visible === true
            ?
            <div className="homeOpacity">
              <div className="homeVideo">
                <Icon type="close-circle" style={{ color: '#fff', fontSize: 24, marginRight: '-15px', float: 'right', cursor: 'pointer' }} onClick={this.closeVideo} />
                <iframe width="100%" height="480" src="https://www.youtube.com/embed/fJ9rUzIMcZQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media;" allowfullscreen></iframe>
              </div>
            </div>
            :
            null
        }
      </Layout>)
  }
};

export default withRouter(Home);

