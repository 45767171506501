import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Card, Row, Col, Avatar, Switch, Icon, Upload, message, Button, Input, Modal, List, Spin, Dropdown, Menu } from 'antd';
import { CONN } from '../../../constant/types';
import axios from 'axios';
import { validate } from 'indicative/validator';
import ImageUploader from 'react-images-upload';
import InfiniteScroll from 'react-infinite-scroller';
import './style.css';
var token = localStorage.getItem('token')
const { Content } = Layout;
function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const image = {
  name: 'img',
  action: `${CONN}/user_update`,
  headers: {
    'Authorization': `Bearer  ${token}`
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

console.log('props', image)
class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      change_name: false,
      change_email: false,
      change_sms: false,
      errors: [],
      user: [],
      pictures: [],
      department: [],
      users: [],
      statuses: [],
      priorities: [],
      topics: []
    }
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() { 
    this.fetchUser();
    this.fetchDept();
    this.fetchUsers();
    this.fetchStatus();
    this.fetchPriority();
    this.fetchTopic();
  }

  fetchUser = () => {
    var token = localStorage.getItem('token');
        const config = {
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      };
      axios
      .get(`${CONN}/user_profile`, config)
      .then((res) => {
        console.log('res.data', res)
        this.setState({
          user: res.data.user,
          name: res.data.user.name,
          email: res.data.user.email,
          password: res.data.user.password
      })
      })
  }

  fetchDept = () => {
    var token = localStorage.getItem('token');
        const config = {
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      };
      axios
      .get(`${CONN}/department`, config)
      .then((res) => {
        console.log('res.data', res)
        this.setState({
            department: res.data.data,
        })
      })
  }

  fetchUsers = () => {
    var token = localStorage.getItem('token');
        const config = {
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      };
      axios
      .get(`${CONN}/users?page=1`, config)
      .then((response) => {
          console.log('user awch baina:', response.data.data)
        this.setState({
          users: [...this.state.users, ...response.data.data],
          page: response.data.meta.last_page
        });
        if(response.data.meta.last_page > 1){
          for(var i=2; i<=response.data.meta.last_page; i++){
            axios
              .get(`${CONN}/users?page=${i}`, config)
              .then(response =>{
                this.setState({
                  users: [...this.state.users, ...response.data.data],
                })
                });
                }}
      })
  }

  fetchStatus = () => {
    var token = localStorage.getItem('token');
        const config = {
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      };
      axios
      .get(`${CONN}/ticket_statuses`, config)
      .then((res) => {
        console.log('res.data', res)
        this.setState({
            statuses: res.data
        })
      })
  }

  fetchPriority = () => {
    var token = localStorage.getItem('token');
        const config = {
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      };
      axios
      .get(`${CONN}/ticket_priorities`, config)
      .then((res) => {
        console.log('res.data', res)
        this.setState({
            priorities: res.data
        })
      })
  }

  fetchTopic = () => {
    var token = localStorage.getItem('token');
        const config = {
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      };
      axios
      .get(`${CONN}/ticket_topics`, config)
      .then((res) => {
        console.log('topic res', res)
        this.setState({
            topics: res.data
        })
      })
  }
  changeName = () => {
    this.setState({
      change_name: !this.state.change_name
    })
  }

  changeEmail = () => {
    this.setState({
      change_email: !this.state.change_email
    })
  }

  changeSms = () => {
    this.setState({
      change_sms: !this.state.change_sms
    })
  }

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onDrop(picture) {
    console.log('picture', picture)
    axios({
      method: 'POST',
      headers: {    'Content-Type': 'application/json'  },
      url: `${CONN}/user_update`,
      data: {
        image: picture
      },
      headers: {
        'Authorization': `Bearer  ${token}`
      }
    }).then(res => {
      console.log(res)
    })
    .catch(err => {
      console.log(err)
    })
    this.setState({
        pictures: this.state.pictures.concat(picture),
    });

  }

  handleUsername = (e) =>{
    e.preventDefault()
    const data = this.state; 
    const rules = {
      nameNew: 'email'
    };
    
    const messages = {
      required: `this {{ field }} is required `,
      'email.emailNew': 'the email is invalid'
    }
    validate( data, rules, messages )
      .then(() => {
        console.log('success');
        axios({
          method: 'POST',
          headers: {    'Content-Type': 'application/json'  },
          url: `${CONN}/user_update`,
          data: {
            name: this.state.nameNew
          },
          headers: {
            'Authorization': `Bearer  ${token}`
          }
        }).then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
        this.setState({name: this.state.nameNew, change_name: !this.state.change_name})
      })
      .catch(errors => {
        console.log(errors);
        const formattedErrors = {}
        errors.forEach( error => formattedErrors[error.field] = error.message)
        this.setState({
          errors: formattedErrors
        })
      })
  }

  handleEmail = (e) =>{
    console.log('token', token)
    e.preventDefault()
    console.log('Here input data',this.state);
    const data = this.state; 
    const rules = {
      emailNew: 'email'
    };
    validate(data, rules)
      .then(() => {
        axios({
          method: 'POST',
          headers: {    'Content-Type': 'application/json'  },
          url: `${CONN}/user_update`,
          data: {
            email: this.state.emailNew
          },
          headers: {
            'Authorization': `Bearer  ${token}`
          }
        }).then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
        this.setState({email: this.state.emailNew, change_email: !this.state.change_email})
      })
      .catch(errors => {
        console.log(errors);
      })
  }

  handleSms = (e) =>{
    console.log('token', token)
    e.preventDefault()
    console.log('Here input data',this.state);
    const data = this.state; 
    const rules = {
      smsNew: 'email'
    };
    validate(data, rules)
      .then(() => {
        axios({
          method: 'POST',
          headers: {    'Content-Type': 'application/json'  },
          url: `${CONN}/user_update`,
          data: {
            sms: this.state.smsNew
          },
          headers: {
            'Authorization': `Bearer  ${token}`
          }
        }).then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
        this.setState({sms: this.state.smsNew, change_sms: !this.state.change_sms})
      })
      .catch(errors => {
        console.log(errors);
      })
  }

  render() {

    console.log('yumgui', this.state.topics)
    const onChangeUser = (
        <Menu>
          {
            this.state.users.map( stats=>(
              <Menu.Item>
              <Button block type="link"
                onClick={() => { this.setState({
                  user_id: stats.id,
                  user_name: stats.name
                })
                }
                }>
                {stats.name}
              </Button>
            </Menu.Item>
            ))
          }
        </Menu>
      );
      const onChangeStatus = (
        <Menu>
          {
            this.state.statuses.map( stats=>(
              <Menu.Item>
              <Button block type="link"
                onClick={() => { this.setState({
                  user_id: stats.id,
                  user_name: stats.name
                })
                }
                }>
                {stats.name}
              </Button>
            </Menu.Item>
            ))
          }
        </Menu>
      );
      const onChangePriority = (
        <Menu>
          {
            this.state.priorities.map( stats=>(
              <Menu.Item>
              <Button block type="link"
                onClick={() => { this.setState({
                  user_id: stats.id,
                  user_name: stats.name
                })
                }
                }>
                {stats.name}
              </Button>
            </Menu.Item>
            ))
          }
        </Menu>
      );
      const onChangeTopic = (
        <Menu>
          {
            this.state.topics.map( stats=>(
              <Menu.Item>
              <Button block type="link"
                onClick={() => { this.setState({
                  user_id: stats.id,
                  user_name: stats.name
                })
                }
                }>
                {stats.name}
              </Button>
            </Menu.Item>
            ))
          }
        </Menu>
      );
    console.log('image', this.state.pictures)
    const data = this.state.department;
    return(
      <div className="profilePage">
        <Content className="profileHeaderWrap">
          <Link to="/admin/ticket/list" className="profilePageBack">
            <Icon type="left" />
          </Link>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <h1 style={{ width: 720, margin: '0 auto' }}>Settings</h1>
          </div>
          <Card title="Profile" style={{ width: 720, margin: '0 auto' }}>
            <Row className="profileLists">
              <Col span={4}>
                <span className="profileType">Photo</span>
              </Col>
              <Col span={14}>
                <Avatar src="https://uinames.com/api/photos/male/20.jpg" className="profileUserImage" />
              </Col>
              <Col span={6}>
                <ImageUploader
                  withLabel={false}
                  withIcon={false}
                  singleImage={true}
                  buttonText='Upload'
                  onChange={this.onDrop}
                  imgExtension={['.jpg','.png']}
                  maxFileSize={5242880}
              />
              </Col>
            </Row>
            <Row className="profileLists">
              <Col span={4}>
                <span className="profileType" readonly>Name</span>
              </Col>
              <Col span={14}>
                {this.state.name}
              </Col>
              <Col span={6}>
                <Link className="profileLink" onClick={this.changeName} disabled>
                  Edit
                </Link>
                <Modal
                  visible={this.state.change_name}
                  onOk={this.handleUsername}
                  onCancel={this.changeName}>
                  {this.state.changeName === false
                    ?
                    null
                    :
                    <div className="ticketUserContact">
                      <h3>Хэрэглэгчийн нэр</h3>
                      <div>
                        {
                          <Input
                          name="nameNew"
                          placeholder="Хэрэглэгчийн нэр"
                          onChange={this.changeHandler}
                          type="text"
                          /> 
                        }
                      </div>
                    </div>                                
                  }
                </Modal>
              </Col>
            </Row>
            <Row className="profileLists">
              <Col span={4}>
                <span className="profileType">Email</span>
              </Col>
              <Col span={14}>
                {this.state.email}
              </Col>
              <Col span={6}>
                <Link className="profileLink" onClick={this.changeEmail} disabled>
                  Edit
                </Link>
                <Modal
                  visible={this.state.change_email}
                  onOk={this.handleEmail}
                  onCancel={this.changeEmail}>
                  {this.state.changeEmail === false
                    ?
                    null
                    :
                    <div className="ticketUserContact">
                      <h3>Хэрэглэгчийн имэйл</h3>
                      <div>
                        {
                          <Input
                          name="emailNew"
                          placeholder="Хэрэглэгчийн имэйл"
                          onChange={this.changeHandler}
                          type="text"
                          /> 
                        }
                      </div>
                    </div>                                
                  }
                </Modal>
              </Col>
            </Row>       
            <Row>
                <Col span={4}>
                    <span className="profileType">Статус</span>
                </Col>
                <Col span={14}>
                <span>Status change automatically</span>
              </Col>
                <Col span={6}>
                    <Switch defaultChecked onChange={onChange} style={{ float: 'right' }} />
                </Col>
            </Row>             
          </Card>
          <div style={{ marginTop: 16 }}>
            <Card title="Reply" style={{ width: 720, margin: '0 auto' }}>               
              <Row className="profileLists">
              <Col span={4}>
                <span className="profileType" readonly>Дугаар</span>
              </Col>
              <Col span={14}>
                <span>70007070</span>
              </Col>
              <Col span={6}>
                <Link className="profileLink" onClick={this.changeName} readonly>
                  Edit
                </Link>
                <Modal
                  visible={this.state.change_name}
                  onOk={this.handleUsername}
                  onCancel={this.changeName}>
                  {this.state.changeName === false
                    ?
                    null
                    :
                    <div className="ticketUserContact">
                      <h3>Дугаар</h3>
                      <div>
                        {
                          <Input
                          name="nameNew"
                          placeholder="Байгууллагын дугаар"
                          onChange={this.changeHandler}
                          type="text"
                          /> 
                        }
                      </div>
                    </div>                                
                  }
                </Modal>
              </Col>
            </Row>
            <Row className="profileLists">
              <Col span={4}>
                <span className="profileType" readonly>Reply email</span>
              </Col>
              <Col span={14}>
                <span>info@callpro.mn</span>
              </Col>
              <Col span={6}>
                <Link className="profileLink" onClick={this.changeEmail} readonly>
                  Edit
                </Link>
                <Modal
                  visible={this.state.change_email}
                  onOk={this.handleEmail}
                  onCancel={this.changeEmail}>
                  {this.state.changeEmail === false
                    ?
                    null
                    :
                    <div className="ticketUserContact">
                      <h3>Reply email</h3>
                      <div>
                        {
                          <Input
                          name="mailNew"
                          placeholder="Байгууллагын мэйл"
                          onChange={this.changeHandler}
                          type="text"
                          /> 
                        }
                      </div>
                    </div>                                
                  }
                </Modal>
              </Col>
            </Row>
            <Row className="profileLists">
              <Col span={4}>
                <span className="profileType">SMS дугаар</span>
              </Col>
              <Col span={14}>
                <span>137070</span>
              </Col>
              <Col span={6}>
                <Link className="profileLink" onClick={this.changeSms}>
                  Edit
                </Link>
                <Modal
                  visible={this.state.change_sms}
                  onOk={this.handleSms}
                  onCancel={this.changeSms}>
                  {this.state.changeSms === false
                    ?
                    null
                    :
                    <div className="ticketUserContact">
                      <h3>SMS дугаар</h3>
                      <div>
                        {
                          <Input
                          name="smsNew"
                          placeholder="Байгууллагын sms дугаар"
                          onChange={this.changeHandler}
                          type="text"
                          /> 
                        }
                      </div>
                    </div>                                
                  }
                </Modal>
              </Col>
            </Row>           
            </Card>   
          </div> 
          <div style={{ marginTop: 16 }}>
            <Card title="Department" style={{ width: 720, margin: '0 auto' }}>               
              <Row className="profileLists">
              <Col span={16}>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                        description={<a href="/admin/profile">{item.name}</a>}
                        />
                        <div span={16}>choose supervisor</div>
                        <div>
                        <Dropdown overlay={onChangeUser}>
                            <Button block className="sideDropdown">
                            {
                                this.state.priority_name
                            } 
                            <Icon type="down" />
                            </Button>
                        </Dropdown> 
                        </div>
                    </List.Item>
                    )}
                />
                </Col>
            </Row>
            </Card>   
          </div>
          <div style={{ marginTop: 16 }}>
            <Card title="Reply" style={{ width: 720, margin: '0 auto' }}>             
              <Row className="profileLists">
              <Col span={4}>
                <span className="profileType" readonly>Ерөнхий төрөл</span>
              </Col>
              <Col span={14}>
              </Col>
              <Col span={6}>
                <div>
                    <Dropdown overlay={onChangeTopic}>
                        <Button block className="sideDropdown">
                        {
                            'Ерөнхий'
                        } 
                        <Icon type="down" />
                        </Button>
                    </Dropdown> 
                </div>
              </Col>
            </Row>
            <Row className="profileLists">
              <Col span={4}>
                <span className="profileType" readonly>Ерөнхий төлөв</span>
              </Col>
              <Col span={14}>
              </Col>
              <Col span={6}>
              <div>
                <Dropdown overlay={onChangeStatus}>
                    <Button block className="sideDropdown">
                    {
                        'Нээлттэй'
                    } 
                    <Icon type="down" />
                    </Button>
                </Dropdown> 
                </div>
              </Col>
            </Row>
            <Row className="profileLists">
              <Col span={4}>
                <span className="profileType">Ерөнхий зэрэглэл</span>
              </Col>
              <Col span={14}>
              </Col>
              <Col span={6}>
              <div>
                <Dropdown overlay={onChangePriority}>
                    <Button block className="sideDropdown">
                    {
                        '1-р зэрэглэл'
                    } 
                    <Icon type="down" />
                    </Button>
                </Dropdown> 
                </div>
              </Col>
            </Row>           
            </Card>   
          </div>           
        </Content>
      </div>
    )
  }

}

export default Settings;
