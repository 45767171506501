import React from 'react';
import './style.css';
import { Icon, Layout, Tag } from 'antd';
import { NavLink } from 'react-router-dom';
import { CONN } from '../../constant/types';
import axios from 'axios'
const token = localStorage.getItem('token')
const {Sider} = Layout;
class SiderTicketListComponent extends React.Component{

    state = {
        showMenu: true,
        info: [],
        stats: [],
        forme:'',
        due: '',
        total: '',
        cust: '',
        dep: '',
        statuses: [],
        sources: []
      };

    componentDidMount () {
      const config = {
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      };
     /* axios
    .get(`${CONN}/ticket_dashboard`, config)
    .then(response => {
      console.log('response', response)
      this.setState({
        info: response.data.ticket_sources,
        stats: response.data.ticket_statuses
      })
    })*/

    axios
    .get(`${CONN}/ticket_count`, config)
    .then(response =>{
      this.setState({ forme: response.data.data.ticket_me_count,
        total: response.data.data.ticket_count,
        due: response.data.data.ticket_deadline_count,
        cust: response.data.data.customer_count,
        dep: response.data.data.department_count
      })
    })
    
    this.setState({statuses: JSON.parse( localStorage.getItem('statuses'))});
    this.setState({sources: JSON.parse( localStorage.getItem('sources'))});
    }

    
    render(){
        const { showMenu } = this.state;
        return(
          <Layout style={{overflowY: 'scroll'}}>
        <div className="siderMenu" style={{ width: 168 }}>
          <h3 className="siderHeader">
          Тикетүүд
          </h3>
          {/*<NavLink to="/admin/ticket/dashboard" className="ticketSideBtn" activeClassName={"ticketSideBtn-active"} onClick={() => this.setState({ showMenu: false })}>
              <Icon type="pie-chart" style={{ marginRight: 8, fontSize: 12 }} />
              Хянах самбар
          </NavLink>*/}
          <NavLink to="/admin/ticket/forme" className="ticketSideBtn" activeClassName={"ticketSideBtn-active"} onClick={() => this.setState({ showMenu: false })}>
              <Icon type="fire" style={{ marginRight: 8, fontSize: 12 }} />
              <span>Миний тикетүүд </span>
              <span>{this.state.forme}</span>
          </NavLink>
          <NavLink to="/admin/ticket/list" className="ticketSideBtn" activeClassName={"ticketSideBtn-active"} onClick={() => this.setState({ showMenu: false })}>
              <Icon type="menu" style={{ marginRight: 8, fontSize: 12 }} />
              <span>Бүх тикетүүд </span>
              <span>{this.state.total}</span>
          </NavLink>   

          <NavLink to="/admin/ticket/department" className="ticketSideBtn" activeClassName={"ticketSideBtn-active"} onClick={() => this.setState({ showMenu: false })}>
              <Icon type="team" style={{ marginRight: 8, fontSize: 12 }} />
              <span>Хэлтэсийн тикет </span>
              <span>{this.state.dep}</span>
          </NavLink>
          
          
          <div className="customFilter">
                <h3>Бусад</h3>
              <NavLink to="/admin/ticket/overdue" className="ticketSideBtn" activeClassName={"ticketSideBtn-active"} onClick={() => this.setState({ showMenu: !showMenu })}>
              <Icon type="clock-circle" style={{ marginRight: 8, fontSize: 12 }} />
                  <span>Хугацаа хэтэрсэн </span>
                  <span>{this.state.due}</span>
              </NavLink>    
              <NavLink to="/admin/ticket/bycustomer" className="ticketSideBtn" activeClassName={"ticketSideBtn-active"} onClick={() => this.setState({ showMenu: !false })}>
              <Icon type="user" style={{ marginRight: 8, fontSize: 12 }} />
              <span>Харилцагчдаар </span>
              <span>{this.state.cust}</span>
              </NavLink>
          </div>

          {/*<div className="customFilter">
          <h3>Төлөв</h3>
            {
              this.state.statuses.map(status=>(
              <div><Icon type='right-circle' style={{color:status.color, backgroundColor: status.color, borderRadius:"50%"}}></Icon> - {status.name}</div>
                ))
            }
          </div>

          <div className="customFilter">
          <h3>Холболт</h3>
            {
              this.state.sources.map(sources=>(
              <div><Icon type={sources.icon} theme='filled' ></Icon> - {sources.name}</div>
                ))
            }
          </div>*/}
         
        </div>
        </Layout>
        )
    }

};

export default SiderTicketListComponent;
