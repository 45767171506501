import React,  {Component}  from 'react';
import { Row, Col, Card, Layout } from 'antd';
import './style.css';
import { CONN } from '../../../../constant/types';
import axios from 'axios';
import Charts, {Bar, Pie, TimelineChart, TagCloud} from 'ant-design-pro/lib/Charts';
import { Chart, Axis, Tooltip, Geom } from 'bizcharts';

const salesData = [];
for (let i = 0; i < 12; i += 1) {
  salesData.push({
    x: `${i + 1}月`,
    y: Math.floor(Math.random() * 1000) + 200,
  });
}

const gridStyle = {
  width: '20%',
  textAlign: 'center',
};

class TicketDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket_statuses: [],
      ticket_created: [],
      ticket_sources: [],
      statuses: [],
      created: [],
      source: [],
      customers:[],
      page: '',
      token: localStorage.getItem('token')
    }
  }

  //Ажилласан үед дуудагдана
  componentDidMount() {
    //message.info("Уншиж байна.")
    this.fetchTickets();
   
    var timeStamp = Math.floor(Date.now() / 1000);
    this.setState({created_at: timeStamp})
  }
  //Хэрэгцээт мэдээллүүдийг API-аас авах
  fetchTickets = () => {

    const { Content } = Layout;
    const config = {
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    };

    //Dahsboard мэдээллийг авах
    axios
    .get(`${CONN}/ticket_dashboard`,config)
    .then(response => {
      this.setState({ticket_statuses: response.data.ticket_statuses,
        ticket_created: response.data.ticket_created,
        ticket_sources: response.data.ticket_sources,
      })
      console.log(this.state)
    })
    for(var i=0; i<=this.state.ticket_created.length; i++){
      this.state.created.push({
        x: this.state.ticket_created.date,
        y: this.state.ticket_created.ticket_count
      })
    }
  }
  cloud = (value) => {
    console.log(value)
  }

  render() {
    return(
      <div className="page2-box" style={{ background: '#ECECEC', padding: '30px', marginTop: '50px' }}>
        
        <Row gutter="6" style={{'marginBottom': '20px'}}>
        <Card title="Хугацаагаар"  style={{ width: 'fixed' }}>
        {
                  this.state.ticket_statuses.map(created =>(
          <Col span='6'>
          <Card title={created.name} size="small" bordered={false} style={{ marginBottom: '6px' }} >
          <h2>{created.total}</h2>
          </Card>
          </Col>
            ))
            }
            </Card>
        </Row>
            
          
            <Row style={{'marginBottom': '20px'}}>
              <Card title="Хугацаагаар"  style={{ width: 'fixed' }}>
              <Bar height={200} title="" data={this.state.ticket_created.map(za => (
                {x: za.date, y: za.ticket_count}
              ))} />
              </Card>
            </Row>

            
        
        
            <Row style={{'marginBottom': '20px'}}>
              <Card title="Холбогдсон байдлаар"  style={{ width: 'fixed' }}>
              {
                this.state.ticket_sources.map(created =>(
                  <Card.Grid style={gridStyle}><p>{created.name}  <h2>{created.total}</h2></p></Card.Grid>
                ))
              }
              </Card>
            </Row>

            <Row style={{'marginBottom': '20px'}}>
              <Card title="Холбогдсон байдлаар"  style={{ width: "fixed" }}>
              <Pie    hasLegend              
                      data={this.state.ticket_sources.map(za =>(
                        {x:za.name , y:za.total}
                      ))}
                      valueFormat={val => <span dangerouslySetInnerHTML={{ __html: (val) }} />}
                      height={294}
                      total={() => (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: (this.state.ticket_sources.map(za =>(
                              {x:za.name , y:za.total}
                            )).reduce((pre, now) => now.y + pre, 0))
                          }}
                        />
                      )}
                    />
              </Card>
            </Row>
{ }
      </div>
    )
  }

}

export default TicketDashboard;
