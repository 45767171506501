import React from 'react';
import './style.css';
import { Icon, Row, Col, Menu, Button, Input, Collapse, Typography, Drawer, Spin, Popover, Modal, Breadcrumb, message, Dropdown } from 'antd';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { CONN } from '../../constant/types';
import axios from 'axios';
const {Paragraph} =Typography;
const {Panel} = Collapse;
const content = {"entityMap":{},"blocks":[{"key":"637gr","text":"Initialized from content state.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]};


class KnowledgeComponent extends React.Component{
  constructor(props) {
    super(props);
    const contentState = convertFromRaw(content);
    this.state = {
      customDrawer: true,
      visible: false,
      contentState,
      knowDrawer: true,
      onVisibleBtn: true,
      knowledge: [],
      orderType: 'Шинэ',    
      clicked: "",
      active: false,
      loading: false,
      showChild: false,
      showChildTwo: false,
      showLastChild: false,
      value: '',
      firstChild: [],
      secondChild: [],
      lastChild: [],      
      postKnowledge: [],
      postTicketDraft: [],
      editKnowledge: [],
      visibleEdit: false,
      token: localStorage.getItem('token'),
      types: [],
      isType: true,
      isKnow: false,
      type_id:'',
      type_name: 'Төрөл сонгох',
      type_namex: '',
      know_name: '',
      know_text: '',
      log:[],
      oldlog:[],
      fix_id:'',
      search_visible: false,
      fix_visible: false,
      searchKnow: [],
    }   
  }

  componentDidMount() {
    this.fetchKnowledge();
  }

  fetchKnowledge = () => {
    const config = {
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    };
    const url = (`${CONN}/knowledge?page=1`);
    this.setState({ loading: true });
    axios
      .get(url, config)
      .then(res => {
       console.log(res)
       this.setState({knowledge: res.data.data ,loading:false})
       if(res.data.meta.last_page > 1){
         for(var i=2; i<=res.data.meta.last_page; i++){
           axios
           .get(`${CONN}/knowledge?page=${i}`, config)
           .then(response =>{
             console.log(response.data.data)
             this.setState({knowledge: [...this.state.knowledge, ...response.data.data]})
           })
           .catch(err=>{
             console.log(err)
           })
         }
       }
       else{
         console.log('no more that one')
       }
      })
      .catch(error => {
        this.setState({ error, loading: false, refreshing: false });
        console.log(error)
      });

    axios
      .get(`${CONN}/knowledge_type`, config)
      .then(res => {
       console.log(res)
       this.setState({types: res.data.data})
      })
      .catch(error => {
        console.log(error)
      });
  };  

  componentWillUnmount() {
    this.fetchKnowledge();
  }

  changeHandler = e => {
		this.setState({ [e.target.name]: e.target.value })
  }

  onContentStateChange = (contentState) => {
    this.setState({
      contentState,
    });
  };

  search = (value) =>{
    const config = {
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    };
    console.log('url:', `${CONN}/department_search/${value}`)
    axios
    .get(`${CONN}/knowledge_search/${value}`, config)
    .then((res) => {
      console.log(res)
      this.setState({searchKnow: res.data.data})
      //this.setState({visible: !this.state.visible});
      if(res.data.meta.last_page > 1){
        for(var i=2; i<=res.data.meta.last_page; i++){
          axios
            .get(`${CONN}/knowledge_search/${value}?page=${i}`, config)
            .then(response =>{
              this.setState({
                searchKnow: [...this.state.searchKnow, ...response.data.data],
                loading: false,
                refreshing: false,
              })
            })
          }}
          this.setState({search_visible: !this.setState.search_visible})
    })
    .catch(error =>{
      console.log('error', error)
    })
  }

  showTypes = () => {
    this.setState({
      isType: true,
      isKnow: false,
    })
  }

  showComment = () => {
    this.setState({
      isKnow: true,
      isType: false,
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showEdit = () => {
    this.setState({
      visible: true,
    });
  };

  log = (value) =>{
    this.setState({oldlog: this.state.log});
    this.setState({log: value});
    console.log('log', this.state.log, value)
  }

  set = () =>{
    console.log('set', this.state.oldlog, this.state.log)
    if(this.state.log.length > this.state.oldlog.length){
      this.setState({fix_id: this.state.oldlog[this.state.oldlog.length-1]})
    }
    else{
      this.setState({fix_id: this.state.log[this.state.oldlog.length-1]})
    }
    this.fixModal();
  }

  fixModal = () =>{
    this.setState({fix_visible: !this.state.fix_visible})
  }
  
  fixSend = () =>{
    this.setState({fix_visible: !this.state.fix_visible})
  }

  
  handleOk = e => {
    const config = {
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    };
    if(this.state.isType == true){
      axios({
        method: 'post',
        url: `${CONN}/knowledge_type`,
        data: {
          name: this.state.type_namex
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      })
      .then(res=>{
        this.setState({
          visible: false,
        });
        message.success('Төрлийг амжилттай үүсгэлээ.')
        this.fetchKnowledge();
      })
      .catch(err =>{
        console.log(err)
      })
    }else{
      axios({
        method: 'post',
        url: `${CONN}/knowledge`,
        data: {
          type: this.state.type_id,
          title: this.state.know_name,
          message: this.state.know_text
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      })
      .then(res=>{
        this.setState({
          visible: false,
        });
        message.success('Санг амжилттай үүсгэлээ.')
        this.fetchKnowledge();
      })
      .catch(err =>{
        console.log(err)
      })
    }
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  canchelEdit = e => {
    console.log(e);
    this.setState({
      visibleEdit: false,
    });
  };

  showKnow = () => {
    this.setState({
      knowDrawer: true,
      knowledgeBtn: false
    });
  };

  onClose = () => {
    this.setState({
      knowDrawer: false,
      knowledgeBtn: true
    });
  };

  handleClick(e) {
    this.setState({
      clicked: e,
      active: true,
      showChild: true,
      showChildTwo: false,
      showLastChild: false,      
      firstChild: [],
      secondChild: [],
      lastChild: [],      
      firstChild: this.state.kList[e]
    })
    console.log('this is:', e);
  }

  handleChild(e) {
    this.setState({
      clicked: e,
      active: true,
      showChild: true,
      showChildTwo: true,
      secondChild: this.state.firstChild.children[e]
    })
  }

  handeLastChild(e) {
    this.setState({
      clicked: e,
      active: true,
      showChild: true,
      showChildTwo: true,
      showLastChild: true,
      lastChild: this.state.secondChild.children[e]
    })
  }

  firstChild = () => {
    this.setState({
      firstChild: true,
      showChild: false,

    })
  }
  backChild = () => {
    this.setState({
      showChild: true,
      showChildTwo: false,
    })
  }  

  backChildTwo = () => {
    this.setState({
      showChildTwo: true,
      showLastChild: false,
    })
  }
  
  closeCustomDrawer = () => {
    this.setState({
      customDrawer: false,
    })
  }

  postKnowledge = () => {
    this.setState({
      postKnowledge: this.state.contentState.blocks,
    })
  }

  editKnowledge = (e) => {
    this.setState({
      visibleEdit: true
    })
  } 

  postKnowledgeDraft = () => {
    this.setState({
      postTicketDraft: this.state.contentState.blocks,
    },
    message.success('Амжилттай Draft болгож хадгаллаа')
    )
  }

  render(){
  const { kList, showChild, firstChild, showChildTwo, secondChild, lastChild, showLastChild, active, loading, contentState } = this.state;
  const controlKnow = (
    <div>
      <p>Засварлах</p>
      <p>Устгах</p>
    </div>
  );
console.log(this.state.knowledge)
  const types = (
    <Menu>
      {
        this.state.types.map(type=>(
          <Menu.Item>
            <Button block onClick={()=>{
              this.setState({
                type_id: type.id,
                type_name: type.name
              })
            }}>
              {type.name}
            </Button>
          </Menu.Item>
        ))
      }
    </Menu>
  )
  return(
    <div>
      {
        this.state.customDrawer === true
        ?
        <div className="demoDrawer">
          <Row style={{ marginBottom: 16 }}>
            <Col span={18}>
              <h3 className="customDrawerHeader">
                Мэдлэгийн сан
              </h3>
            </Col>
            <Col span={6}>
              <span>
                <Icon type="close" onClick={this.closeCustomDrawer}  style={{ float: 'right', marginTop: 4 }} />
              </span>
            </Col>
          </Row>
          <Row>
              <Col span={18} style={{ marginBottom: 8 }}>
                <Input.Search
                  placeholder="Хайх" allowClear autoFocus name='searchTicket' onchange={this.changeHandler} onSearch={this.search}  enterButton
                />
              </Col>
              <Col span={3} style={{ marginBottom: 8}}>
                <Button type="primary" shape="circle" style={{marginLeft:4}} onClick={this.showModal}>
                  <Icon type='plus'/>
                </Button>
              </Col>
              <Col span={3} style={{ marginBottom: 8}}>
                <Button type="primary" shape="circle" style={{marginLeft:4}} onClick={this.fixModal}>
                  <Icon type='edit'/>
                </Button>
              </Col>
            </Row>
          {
            loading === true
            ?
            <div>
              <Spin />
            </div>
            :
            this.state.search_visible == true
            ?
            <div>
            <Row>
            <Button style={{marginBottom: '10px', backgroundColor: '#1890FF', color:"#FFFFFF"}} onClick={e=>this.setState({search_visible: false})}>Хайлт цэвэрлэх</Button>
            <Col>
            {<Collapse expandIconPosition='left' onChange={this.log}>
                  {this.state.searchKnow.map(map=>(
                      <Panel header={<span><b>{map.title}</b> <i>from</i> {map.knowledge_type.name}</span>}><i><Paragraph copyable>{map.message}</Paragraph></i></Panel>
                    ))
                  }
                  </Collapse>
            }
            </Col>
          </Row>
          </div>
            :
            <div>
            <Row>
            <Col>
            {
            this.state.types.map(type=>(
              <Collapse expandIconPosition='left'>
                <Panel header={<b>{type.name}</b>}>
                  <Collapse expandIconPosition='left' onChange={this.log}>
                  {
                    this.state.knowledge.map(map=>(
                      map.knowledge_type.id == type.id
                      ?
                      <Panel header={map.title}><i><Paragraph copyable>{map.message}</Paragraph></i></Panel>
                      :
                      null
                    ))
                  }
                  </Collapse>
                </Panel>
              </Collapse>
            ))
            }
            </Col>
          </Row>
          </div>
          }
          

            <Modal
              title="Мэдлэгийн сан шинээр нэмэх"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <Row>
                <Col>
                {
                  this.state.isType == true
                  ?
                  <Button onClick={this.showTypes} className="btnActive">Төрөл</Button>
                  :
                  <Button onClick={this.showTypes} >Төрөл</Button>
                }
                {
                    this.state.isKnow === true
                    ?
                    <Button onClick={this.showComment} style={{marginLeft: '5px', marginBottom: '10px'}} className="btnActive">Мэдлэгийн сан</Button>
                    :
                    <Button onClick={this.showComment} style={{marginLeft: '5px', marginBottom: '10px'}}>Мэдлэгийн сан</Button>
                  }
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    this.state.isType == true
                    ?
                    <Row>
                      <Col>
                       <Input type="text" name='type_namex' onChange={this.changeHandler} placeholder='Шинээр үүсгэх'></Input>
                      </Col>
                    </Row>
                    :
                    <div>
                      <Row style={{marginBottom: '10px'}}>
                        <Col>
                          <Dropdown overlay={types} >
                            <Button style={{width: '100%', textAlign: "left"}}>{this.state.type_name}</Button>
                          </Dropdown>
                        </Col>
                      </Row>
                      <Row style={{marginBottom: '10px'}}>
                        <Col>
                          <Input type="text" name='know_name' onChange={this.changeHandler} placeholder='Сангийн гарчиг'></Input>
                        </Col>
                      </Row>
                      <Row style={{marginBottom: '10px'}}>
                        <Col>
                          <Input.TextArea type="text" name='know_text' onChange={this.changeHandler} placeholder='Сангийн агуулга'></Input.TextArea>
                        </Col>
                      </Row>
                    </div>
                  }
                </Col>
              </Row>
            </Modal>  

            <Modal
              title='Мэдлэгийн сан засах'
              visible={this.state.fix_visible}
              onOk={this.fixSend}
              onCancel={this.fixModal}
            >
              <div>{console.log(this.state.fix_id)}</div>
            </Modal>         
        </div>  
        :
        null        
      }
    </div>
    )
  }
};

export default KnowledgeComponent;
