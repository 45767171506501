import React from 'react';
import './style.css'
import { Icon, Layout } from 'antd';
import { NavLink } from 'react-router-dom';
import { CONN } from '../../constant/types';
import axios from 'axios'
const token = localStorage.getItem('token')
const {Sider} = Layout;
class SiderDashboardComponent extends React.Component{

    state = {
        showMenu: true,
        info: [],
        stats: [],
        forme:'',
        due: '',
        total: '',
        cust: '',
        dep: ''
      };

    componentDidMount () {
      const config = {
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      };

    axios
    .get(`${CONN}/ticket_count`, config)
    .then(response =>{
      this.setState({ forme: response.data.data.ticket_me_count,
        total: response.data.data.ticket_count,
        due: response.data.data.ticket_deadline_count,
        cust: response.data.data.customer_count,
        dep: response.data.data.department_count
      })
    })
    

    }

    
    render(){
        const { showMenu } = this.state;
        return(
          <Layout>
          <Sider
      breakpoint="lg"
      collapsedWidth="0"
      theme="light"
      width="150px"
      onBreakpoint={broken => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
        <div className="siderMenu" style={{ width: 168 }}>
          <h3 className="siderHeader">
          Тайлангууд
          </h3>
          <NavLink to="/admin/dashboard/ticket" className="ticketSideBtn" activeClassName={"ticketSideBtn-active1"} onClick={() => this.setState({ showMenu: false })}>
              <Icon type="pie-chart" style={{ marginRight: 8, fontSize: 12 }} />
              Тикетийн тайлан
          </NavLink>
          <NavLink to="/admin/dashboard/order" className="ticketSideBtn" activeClassName={"ticketSideBtn-active1"} onClick={() => this.setState({ showMenu: false })}>
              <Icon type="menu" style={{ marginRight: 8, fontSize: 12 }} />
              <span>Захиалгын тайлан</span>
          </NavLink>         
        </div>
        </Sider>
        </Layout>
        )
    }

};

export default SiderDashboardComponent;
