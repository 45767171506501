import React from 'react';
import { Layout, Icon } from 'antd';
import './style.css';

class OrderDashboard extends React.Component {

  render() {
     return(
      <Layout style={{ paddingTop: 48, paddingLeft: 15, paddingRight: 15, paddingBottom: 15, backgroundColor: '#ffffff' }}>
        <p style={{textAlign:'center', fontSize:'100px'}}><Icon type='smile'/></p>  
        <p style={{textAlign:'center', fontSize:'50px'}}>Тун удахгүй...</p>          
      </Layout>
    )
  }

}

export default OrderDashboard;
