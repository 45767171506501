import React from 'react';
import { Layout, Spin, Row, Col, Divider, Icon, Input, Button, Modal, Dropdown, Menu } from 'antd';
import './style.css';
import { CONN } from '../../../constant/types';

const { TextArea } = Input;

class KnowledgeBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      knowledgeList: [],
      clicked: "",
      active: false,
      loading: false,
      showChild: false,
      showChildTwo: false,
      showLastChild: false,
      value: '',
      firstChild: [],
      secondChild: [],
      lastChild: [],
    };
  }

  componentDidMount() {
    this.fetchKnowledge();
  }

  fetchKnowledge = () => {
    const url = (`${CONN}/knowledge`);
    this.setState({ loading: false });
    fetch(url)
      .then(res => res.json())
      .then(res => {
        this.setState({
          knowledgeList: [...this.state.knowledgeList, ...res],
          total: res.total || null,
          error: res.error || null,
          loading: false,
          refreshing: false,
        });
      })
      .catch(error => {
        this.setState({ error, loading: false, refreshing: false });
      });
  };  
  
  handleClick(e) {
    this.setState({
      clicked: e,
      active: true,
      loading: true,
      showChild: true,
      showChildTwo: false,
      showLastChild: false,      
      firstChild: [],
      secondChild: [],
      lastChild: [],      
      firstChild: this.state.knowledgeList[e]
    })
    console.log('this is:', e);
  }
  
  handleChild(e) {
    this.setState({
      clicked: e,
      active: true,
      loading: true,
      showChild: true,
      showChildTwo: true,
      secondChild: this.state.firstChild.children[e]
    })
  }

  handeLastChild(e) {
    this.setState({
      clicked: e,
      loading: true,
      active: true,
      showChild: true,
      showChildTwo: true,
      showLastChild: true,
      lastChild: this.state.secondChild.children[e]
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { kList, clicked, loading, showChild, firstChild, showChildTwo, secondChild, lastChild, showLastChild, active, knowledgeList } = this.state;
    console.log("knowledgeList", knowledgeList);
    const menu = (
      <Menu>
        <Menu.Item>
          <span>
            Cэдэв 1
          </span>
        </Menu.Item>
        <Menu.Item>
          <span>
            Cэдэв 2
          </span>
        </Menu.Item>
        <Menu.Item>
          <span>
            Cэдэв 3
          </span>
        </Menu.Item>
      </Menu>
    );
     return(
      <Layout style={{ paddingTop: 48, paddingLeft: 15, paddingRight: 15, paddingBottom: 15, backgroundColor: '#ffffff' }}>        
        <Row>
          <Col span={24} className="knowledgeTop">
            <h2>
              Мэдлэгийн сан
            </h2>
            <Button block className="btnRight" onClick={this.showModal}><Icon type="plus"></Icon>Шинэ</Button>            
          </Col>
          <Col span={5} style={{ borderRight: 1, borderRightColor: '#EBEBEB', borderRightStyle: 'solid', height: '100vh'  }}>
            <ul className="knowledgeList">
              {
                knowledgeList.map(kList =>(

                    active === true
                    ?
                    <li onClick={(e) => this.handleClick(kList.value)} key={`knowledgeId${kList.value}`} className="" react-id={`knowledgeId-${kList.value}`} >{kList.label}  <Icon type="right" style={{ float: 'right', color: '#60698E', fontSize: 12, marginTop: 4 }} /></li>
                    :
                    <li onClick={(e) => this.handleClick(kList.value)} key={`knowledgeId${kList.value}`}>{kList.label} <Icon type="right" style={{ float: 'right', color: '#60698E', fontSize: 12, marginTop: 4 }} /></li>
                  
                ))
              }
            </ul>
          </Col>
          <Col span={5} style={{ borderRight: 1, borderRightColor: '#EBEBEB', borderRightStyle: 'solid', height: '100vh'  }}>
            {
              showChild === true
              ?
              <ul className="knowledgeList">
                {
                  firstChild.children.map(firstChild =>(
                    <li onClick={(e) => this.handleChild(firstChild.value)}  key={`knowledge-Child-Id-${firstChild.value}`} react-id={`knowledge-Child-Id-$${firstChild.value}`}>{firstChild.label} <Icon type="right" style={{ float: 'right', color: '#60698E', fontSize: 12, marginTop: 4 }} /></li>
                  ))
                }
              </ul>
              :
              null
            }
          </Col>
          <Col span={5} style={{ borderRight: 1, borderRightColor: '#EBEBEB', borderRightStyle: 'solid', height: '100vh'  }}>
            {
              showChildTwo === true
              ?
              <ul className="knowledgeList">
                {
                  secondChild.children.map(secondChild =>(
                    <li onClick={(e) => this.handeLastChild(secondChild.value)} key={`knowledge-Child-Second-Id-${secondChild.value}`} react-id={`knowledge-Child-Second-Id-${secondChild.value}`}>{secondChild.label} <Icon type="right" style={{ float: 'right', color: '#60698E', fontSize: 12, marginTop: 4 }} /></li>
                  ))
                }
              </ul>
              :
              null
            } 
          </Col>
          <Col span={9}>
          {
            showLastChild === true
            ?
            <div style={{ padding: 20 }}>
              {
                lastChild.children.map(lChild =>(
                  <Col key={`knowledge-Last-Id-${lChild.value}`} react-id={`knowledge-Last-Id-${secondChild.value}`} span={24} style={{ marginTop: 12 }}>
                    <h3>{lChild.title}</h3>
                    <p>{lChild.body}</p>
                  </Col>
                ))
              }
            </div>
            :
            null
          }  
          </Col>                              
        </Row>
        <Modal
          title="Basic Modal"
          visible={this.state.visible}
          onOk={this.handleOk}
          className="knowledgeModal"
          onCancel={this.handleCancel}
        >
          <h3>Мэдлэгийн сан шинээр нэмэх</h3>
          <Dropdown overlay={menu} placement="bottomCenter">
            <Button block style={{ width: 210, marginBottom: 20 }}>Сэдэв сонгох 
              <Icon type="down"></Icon>
            </Button>
          </Dropdown>
          <TextArea rows={10} />
        </Modal>
      </Layout>
    )
  }

}

export default KnowledgeBase;
