import React from 'react';
import './style.css';
import { Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import axios from 'axios'
import { CONN } from '../../constant/types';


class SiderTicketComponent extends React.Component{
    
    state = {
        showMenu: false,
        info: [],
        stats: []
      };
    
      componentDidMount () {
        axios
      .get(`${CONN}/ticket_dashboard`)
      .then(response => {
        this.setState({info: response.data.ticket_sources,
        stats: response.data.ticket_statuses
        })
        //console.log(this.state.users)
      })
      }
    render(){
        const { showMenu } = this.state;
        return(
            <div className="siderMenu" style={{ width: 168 }}>
            <h3 className="siderHeader">
                Тикетүүд
            </h3>
            <NavLink to="/admin/ticket/dashboard" className="ticketSideBtn" activeClassName={"ticketSideBtn-active"} onClick={() => this.setState({ showMenu: false })}>
                <Icon type="pie-chart" style={{ marginRight: 8, fontSize: 12 }} />
                Хянах самбар
            </NavLink>
            <NavLink to="/admin/ticket/list" className="ticketSideBtn" activeClassName={"ticketSideBtn-active"} onClick={() => this.setState({ showMenu: !showMenu })}>
                <Icon type="menu" style={{ marginRight: 8, fontSize: 12 }} />
                Бүх тикетүүд
            </NavLink>                         
            {
                showMenu === true
                ?
                <div class="sideNavList">
                    <div className="sideNavBtn">
                        <Icon type="phone" style={{ marginRight: 8, fontSize: 12 }} />
                        <span class="sideNavTitle">Дуудлага</span>
                        <span className="sideNavCount">310</span>
                    </div>                
                    <div className="sideNavBtn">
                        <Icon type="phone" style={{ marginRight: 8, fontSize: 12 }} />
                        <span class="sideNavTitle">SMS</span>
                        <span className="sideNavCount">20</span>
                    </div>                   
                    <div className="sideNavBtn">
                        <Icon type="mail" style={{ marginRight: 8, fontSize: 12 }} />
                        <span class="sideNavTitle">И-Мэйл</span>
                        <span className="sideNavCount">136</span>
                    </div>                  
                    <div className="sideNavBtn">
                        <Icon type="twitter" style={{ marginRight: 8, fontSize: 12 }} />
                        <span class="sideNavTitle">Twitter</span>
                        <span className="sideNavCount">11</span>
                    </div>                   
                    <div className="sideNavBtn">
                        <Icon type="skype" style={{ marginRight: 8, fontSize: 12 }} />
                        <span class="sideNavTitle">Viber</span>
                        <span className="sideNavCount">11</span>
                    </div>                   
                    <div className="sideNavBtn">
                        <Icon type="clock-circle" style={{ marginRight: 8, fontSize: 12 }} />
                        <span class="sideNavTitle">Хугацаа хэтэрсэн</span>
                        <span className="sideNavCount">100</span>
                    </div>
                    <div className="sideNavBtn">
                        <Icon type="clock-circle" style={{ marginRight: 8, fontSize: 12 }} />
                        <span class="sideNavTitle">Хугацаа хэтэрсэнoo;</span>
                        <span className="sideNavCount">5</span>
                    </div>                  
                </div>     
                :
                null

            }       
        </div>
        )
    }

};

export default SiderTicketComponent;
