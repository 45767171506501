import React from 'react';
import { Layout, Row, Col, Icon, Button, message } from 'antd';
import { Input } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import './style.css';
import CallProLogo from '../../../assets/logo@2x.png';
import sourceMap from '../../../assets/map.jpg';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = ({
    })
  }

  sendMail = () => {
    message.success('Амжилттай и-мэйл илгээгдлээ');
  }
  
  render() {
  return(
    <Layout style={{ backgroundColor: '#fff' }}>
      <div className="homeTopbar">
        <Link to="/">
          <img src={CallProLogo} alt="" className="topBarLogo" />
        </Link>
        {/* <ul className="homeTopRight">
          <li>
            <Link to="about" className="homeLinkGhost">Тухай</Link>
          </li>
          <li>
            <Link to="contact">Холбогдох</Link>
          </li>
        </ul> */}
      </div>
      <Row className="homeAbout">
        <Col span={8} className="homeContactForm">
          <h2>Бидэнтэй Холбогдох</h2>
          <Row>
            <Col span={24}>
              <label className="homeContactLabel">Байгууллагын нэр</label>
              <Input   />
            </Col>
          </Row>
          <Row style={{ marginTop: 24 }}>
            <Col span={24}>
              <label className="homeContactLabel">Байгууллагын утас</label>
              <Input />
            </Col>
          </Row>          
          <Row style={{ marginTop: 24 }}>
            <Col span={24}>
              <label className="homeContactLabel">Байгууллагын и-мэйл хаяг</label>
              <Input />
            </Col>
          </Row>
          <Row style={{ marginTop: 24 }}>
            <Col span={24}>
              <Button block className="homeContactConfirm" onClick={this.sendMail}>Илгээх</Button>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <img src={sourceMap} alt="CallPro Map" className="contactMap" />
        </Col>
      </Row>
      <Row className="homeFooter">
        <Col span={12}>
          <img src={CallProLogo} alt="Onlime CRM" className="footerLogo" /> 
          <span className="homeCopyright">| Copyright &copy; 2020 Onlime</span>
        </Col>
        <Col span={12}>
          <ul className="footerSocial">
            <li> 
              <a href="#">
                <Icon type="facebook" />
              </a>   
            </li>
            <li>
              <a href="#">
                <Icon type="twitter" />
              </a>              
            </li>
            <li>
              <a href="#">
                <Icon type="instagram" />
              </a>
            </li>
            <li>
              <a href="#">
                <Icon type="youtube" />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      </Layout>)
  }};

  export default withRouter(Contact);

