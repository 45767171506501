import React from 'react';
import { Layout } from 'antd';
const { Content } = Layout;


class Dashboard extends React.Component {
  constructor(props){
    super(props);
 }


  render() {
    return(
      <Layout style={{ marginTop: 48, backgroundColor: '#ffffff' }}>
        <Content>
          <p>Dashboard</p>
        </Content>
      </Layout>
    )
  }

}

export default Dashboard;
