import React from 'react';
import './style.css';
import { Layout, Icon, Col, Row, Avatar, Badge, Button, Alert, Input, Divider } from 'antd';

class Workchat extends React.Component{
    constructor(props) {
        super(props);
        this.chatShowOff = this.chatShowOff.bind(this);
        this.chatShowOn = this.chatShowOn.bind(this);
        this.groupShowOff = this.groupShowOff.bind(this);
        this.groupShowOn = this.groupShowOn.bind(this);
        this.state = {
            isOpen: true,
            chatWidth: 216,
            chatShow: true,
            groupShow: true,
            onChat: false,
        };
    };
    
    chatShowOn() {
        this.setState({
            chatShow: true
        })
    }

    chatShowOff() {
        this.setState({
            chatShow: false
        })
    }

    groupShowOn() {
        this.setState({
            groupShow: true,
        })
    }

    groupShowOff() {
        this.setState({
            groupShow: false,
        })
    }    

    onChat = (e) => {
        this.setState({
            onChat: !this.state.onChat,
            activeUser: '',
        },
        console.log("this is the active user", e));
    }

    chatClose = () => {
        this.setState({
            onChat: false
        })
    }

    render(){
        console.log("onChat trigger is working", this.state.onChat);
        const groupChats = [{
            key: "group-1",
            imgMain: "https://uinames.com/api/photos/female/23.jpg",
            imgOther: "https://uinames.com/api/photos/female/14.jpg",
            name: "Менежмент групп",
            badge: null
        },{
            key: "group-2",
            imgMain: "https://uinames.com/api/photos/female/3.jpg",
            imgOther: "https://uinames.com/api/photos/female/9.jpg",
            name: "Маркетинг групп",
            badge: 1
        },
        {
            key: "group-3",
            imgMain: "https://uinames.com/api/photos/female/5.jpg",
            imgOther: "https://uinames.com/api/photos/female/7.jpg",
            name: "Маркетинг хэлтэс",
            badge: 1
        }];

        const chatUsers = [{
            key: "user-1",
            img: "https://uinames.com/api/photos/female/23.jpg",
            name: "Цэнгүүн",
            type: "Техник",
            badge: 3
        },
        {
            key: "user-2",
            img: "https://scontent.fuln2-1.fna.fbcdn.net/v/t1.0-1/p160x160/49547406_2110747595654113_7499002144217890816_n.jpg?_nc_cat=105&_nc_oc=AQkzGR-gm7svvzdQCvV__smMbc_uM1wY24VNbHm_KvgEfC1VewUzSxePKGDwiyD_WKU&_nc_ht=scontent.fuln2-1.fna&oh=f9c65caaa687aadf021ecf59b2f74a0a&oe=5E2C8E7B",
            name: "Итгэл",
            type: "Барилга",
            badge: 2
        },        
        {
            key: "user-3",
            img: "https://uinames.com/api/photos/male/13.jpg",
            name: "Энх",
            type: "Техник",
            badge: 0
        },  
        {
            key: "user-4",
            img: "https://uinames.com/api/photos/male/7.jpg",
            name: "Чингүүн",
            type: "Техник",
            badge: 0
        },
        {
            key: "user-5",
            img: "https://uinames.com/api/photos/male/3.jpg",
            name: "Өгөөдэй",
            type: "Техник",
            badge: 0
        },                    
        {
            key: "user-6",
            img: "https://uinames.com/api/photos/male/2.jpg",
            name: "Өгөөдэй",
            type: "Техник",
            badge: 0
        },
        {
            key: "user-7",
            img: "https://uinames.com/api/photos/female/2.jpg",
            name: "Аз жаргал",
            type: "Борлуулалт",
            badge: 0
        },
        {
            key: "user-8",
            img: "https://uinames.com/api/photos/male/8.jpg",
            name: "Шихихутаг",
            type: "Борлуулалт",
            badge: 0
        },
        {
            key: "user-9",
            img: "https://uinames.com/api/photos/female/8.jpg",
            name: "Хэрлэн",
            type: "Борлуулалт",
            badge: 5
        },        
        {
            key: "user-10",
            img: "https://uinames.com/api/photos/male/14.jpg",
            name: "Билгүүн",
            type: "Борлуулалт",
            badge: 4
        },  
        {
            key: "user-11",
            img: "https://uinames.com/api/photos/male/15.jpg",
            name: "Энх",
            type: "Техник",
            badge: 0
        },
        {
            key: "user-12",
            img: "https://uinames.com/api/photos/male/17.jpg",
            name: "Батхүү",
            type: "Техник",
            badge: 2
        },                   
        {
          key: "user-13",
          img: "https://uinames.com/api/photos/male/2.jpg",
          name: "Доржоо",
          type: "Техник",
          badge: 0
        },
        {
          key: "user-14",
          img: "https://uinames.com/api/photos/male/1.jpg",
          name: "Гомбоо",
          type: "Техник",
          badge: 0
        },                         
        {
          key: "user-15",
          img: "https://uinames.com/api/photos/female/2.jpg",
          name: "Цэцэг",
          type: "Техник",
          badge: 0
        }           
    ];


        return(
                <Layout>
                    <Row>
                    <Col span={4} style={{ marginTop: 48 }}>
                        <div className="workChat" style={{ width: '100%' }}>     
                        <div className="workChatHeader">
                            <div>
                                <h3>Ажлын чат</h3>
                                <ul className="workChatToolkit">
                                    <li>
                                        <span>
                                            <Icon type="setting" size={16}  />
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <Icon type="edit" size={16} />
                                        </span>
                                    </li>                        
                                </ul>
                            </div>
                        </div>  
                        <div className="sideSearch">
                            <input type="text" placeholder="Хайх" className="sideInput"/>
                        </div> 
                            <div className="workChatType">
                                <h3>Групп чат</h3>
                                <div className="workChatCollapse">
                                    {
                                        this.state.groupShow === true
                                        ?
                                        <Icon type="up" onClick={this.groupShowOff} />
                                        :
                                        <Icon type="down" onClick={this.groupShowOn} />
                                    }
                                </div>
                            </div> 
                            <div style={{ height: 40 }}></div>       
                            <div className="workChatGroup">              
                              <div className="workChatType">
                                  <h3>Зурвас</h3>
                                  <div className="workChatCollapse">
                                      {
                                          this.state.chatShow === true
                                          ?
                                          <span onClick={this.chatShowOff}>
                                              <Icon type="up" />
                                          </span>
                                          :
                                          <span href="#" onClick={this.chatShowOn}>
                                              <Icon type="down" />
                                          </span>
                                      }
                                  </div>
                              </div>                
                            </div>
                            <div style={{ height: 40 }}></div>
                            <div className="workChats" id="workChatScroll">
                                {
                                    this.state.chatShow === true
                                    ?
                                    <div>
                                        {chatUsers.map(cUser => (
                                        <Row className="workChatUser" key={cUser.key} onClick={(e) => this.onChat(cUser.key)}>
                                            <Col span={6}>
                                                <Avatar src={cUser.img} className="chatUserImg" />
                                            </Col>
                                            {
                                                this.state.chatWidth === 216
                                                ?
                                                <Col span={15}>
                                                    <h3 className="workChatUsername">{cUser.name}</h3>
                                                    <span className="workChatUserType">{cUser.type}</span>
                                                </Col>
                                                :
                                                null

                                            }
                                            {
                                                this.state.chatWidth === 216
                                                ?
                                                <Col span={3}>
                                                    {
                                                        cUser.badge > 0
                                                        ?
                                                        <Badge
                                                        count={cUser.badge}
                                                        className="workChatUserBadge"
                                                        />
                                                        :
                                                        null
                                                    }
                                                </Col>
                                                :
                                                null

                                            }
                                        </Row>))
                                        }
                                    </div>
                                    :
                                    null                                
                                }          
                            </div>       
                        </div>                
                    </Col>
                    <Col span={14} className="workChatMain">
                        <div className="wChatHeader">
                            <h3>
                                Баяраа
                            </h3>
                            <span>bayar@callpro.mn</span>
                            <ul>
                                <li>
                                    <Icon type="phone" />
                                </li>
                                <li>
                                    <Icon type="video-camera" />
                                </li>
                            </ul>
                        </div>
                        <div className="wChatBody">
                            <div className="wChatMsg">
                                <img src="https://uinames.com/api/photos/male/4.jpg" alt="" className="wChatMsgImg" />
                                <div className="wChatMsgBody">
                                Өчигдөр нээгдсэн Самсунгийн хөгжүүлэгчдийн чуулганы үеэр тус компани нугардаг дэлгэцтэй гар утасны шинэ санаагаа танилцуулжээ.
                                </div>  
                            </div>
                            <div className="wChatMsg-active">
                                <div className="wChatMsgBody-active">
                                Уг санаа нь бидний сайн мэдэх уламжлалт эвхдэг гар утасны загвар дээр тулгуурлажээ. Ухаалаг гар утасны хувьсгал эхлэхээс өмнө голоороо эвхэгддэг буюу “clam shell”, “flip” загвартай гар утаснууд түгээмэл хэрэглэгддэг байв.
                                </div>  
                            </div>                              
                            <div className="wChatMsg">
                                <img src="https://uinames.com/api/photos/male/4.jpg" alt="" className="wChatMsgImg" />
                                <div className="wChatMsgBody">
                                “Нугардаг дэлгэцтэй гар утасны шинэ санаа нь халаасанд багтахуйц байхаас гадна бидний гар утас хэрэглэх аргыг өөрчлөх юм” гэж Самсунгийн туршилт, судалгааны хэсгийн тэргүүн Хесун Жён тайлбарлажээ.
                                </div>  
                            </div>                            
                            <div className="wChatMsg-active">
                                <div className="wChatMsgBody-active">
                                Шинэ загвартай гар утас хэзээ зах зээлд гарах талаар тэр мэдээлэл өгөөгүй юм.
                                </div>  
                            </div>                             
                            <div className="wChatMsg">
                                <img src="https://uinames.com/api/photos/male/4.jpg" alt="" className="wChatMsgImg" />
                                <div className="wChatMsgBody">
                                Самсунг нь дэлхийд анхны гэгдэж байгаа нугардаг дэлгэцтэй гар утас Galaxy Fold-ийг саяхан худалдаанд гаргасан билээ. 
                                </div>  
                            </div> 
                            <div className="wChatMsg">
                                <img src="https://uinames.com/api/photos/male/4.jpg" alt="" className="wChatMsgImg" />
                                <div className="wChatMsgBody">
                                Гэхдээ тэр утас нь нээх үнэтэй юм шиг байна лээ.
                                </div>  
                            </div> 
                            <div className="wChatMsg">
                                <img src="https://uinames.com/api/photos/male/4.jpg" alt="" className="wChatMsgImg" />
                                <div className="wChatMsgBody">
                                Galaxy Fold анх үйлдвэрээс гармагцаа техникийн олон асуудалтай тулгарснаас нээлтийг нь хэдэн сараар хойшлуулж байсан юм.
                                </div>  
                            </div>                                                          
                        </div>                      
                        <div className="wChatBottom">
                            <img src="https://uinames.com/api/photos/male/7.jpg" alt="" className="wChatUserImg" />
                            <div className="wChatType">
                                <input type="text" placeholder="Чат бичих" class="wChatInput"></input>
                                <ul className="wChatInputControl">
                                    <li>
                                        <Icon type="audio" />
                                    </li>
                                    <li>
                                        <Icon type="paper-clip" />
                                    </li>
                                    <li>
                                        <Icon type="smile" />
                                    </li>
                                </ul>
                            </div>    
                        </div>
                    </Col>
                    <Col span={6} className="wChatUserInfo">
                        <div className="wChatUserPro">
                            <img src="https://uinames.com/api/photos/male/4.jpg" alt="" />
                            <h3>Баяраа</h3>
                            <span>bayar@callpro.mn</span>
                        </div>
                        <ul className="wUserControl">
                          <li>
                            <span>
                              <Icon type="phone" className="wControlIcon" />
                              Захиалгууд
                            </span>
                          </li>
                          <li>
                            <span>
                              <Icon type="question-circle" className="wControlIcon" />
                              Тикетүүд
                            </span>
                          </li>
                          <li>
                            <span>
                              <Icon type="user" className="wControlIcon" />
                              Харилцагчид
                            </span>
                          </li>
                          <li>
                            <span>
                              <Icon type="copy" className="wControlIcon" />
                              Түүх
                            </span>
                          </li>
                        </ul>
                        <Divider style={{ backgroundColor: '#F5F5F5' }} />
                        <h3 className="wUserControlHeading">Тохиргоо</h3>
                        <ul className="wUserControl">
                          <li>
                            <span>
                              <Icon type="phone" className="wControlIcon" />
                              Mute
                            </span>
                          </li>
                          <li>
                            <span>
                              <Icon type="delete" className="wControlIcon" />
                              Түүх устгах
                            </span>
                          </li>
                        </ul>                        
                        <Divider style={{ backgroundColor: '#F5F5F5' }} />
                        <h3 className="wUserControlHeading">Хүмүүс</h3>
                        <ul className="wUsersList">
                          <li>
                            <Row>
                              <Col span={3}>
                                <Avatar src="https://uinames.com/api/photos/male/6.jpg" />
                              </Col>
                              <Col span={21}>
                                <h3 className="workChatUsername">Баяр</h3>
                                <span className="workChatUserType">Техник</span>
                              </Col>
                            </Row>
                          </li>
                          <li>
                            <Row>
                              <Col span={3}>
                                <Avatar src="https://uinames.com/api/photos/male/7.jpg" />
                              </Col>
                              <Col span={21}>
                                <h3 className="workChatUsername">Цэнгүүн</h3>
                                <span className="workChatUserType">Техник</span>
                              </Col>
                            </Row>
                          </li>
                        </ul>                         
                    </Col>
                </Row>
            </Layout>)
    }

};

export default Workchat;
