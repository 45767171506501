import AgoraRTC from "agora-rtc-sdk";
import qs from 'qs';
const axios = require('axios');

// AgoraRtcEngine.Logger.setLogLevel(AgoraRtcEngine.Logger.NONE);

// if (!AgoraRtcEngine.checkSystemRequirements()) {
//     alert("browser does not support webRTC");
// }

/**
  * TOOLS FUNCTIONS =================================
*/

const randomGUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

// function startSeconds() {
//     timer.start();
//     timer.addEventListener('secondsUpdated', function (e) {
//         $('#seconds-counter').html(timer.getTimeValues().toString());
//     });
// }

// function stopSeconds() {
//     timer.stop();
// }

// function play_sound(sound) {
//     audio = document.getElementById('playAudio');
//     if (sound == 'play') {
//         audio.play();
//     } else {
//         audio.pause();
//     }
// }

var localStreamUIDs = [];


class RTC_Agora {

    static join(AppId, ChannelName, Token, Hash, Uid, Action = 'sip', microphoneId, cameraId) {

        var isVideo = false;
        var isScreenShare = false;
        var agora_uid = Uid;

        if (Action == 'rtc') {
            isVideo = true;
            isScreenShare = false;
            agora_uid = Uid;
        } else if (Action == 'ss') {
            isVideo = false;
            isScreenShare = true;
            agora_uid = '1' + Uid;
        }

        var client = AgoraRTC.createClient({
            mode: 'live'
        });

        localStreamUIDs.push(Uid);

        client.init(AppId, function () {
            client.join(Token, ChannelName, agora_uid, function (uid) {
                // var camera = videoSource.value;
                // var microphone = audioSource.value;

                const localStream = AgoraRTC.createStream({
                    streamID: uid,
                    audio: true,
                    cameraId,
                    microphoneId,
                    video: false,
                    screen: false
                });
                localStream.setVideoProfile('720p_2');

                localStream.init(function () {
                    localStream.play('agora_local');

                    client.publish(localStream, function (err) {
                        console.log("Publish local stream error: " + err);
                    });
                }, function (err) {
                    console.log("getUserMedia failed", err);
                });
            }, function (err) {
                console.log("Join channel failed", err);
            });
        }, function (err) {
            console.log("RTC client init failed", err);
        });

        client.on('stream-added', function (evt) {
            var stream = evt.stream;
            var uid = stream.getId();

            if (!localStreamUIDs.includes(uid)) {
                console.log('subscribe stream: ' + uid);
                // Subscribe to the stream.
                client.subscribe(stream, function (err) {
                    console.log("Subscribe stream failed", err);
                });
            }
        });

        client.on('stream-subscribed', function (evt) {
            var stream = evt.stream;
            // if ($('div#video #agora_remote' + stream.getId()).length === 0) {
            //     $('div#video').append('<div id="agora_remote' + stream.getId() + '" style="float:left; top:0px; right:0px; width:100%;height:800px;display:inline-block;"></div>');
            // }

            var elementId = 'agora_remote' + stream.getId();
            var element =  document.getElementById(elementId);

            if (typeof(element) == 'undefined' || element == null)
            {
                var div_node = document.createElement("div");
                div_node.setAttribute('id', elementId);

                document.getElementById("video").appendChild(div_node);
            }

            stream.play(elementId);
            
        });

        client.on('stream-removed', function (evt) {
            console.log('Stream removed: ', evt.streamID);
        });

        client.on('peer-leave', function (evt) {
            var stream = evt.stream;
            if (stream) {
                stream.stop();
                // $('#agora_remote' + stream.getId()).remove();
            }

            client.leave(function () {
                console.log('Successfully left channel!');
                // $('#agora_local').hide();
            }, function (err) {
                console.log('Leave channel failed...');
                // location.reload();
            });
        });

        if (Hash) {
            // socketOutBound.emit('room', ChannelName);
            // var data = {
            //     channelName: ChannelName,
            //     type: "call",
            //     hash: Hash
            // }
            // socketOutBound.emit('message', JSON.stringify(data));
        }

        const agoraClient = client;

        return client;
    }

    // muteAudio() {
    //     // $('#unmuteAudio').show();
    //     // $('#muteAudio').hide();
    //     localStream.disableAudio();
    // }

    // unmuteAudio() {
    //     // $('#unmuteAudio').hide();
    //     // $('#muteAudio').show();
    //     localStream.enableAudio();
    // }

    // enableVideo() {
    //     // $('#disableVideo').show();
    //     // $('#enableVideo').hide();
    //     localStream.enableVideo();
    // }

    // disableVideo() {
    //     // $('#disableVideo').hide();
    //     // $('#enableVideo').show();
    //     localStream.disableVideo();
    // }
}

/**
  * DEMO CLIENT CLASS =================================
*/
class RTC_Client {

    constructor(tenant) {
        this.tenantName = tenant;

        this.apiKey = "rb6WmGcpRz4NNgU92qVE4aZYEVx9eYb95GpDmZRH";
        this.username = "demo@callpro.mn";
        this.password = "password123";
    }

    initialize() {
        const loginUrl = 'https://api.callpro.mn/v1/login';
        const apiKey = this.apiKey;
        const username = this.username;
        const password = this.password;

        const data = {
            name: username,
            password: password,
            type: "web",
            token: ""
        };
        const options = {
            method: 'POST',
            headers: { 'X-Api-Key': apiKey },
            data: qs.stringify(data),
            url: loginUrl
        };

        axios(options)
            .then(function (response) {
                // handle success
                console.log(response.data.result);

                const callToken = response.data.result.callToken;
                const tenant = response.data.result.tenant;
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }

    // getCallToken() {
    //     return $('#rtc-call-token').val();
    // }

    // getChannelName() {
    //     return $('#rtc-channel-name').val();
    // }

    // getHash() {
    //     return $('#rtc-hash').val();
    // }

    // getChannelToken() {
    //     return $('#rtc-channel-token').val();
    // }

    // getAppId() {
    //     return $('#rtc-app-id').val();
    // }

    // call(callType) {

    //     var destNumber = "5021";
    //     oponentNumber = destNumber;
    //     var video = 0;

    //     if ($.trim(destNumber) === "") {
    //         alert('Ð”ÑƒÐ³Ð°Ð°Ñ€Ð°Ð° Ð¾Ñ€ÑƒÑƒÐ»Ð½Ð° ÑƒÑƒ');
    //     } else {
    //         $('#join').prop('disabled', true);
    //         $('#video_call').prop('disabled', true);

    //         if (callType == 'rtc') {
    //             $('div.voice-call-avatar').hide();
    //             video = 1;
    //         } else if (callType == 'sip') {
    //             $('div.voice-call-avatar').show();
    //             video = 0;
    //         }

    //         $('#hangup_outbound_call').disabled = false;

    //         const callUrl = 'https://api.callpro.mn/v1/call';
    //         const apiKey = this.apiKey;
    //         const callToken = this.getCallToken();
    //         const userNumber = $('#rtc-user-number').val();

    //         $.ajax({
    //             url: callUrl,
    //             type: 'POST',
    //             beforeSend: function (xhr) {
    //                 xhr.setRequestHeader("X-Api-Key", apiKey);
    //             },
    //             data: '{"callToken": "' + callToken + '", "type": "' + callType + '", "destination": "' + destNumber + '", "video": ' + video + ', "deviceToken": ""}',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             dataType: 'json',
    //             success: function (data) {
    //                 console.log('RTC Call:RESULT:', data);

    //                 var uid = parseInt(userNumber);

    //                 $('#rtc-channel-name').val(data.channelName);
    //                 $('#rtc-hash').val(data.hash);
    //                 $('#rtc-channel-token').val(data.channelToken);
    //                 $('#rtc-app-id').val(data.appId);

    //                 RTC_Agora.join(data.appId, data.channelName, data.channelToken, data.hash, uid, callType);
    //             }
    //         });
    //     }
    // }

    answer(answerType, inBoundAppId, inBoundChannel, inBoundToken, inBoundHash, socketOutBound, microphoneId, cameraId) {
        const userNumber = localStorage.getItem('local_number')

        // play_sound('stop');

        if (answerType == 'sip') {
            var socketData = {
                channelName: inBoundChannel,
                type: "answer",
                hash: inBoundHash
            }

            socketOutBound.emit('message', JSON.stringify(socketData));
        } else {
            var socketData = {
                channelName: inBoundChannel,
                type: "answer",
                hash: inBoundHash
            }

            socketOutBound.emit('message', JSON.stringify(socketData));
        }

        let uid = parseInt(userNumber);

        return RTC_Agora.join(inBoundAppId, inBoundChannel, inBoundToken, inBoundHash, uid, answerType, microphoneId, cameraId);
    }

    // screenShare() {
    //     const channelName = this.getChannelName();
    //     const userNumber = $('#rtc-user-number').val();
    //     const hash = this.getHash();
    //     const channelToken = this.getChannelToken();
    //     const appId = this.getAppId();

    //     let uid = parseInt(userNumber);

    //     RTC_Agora.join(appId, channelName, channelToken, hash, uid, 'ss');
    // }

    hangUp(type, channelName, hash, socket) {

        // play_sound('stop');
        // stopSeconds();

        if (type == 'inbound') {
            var socketData = {
                type: "hangup",
                channelName,
                hash
            }

            socket.emit('message', JSON.stringify(socketData));
            console.log('HangUp InBound Call:MESSAGE', socketData);
        } else if (type == 'outbound') {
            // agoraClient.leave(function () {
            //     console.log("Leavel channel successfully");
            // }, function (err) {
            //     console.log("Leave channel failed");
            // });

            var channelName = this.getChannelName();
            var hash = this.getHash();
            var socketData = {
                type: "hangup",
                channelName,
                hash
            }

            socket.emit('message', JSON.stringify(socketData));

            console.log('HangUp OutBound Call:MESSAGE', socketData);
        }
    }

    // foodMenu() {
    //     var channelName = this.getChannelName();
    //     var hash = this.getHash();

    //     var socketData = {
    //         channelName,
    //         type: "menu",
    //         hash
    //     }

    //     console.log('FoodMenu: ', socketData);
    //     socketOutBound.emit('message', JSON.stringify(socketData));
    // }


    // closeMenu() {
    //     $('#menu_modal').modal('hide');
    // }
}

/**
  * AGORA CONFIGURATION =================================
*/
// var audioSelect = document.querySelector('select#audioSource');
// var videoSelect = document.querySelector('select#videoSource');

// $('#enableVideo').hide();
// $('#unmuteAudio').hide();


// function getDevices() {
//     AgoraRTC.getDevices(function (devices) {
//         for (var i = 0; i !== devices.length; ++i) {
//             var device = devices[i];
//             var option = document.createElement('option');
//             option.value = device.deviceId;
//             if (device.kind === 'audioinput') {
//                 option.text = device.label || 'microphone ' + (audioSelect.length + 1);
//                 audioSelect.appendChild(option);
//             } else if (device.kind === 'videoinput') {
//                 option.text = device.label || 'camera ' + (videoSelect.length + 1);
//                 videoSelect.appendChild(option);
//             } else {
//                 console.log('Some other kind of source/device: ', device);
//             }
//         }
//     });
// }

// getDevices();

export default RTC_Client;