import React from 'react';
import { Layout, Row, Col, Icon, Button, message } from 'antd';
import { Input } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import './style.css';
import CallProLogo from '../../../assets/logo@2x.png';
import sourceMap from '../../../assets/map.jpg';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = ({
    })
  }

  
  render() {
  return(
    <Layout style={{ backgroundColor: '#fff' }}>
      <div className="homeTopbar">
        <Link to="/">
          <img src={CallProLogo} alt="" className="topBarLogo" />
        </Link>
        <ul className="homeTopRight">
          <li>
            <Link to="about" className="homeLinkGhost">Тухай</Link>
          </li>
          <li>
            <Link to="contact">Холбогдох</Link>
          </li>
        </ul>
      </div>
      <Row className="homeAbout">
        <Col span={24} className="homeContactForm">
          <h2>Бидний тухай</h2>
        </Col>
      </Row>
      <Row className="homeFooter">
        <Col span={12}>
          <Link to="/">
            <img src={CallProLogo} alt="Call Pro CRM" className="footerLogo" /> 
          </Link>
          <span className="homeCopyright">| Copyright &copy; 2019 Callpro</span>
        </Col>
        <Col span={12}>
          <ul className="footerSocial">
            <li> 
              <a href="#">
                <Icon type="facebook" />
              </a>   
            </li>
            <li>
              <a href="#">
                <Icon type="twitter" />
              </a>              
            </li>
            <li>
              <a href="#">
                <Icon type="instagram" />
              </a>
            </li>
            <li>
              <a href="#">
                <Icon type="youtube" />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      </Layout>)
  }};

  export default withRouter(About);

