import React from 'react';
import './style.css';
import { App as SendBirdApp } from 'sendbird-uikit';
import 'sendbird-uikit/dist/index.css';
class ChatBot extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            chatWidth: 216,
            chatShow: true,
            groupShow: true,
            onChat: false,
        };
    };

    render(){
        return(
            <SendBirdApp
            appId="19F90214-F3A3-4338-AF57-529A98CFE914"
            userId={"crm_" + localStorage.getItem('user_id')}
            nickname={localStorage.getItem('user_name')}
            /> 
            )
    }

};

export default ChatBot;
