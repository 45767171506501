import React from 'react';
import './style.css';
import { Icon } from 'antd';
import { NavLink } from 'react-router-dom';

class SiderOrderComponent extends React.Component{
    
    state = {
        showMenu: true,
      };
    
    render(){
        const { showMenu } = this.state;
        return(
        <div className="siderMenu" style={{ width: 168 }}>
          <h3 className="siderHeader">
          Захиалгууд
          </h3>
          <NavLink to="/admin/order/dashboard" className="sideBtn" activeClassName={"sideBtn-active"} onClick={() => this.setState({ showMenu: false })}>
              <Icon type="pie-chart" style={{ marginRight: 8, fontSize: 12 }} />
              Хянах самбар
          </NavLink>
          <NavLink to="/admin/order/list" className="sideBtn" activeClassName={"sideBtn-active"} onClick={() => this.setState({ showMenu: !showMenu })}>
              <Icon type="menu" style={{ marginRight: 8, fontSize: 12 }} />
              Бүх захиалгууд
          </NavLink>            
          {
            showMenu === true
            ?
            <div>
              <div className="sideNavList">
                <div className="sideNavBtn">
                  <Icon type="phone" style={{ marginRight: 8, fontSize: 12 }} />
                  <span className="sideNavTitle">Дуудлага</span>
                  <span className="sideNavCount">310</span>
                </div>                
                <div className="sideNavBtn">
                  <Icon type="phone" style={{ marginRight: 8, fontSize: 12 }} />
                  <span className="sideNavTitle">SMS</span>
                  <span className="sideNavCount">20</span>
                </div>                   
                <div className="sideNavBtn">
                  <Icon type="mail" style={{ marginRight: 8, fontSize: 12 }} />
                  <span className="sideNavTitle">И-Мэйл</span>
                  <span className="sideNavCount">136</span>
                </div>                  
                <div className="sideNavBtn">
                  <Icon type="twitter" style={{ marginRight: 8, fontSize: 12 }} />
                  <span className="sideNavTitle">Twitter</span>
                  <span className="sideNavCount">11</span>
                </div>                   
                <div className="sideNavBtn">
                  <Icon type="skype" style={{ marginRight: 8, fontSize: 12 }} />
                  <span className="sideNavTitle">Viber</span>
                  <span className="sideNavCount">11</span>
                </div>                               
              </div>     
              <div className="customFilter">
                <p>Хугацаа хэтэрсэн</p>   
                  <div className="sideNavBtn">
                    <Icon type="filter" style={{ marginRight: 8, fontSize: 12 }} />
                    <span className="sideNavTitle">Filter Name</span>
                    {/* <span className="sideNavCount">2</span> */}
                  </div>                  
                  <div className="sideNavBtn">
                    <Icon type="clock-circle" style={{ marginRight: 8, fontSize: 12 }} />
                    <span className="sideNavTitle">Хугацаа хэтэрсэн</span>
                    <span className="sideNavCount">2</span>
                  </div>                  
              </div>
            </div>     
              :
              null

            }       
        </div>
        )
    }

};

export default SiderOrderComponent;
