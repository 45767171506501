import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';
import { Layout, Icon} from 'antd';
import { OrderList,
         OrderDashboard,
         TicketDashboard,
         TicketList,
         TicketShow,
         TicketNew,
         KnowledgeBase,
         Workchat,
         AdminProfile,
         Notfound,
         Dashboard,
         Example,
         TicketOverdue,
         TicketForme,
         TicketBy,
         Settings,
         TicketCust,
         TicketDep,
         Users,
         ChatBot
        } from './';
import { HeaderComponent,
         MasterMenuComponent,
         SidebarChatComponent,
         SiderTicketComponent,
         SiderOrderListComponent,
         SiderTicketDetailComponent,
         KnowledgeComponent,
         SiderTicketListComponent,
         SiderOrderComponent,
         SiderTicketUserComponent,
         //SiderTicketOverdueComponent,
         IncomingCallComponent,
         SiderDashboardComponent
         } from '../../components';
import './App.css';
import { CONN } from '../../constant/types';
import axios from 'axios';
import SendBird from 'sendbird';
import SendBirdWidget from './../../components/SiderChatNew/widget.SendBird';

const {Sider} = Layout;
const user_id = "crm_" + localStorage.getItem('user_id');
const user_name = localStorage.getItem('user_name')

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      marginRight: 216,
      knowComponent: false,
    }
    this.isOpened = this.isOpened.bind(this);
  }

  componentDidMount() {
    this.checkLoginStatus();
    window.SendBird = SendBird;
    SendBirdWidget.startWithConnect('19F90214-F3A3-4338-AF57-529A98CFE914', user_id, user_name);
  }

  logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('local_number');
    localStorage.removeItem('user_id');
    document.location.href = '/';
  }

  checkLoginStatus() {
    const token = localStorage.getItem('token');

    if (token && token !== null && token != "" && typeof token !== 'undefined') {
      console.log('YOU ARE LOGGED IN');

      var me = this;

      axios({
        method: 'GET',
        url: `${CONN}/check_session`,
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      })
        .then(function (response) {
          var r = response.data;
          console.log('LOGINOOO', r);

          if(r.status == 'ok') {
            console.log('YOU ARE LOGGED IN AND TOKEN ALIVE');
          } else {
            console.log('YOU ARE LOGGED IN BUT TOKEN DEAD!');
            me.logout();
          }
        })
        .catch(function (error) {
          console.log(error);
          if(error && error.response && typeof error.response.status !== undefined && error.response.status == 401){
            me.logout();
          }
        })
        .finally(function () {
          // always executed
        });
    } else {
      console.log('YOU ARE NOT LOGGED IN!!!!!!!!!!!!');
    }
  }

  isOpened = () => {
    this.setState({
      isOpened: !this.state.isOpened
    });
  }

  chatCollapseON = () => {
    this.setState({
      marginRight: 70
    })
  }

  chatCollapseOff = () => {
    this.setState({
      marginRight: 216
    })
  }

  

  render() {
    const { marginRight } = this.state;
    return (
      <Layout className="App">
        {
          this.state.isOpened === true
            ?
            <KnowledgeComponent />
            :
            null
        }
        <div className="knowledge-master" onClick={this.isOpened} style={{position:'fixed'}}>
          <Icon type="folder-open" />
        </div>
          <div id="sb_widget"></div>
        <Layout>
          <HeaderComponent />
          <IncomingCallComponent />
        </Layout>
        <Layout style={{ position: 'fixed', left: 0, height: '100%', top: 48 }}>
          <MasterMenuComponent />
        </Layout>
          <Switch>
            <Layout style={{ position: 'fixed', height: '100%', left: 48, top: 48 }}>
            
              <Route exact path="/" component={SiderOrderComponent} />
              <Route exact path="/admin" component={SiderOrderComponent} />
              <Route path="/admin/order/list" component={SiderOrderListComponent} />
              <Route path="/admin/ticket/dashboard" component={SiderTicketListComponent} />
              <Route path="/admin/ticket/list" component={SiderTicketListComponent} />
              <Route path="/admin/ticket/overdue" component={SiderTicketListComponent} />
              <Route path="/admin/ticket/forme" component={SiderTicketListComponent} />
              <Route path="/admin/ticket/new" component={SiderTicketListComponent} />
              <Route path="/admin/ticket/bycustomer" component={SiderTicketListComponent} />
              <Route path="/admin/ticket/customer" component={SiderTicketListComponent} />
              <Route path="/admin/ticket/department" component={SiderTicketListComponent} />
              <Route path="/admin/dashboard/ticket" component={SiderDashboardComponent} />
              <Route path="/admin/dashboard/order" component={SiderDashboardComponent} />
            </Layout>
          </Switch>
        <Layout style={{ marginLeft: 216, marginRight: 0, height: '100%' }}>
          <Switch>
            <Route exact path="/admin" component={Dashboard} />
            <Route path="/admin/example/" component={Example} />
            <Route path="/admin/order/list" component={OrderList} />
            <Route path="/admin/ticket/dashboard" component={TicketDashboard} />
            <Route path="/admin/ticket/list" component={TicketList} />
            <Route path="/admin/ticket/new" component={TicketNew} />
            <Route path="/admin/ticket/overdue" component={TicketOverdue} />
            <Route path="/admin/ticket/forme" component={TicketForme} />
            <Route path="/admin/ticket/bycustomer" component={TicketBy} />
            <Route path="/admin/ticket/customer" component={TicketCust} />
            <Route path="/admin/ticket/department" component={TicketDep} />
            <Route path="/admin/dashboard/ticket" component={TicketDashboard} />
            <Route path="/admin/dashboard/order" component={OrderDashboard} />
      </Switch>
        </Layout>
        <Layout>
          <Switch>
            <Route path="/admin/user" component={Users} />
            <Route path="/admin/ticket/show" component={TicketShow} />
            <Route path="/admin/profile" component={AdminProfile} />
          </Switch>
        </Layout>
        <Layout style={{ marginLeft: 48, marginRight: 0, marginTop: 48, height:'95vh'}}>
          <Switch>
            <Route path="/admin/knowledge" component={KnowledgeBase} />
            <Route path="/admin/chatbot" component={ChatBot} />
            <Route path="/admin/settings" component={Settings} />
          </Switch>
        </Layout>

        {/*<Switch>
          <Layout style={{ position: 'fixed', right: 0, height: '100vh', top: 48 }}>
            <Route exact path="/" component={SidebarChatComponent} />
            <Route exact path="/admin" component={SidebarChatComponent} />
            <Route path="/admin/order/dashboard" component={SidebarChatComponent} />
            <Route path="/admin/order/list" component={SidebarChatComponent} />
            <Route path="/admin/ticket/dashboard" component={SidebarChatComponent} />
            <Route path="/admin/ticket/list" component={SidebarChatComponent} />
            <Route path="/admin/ticket/overdue" component={SidebarChatComponent} />    
            <Route path="/admin/ticket/forme" component={SidebarChatComponent} />    
            <Route path="/admin/ticket/bycustomer" component={SidebarChatComponent} />
            <Route path="/admin/ticket/customer" component={SidebarChatComponent} />
            <Route path="/admin/ticket/new" component={SidebarChatComponent} />
            <Route path="/admin/ticket/department" component={SidebarChatComponent} />
          </Layout>
        </Switch>*/}
      </Layout>
    )  
  }

};

export default App;
