/* eslint-disable no-unused-expressions */
import React, {Component} from 'react';
import { Layout, Table, Tabs, Icon, Row, Col, Button, Input, Tooltip, message, Menu, Dropdown, Popover, Select,  Timeline } from 'antd';
import './style.css';
import { EditorState, convertFromRaw } from 'draft-js';
import { CONN } from '../../../../constant/types';
import { Editor } from 'react-draft-wysiwyg';
import sourceViber from '../../../../assets/icons/contacts/viber.svg';
import sourcePhone from '../../../../assets/icons/contacts/call.svg';
import sourceMessenger from '../../../../assets/icons/contacts/mail.svg';
import axios from 'axios';
import moment from 'react-moment'

import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const { Content } =  Layout;

const content = {"entityMap":{},"blocks":[{"key":"637gr","text":"Initialized from content state.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]};

class TicketNew extends React.Component {
  constructor(props) {
    super(props);
    const contentState = convertFromRaw(content);
    this.state = {
      contentState,
      users: [],
      owner_id: '',
      owner_name:'',
      assignee: [],
      assignee_names: [],   
      title: '',
      code:'',
      id:'',
      address: '',
      created_at: '',
      deadline: '',
      
      firstname:'',
      lastname:'',

      phone: '',
      viber: '',
      messenger: '',
      twitter: '',
      mail:'',

      token: localStorage.getItem('token'),


      status_id:'',
      status_name: '',
      topic_id:'',
      topic_name: '',
      priority_id: '',
      priority_name:'',
      source_id: '',
      source_name: '',
      customer_id: '',
      statuses: [],
      topics: [],
      priorities: [],
      ticket_id: [],
      sources: [],
      source_after: [],

    }
    //Доорх функцуудыг bind хийх хэрэгтэй
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
  }

  
  //Ажилласан үед дуудагдана
  componentDidMount() {
    message.info("Уншиж байна.")
    this.fetchTickets();
   
    var timeStamp = Math.floor(Date.now() / 1000);
    this.setState({created_at: timeStamp})
  }
  //Хэрэгцээт мэдээллүүдийг API-аас авах
  fetchTickets = () => {
    const config = {
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    };

    var assign;
      var usery;
      var ulength;
      var alength;
      var check = 0;
      var owner;
      
    //Хэрэглэгчдийн мэдээллийг авах
    axios
    .get(`${CONN}/users`,config)
    .then(response => {
      this.setState({users: response.data.data})
    })
    //Төлөвийн мэдээллийг авах
    axios
    .get(`${CONN}/ticket_statuses`,config)
    .then(response => {
      this.setState({statuses: response.data})
      //console.log(this.state.statuses)
    })
    //Төрөлийн мэдээллийг авах
    axios
    .get(`${CONN}/ticket_topics`,config)
    .then(response => {
      this.setState({topics: response.data})
      //console.log(this.state.topics)
    })
    //Түвшний мэдээллийг авах
    axios
    .get(`${CONN}/ticket_priorities`,config)
    .then(response => {
      this.setState({priorities: response.data})
      //console.log(this.state.priorities)
    })
    //Source_id мэдээллийг авах
    axios
    .get(`${CONN}/sources`,config)
    .then(response => {
      this.setState({sources: response.data})
      //console.log(this.state.sources)
      message.success("Бэлэн боллоо.")
    })
  }
  
  //setState үйлдлийг хийх
  changeHandler = e => {
		this.setState({ [e.target.name]: e.target.value })
  }
  
  //Илгээх товч дарагдахад дуудагдана
  onSend = () => {
    console.log(this.state)
    
    //source_id -игй өөрчлөх хэрэв талбар утга авсан бол id -г онооно
    //console.log(this.state)
   

    //Доорх хэсэгт үйлдлийн дарааллыг дугаарлав
    //1. Үйлчлүүлэгчийг үүсгэх /Шинэ үүссэн ID response -д буцна/
    //2. Тикет үүсгэх /Үйлүүлэгчийн ID ашиглана, ШинэШинэ Тикет ID response -д буцна/
    //3. Оролцогчид үүсгэх /Шинэ Тикет ID ашиглана/

    //Харилцагч үүсгэх
    axios({
        method: 'post',
        url: `${CONN}/customers`,
        data: {
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          mobile: this.state.phone,
          source_id: this.state.source_id,
          viber: this.state.viber,
          address: this.state.address,
          messenger: this.state.messenger
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      })
      .then(response => {
        //console.log(response.data.data.id)
        if(response.status == 200) message.success("Хэрэглэгч амжилттай үүслээ.")
        this.setState({customer_id: response.data.data.id})
          
          //Тикет үүсгэх
          axios({
            method: 'post',
            url: `${CONN}/tickets`,
            header: {
              "Accept": 'application/json',
              "Content-Type": 'application/json',
            },
            data: {
              title: this.state.title,
              code: this.props.location.state.code,
              status_id: this.state.status_id,
              topic_id: this.state.topic_id,
              priority_id: this.state.priority_id,
              customer_id: this.state.customer_id,
              owner_id: this.state.owner_id,
              deadline: this.state.deadline,
              created_at: this.state.created_at
            },
            headers: {
              'Authorization': `Bearer  ${this.state.token}`
            }
          })
          .then(response => {
            this.setState({id: response.data.data.id})
            //console.log(response)
            //console.log(this.state)
            if(response.status == 200) message.success("Тикет амжилттай үүслээ.")
              
              //Оролцогч үүсгэх *Олон оролцогч нэмж болох тул давталт ашиглав*
              for(var i=0; i<this.state.assignee.length; i++){
                axios({
                  method: 'post',
                  url: `${CONN}/ticket_assignee`,
                  data: {
                    user_id: this.state.assignee[i],
                    ticket_id: this.state.id
                  },
                  headers: {
                    'Authorization': `Bearer  ${this.state.token}`
                  }
                })
                .then(response=> {
                  //console.log(response)
                  if (response.status == 200) {
                    message.success("Оролцогчид амжилттай үүслээ.")
                    this.props.history.push({
                      pathname: '/admin/ticket/list'
                    });
                  }
                })
                //Оролцогч үүсгэх алдаа
                .catch(error => {
                  console.log(error)
                  message.error("Алдаа гарлаа... Дахин оролдоно уу! - Оролцогч үүсгэх")
                  //console.log(this.state)
                })
              }
          })
          //Тикет үүсгэх алдаа
          .catch(error => {
            console.log(error)
            if(error == 404){
            message.warning('Алдаа гарлаа... Дахин оролдоно уу! - Тикет үүсгэх');
            }else message.warning('Алдаа гарлаа... Дахин оролдоно уу! - Тикет үүсгэх');
          });
      })
      //Харилцагч үүсгэх алдаа
      .catch(error => {
        console.log(error)
        if(error == 404){
        message.warning('Алдаа гарлаа... Дахин оролдоно уу! - Хэрэглэгч үүсгэх');
        }else message.warning('Алдаа гарлаа... Дахин оролдоно уу! - Хэрэглэгч үүсгэх');
      });
  }

  //Хариуцагчийн нэр сонгоход ID өөрчлөх
  handleChange(value, label) {
    this.setState({owner_id: value, owner_name: label.props.label})
    console.log('sad', label.props.label)
  }

  //Оролцогчийн нэр сонгоход ID өөрчлөх
  handleChange1(value, label) {
    this.setState({assignee: value })
   //this.setState({assignee_names: label.props.label})
    console.log();
  }

  render() {
    const {users } = this.state;

    
    //Төлөв өөрчлөх хэсэг
    const onChangeStatus = (
      <Menu>
        {
          this.state.statuses.map(stats => (
            <Menu.Item>
              <Button block type="link" 
                onClick={() => this.setState({
                  status_id: stats.id,
                  status_name: stats.name
                })}>
                {stats.name}
              </Button>
            </Menu.Item>
          ))
        }
      </Menu>
    );
    
    //Төрөл өөрчлөх хэсэг
    const onChangeTopic = (
      <Menu>
        {
          this.state.topics.map(stats => (
            <Menu.Item>
              <Button block type="link" 
                onClick={() => this.setState({
                  topic_id: stats.id,
                  topic_name: stats.name
                })}>
                {stats.name}
              </Button>
            </Menu.Item>
          ))
        }
      </Menu>
    );
    
    //Түвшин өөрчлөх
    const onChangePriority = (
      <Menu>
        {
          this.state.priorities.map(stats => (
            <Menu.Item>
              <Button block type="link" 
                onClick={() => this.setState({
                  priority_id: stats.id,
                  priority_name: stats.name
                })}>
                {stats.name}
              </Button>
            </Menu.Item>
          ))
        }
      </Menu>
    );

    //SourceId өөрчлөх
    const onChangeSource = (
      <Menu>
        {
          this.state.sources.map(stats => (
            <Menu.Item>
              <Button block type="link" 
                onClick={() => this.setState({
                  source_id: stats.id,
                  source_name: stats.keyword
                })}>
                {stats.keyword}
              </Button>
            </Menu.Item>
          ))
        }
      </Menu>
    );
    
    //Хариуцагч өөрчлөхөд сонголтуудыг харуулах *Зөвхөн нэг*
    const { Option } = Select;
    const text = <span>Add Owner</span>;
    const content = (
      <div className="selectAssists">
        <Select
          style={{ width: '100%' }}
          autoFocus={true}
          maxTagCount={1}//Зөвхөн нэг сонгох
          placeholder="Add members"
          onChange={this.handleChange} //Өөрчлөлт хийгдэхэд дуудах
          optionLabelProp="label">
          {
            users.map(d => (
              <Option value={d.id} label={d.name}>
            <span className="select-Assistname">{d.name}</span>
          </Option>
            ))
          }           
        </Select>
      </div>
    );
    
    //Оролцогч өөрчлөхөд сонголтуудыг харуулах *Дээд тал нь 4*
    const text1 = <span>Add Assignee</span>;
    const content1 = (
      <div className="selectAssists">
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          autoFocus={true}
          maxTagCount={4} //Сонголтын дээд хязгаар
          placeholder="Add members"
          onChange={this.handleChange1}//Өөрчлөлт хийгдэхэд дуудах
          optionLabelProp="label">
          {
            users.map(d => (
              <Option value={d.id} label={d.name}>
            <span className="select-Assistname">{d.name}</span>
          </Option>
            ))
          }         
        </Select>
      </div>
    );
    
    //Харагдах хэсэг
    return(
      <Layout style={{ marginTop: 48, backgroundColor: '#ffffff' }}>
        
        <Content style={{ position: 'fixed', height: '100%', left: 400, width: 320, paddingLeft: 24, paddingRight: 24, borderLeft: 1, borderLeftColor: '#E8E8E8', borderLeftStyle: 'solid', backgroundColor: '#FAFAFA'   }}>
        <div className="ticketHeader">
                <h2>Тикет үүсгэх</h2>
                <h2>
                       <Input
                       name="title"
                       placeholder="Гарчиг"
                       onChange={this.changeHandler}
                       type="text"/>
                </h2>
                <span className="sideHeading">
                     Төрөл
                    </span> 
                    <div>
                    <Dropdown overlay={onChangeTopic}>
                      <Button block className="sideDropdown">
                        {this.state.topic_name} 
                        <Icon type="down" />
                      </Button>
                    </Dropdown>
                    </div>
                    <span className="sideHeading">
                     Төлөв
                    </span> 
                    <div>
                    <Dropdown overlay={onChangeStatus}>
                      <Button block className="sideDropdown open">
                        {this.state.status_name}
                        <Icon type="down" />
                      </Button>
                    </Dropdown> 
                    </div>
                    <span className="sideHeading">
                     Зэрэглэл
                    </span> 
                    <div>
                    <Dropdown overlay={onChangePriority}>
                      <Button block className="sideDropdown open">
                        {this.state.priority_name}
                        <Icon type="down" />
                      </Button>
                    </Dropdown> 
                    </div>
                    <span className="sideHeading">
                     Deadline
                    </span> 
                    <Col span={24}>
                      <span className="sideDeadline">
                      <Input
                          name="deadline"
                          type="date"
                          onChange={this.changeHandler}
                          className="ticketWrap-input input-from"
                        />
                      </span>
                    </Col>
                    <Col span={24} className="sideAssists">                    
                     <div className="sideHeading">
                      Хариуцагч:
                    </div> 
                    <ul className="assitsList">   
                      <li>
                      {this.state.owner_name}
                      <Popover placement="bottom" title={text}  content={content} trigger="click">
                        <div className="addAssist">
                          <Icon type="plus" style={{ color: '#597EF7' }} />
                        </div> 
                      </Popover>

                      </li>    
                     </ul>   
                    </Col>  
                    <Col span={24} className="sideAssists">             
                    <span className="sideHeading">
                      Оролцогч:
                     </span> 
                     <ul className="assitsList">   
                      <li>
                      <Popover placement="bottom" title={text1}  content={content1} trigger="click">
                        <div className="addAssist">
                          <Icon type="plus" style={{ color: '#597EF7' }} />
                        </div> 
                      </Popover>
                      </li>    
                     </ul>
                    </Col>
                    <li style={{ float: 'right'}}>
                      <Button block type="primary" block onClick={this.onSend}>
                          Илгээх
                      </Button>
                    </li> 
              </div>
          </Content>
        {/*Тикетийн мэдээлэл*/}
          <Content style={{position: 'fixed', marginLeft: 154, marginRight: 500, backgroundColor: '#FAFAFA' }}>
              
          </Content>

          {/*Харилцагчийн мэдээлэл*/}
          <Content style={{ position: 'fixed', height: '100%', right: 350, width: 320, paddingLeft: 24, paddingRight: 24, borderLeft: 1, borderLeftColor: '#E8E8E8', borderLeftStyle: 'solid', backgroundColor: '#FAFAFA'   }}>
            <Row>
            <Col className="userAvatar" span={24}>
               <h2>Харилцагчийн мэдээлэл</h2>
            </Col>
            </Row>
            <Row className="ticketUserDetail">
              <Col className="userAvatar" span={3}>
                <Icon type="user" />
              </Col>
              <Col span={18}>
                <Row>
                  <Col span={24}>
                    <h3 className="userName">
                      {
                       <Input
                       name="firstname"
                       placeholder="Firstname"
                       onChange={this.changeHandler}
                       type="Name"
                     />
                      }
                    </h3>
                    <h3 className="userName">
                      {
                       <Input
                       name="lastname"
                       placeholder="Lastname"
                       onChange={this.changeHandler}
                       type="Name"
                     />
                      }
                    </h3>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="ticketUserContact">
              <h3>Contacts</h3>
              <ul>
                {
                  this.state.sources.map(source => 
                    (<li>
                      <Row>
                        <Col span={3} style={{ textAlign: 'center', paddingTop: 6  }}>
                        <Icon type={source.keyword} style={{ color: '#597EF7' }} />
                        </Col>
                        <Col span={15} style={{ paddingTop: 7 }}>
                          <span className="contactInfo">
                            <Input
                            name={source.keyword}
                            placeholder={source.name}
                            onChange={this.changeHandler}
                            type="number"
                            />
                          </span>
                        </Col>
                      </Row>
                      
                    </li>
                    )
                  )
                  }              
              </ul>

              <ul>
                <li>
                  <Row >
                  <Col span={15} style={{ paddingTop: 7 }}>
                  <h4>Та аль хэрэгслээр мэдээлэл хүлээн авах вэ?</h4>
                  </Col>
                  </Row>
                  <Row>
                    <Col span={3} style={{ textAlign: 'center', paddingTop: 6  }}>
                    <Icon type="plus" style={{ color: '#597EF7' }} />
                    </Col>
                    <Col span={15} style={{ paddingTop: 7 }}>
                    <Dropdown overlay={onChangeSource}>
                        <Button block className="sideDropdown open">
                          {this.state.source_name}
                          <Icon type="down" />
                        </Button>
                      </Dropdown> 
                    </Col>
                  </Row>
                </li>
              </ul>
            </Row>
            <Row className="ticketUserAddress">
            <Col span={15} style={{ paddingTop: 7 }}>
              <h3>Address</h3>
              </Col>
              <Col span={18} style={{ paddingTop: 7 }}>
              <ul>
                <li>
                  {
                   <Input
                   name="address"
                   type = ""
                   placeholder="Хаяг"
                   onChange={this.changeHandler}
                   type="address"
                 /> 
                  }
                </li>
              </ul>
              </Col>                
            </Row>
            <Row className="ticketRelated">
              <h3>Холбоотой тикетүүд</h3>
              
            </Row>
          </Content>                    
      </Layout>
    )
  }

}

export default TicketNew;
