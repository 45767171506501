import React from 'react';
import {
  Layout,
  Icon,
  Row,
  Col,
  Button,
  Table,
  Popover,
  Menu,
  Dropdown,
  List,
  Tooltip,
  Pagination,
  Tag,
  Spin
} from 'antd';
import { Link } from 'react-router-dom';
import './style.css';
import { CONN } from '../../../../constant/types';
import Axios from 'axios';
import {TicketDashboard} from '../../Ticket';
const { Column } = Table;
const { Content,  Header, Sider} = Layout;
let array_status=[];
let array_users =[];
class TicketBy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
      page:'',
      id:0,
      users: [],
      selectedRowKeys: [],
      loading: true,
      customers: [],
      token: localStorage.getItem('token'),
      columns: [
        {
          title:'Хэрэглэгчид',
          dataIndex: 'all',
          render: all =>(
            all.is_active==1
            ?
            <div onClick={()=>this.props.history.push({pathname: `/admin/ticket/customer/${all.id}`,state: {id: all.id}})} style={{cursor:'pointer'}}>
              <Row>
                <span style={{fontSize:'16px', fontWeight:'bold'}}>{all.name}</span>
                <span style={{float:'right'}}><Tag>{all.created_at}</Tag></span>
                <span style={{float:'right'}}><Tag color='blue'>{all.email}</Tag></span>
                <span style={{float:'right'}}><Tag color='orange'>{all.mobile}</Tag></span>
              </Row>
            </div>
            :
            null
          )
        },
        
      ],
      dataSource: []
    }

  }

  componentDidMount() {
    var token = localStorage.getItem('token')
    const url = (`${CONN}/tickets?page=1`);
    const config = {
      headers: {
        'Authorization': `Bearer  ${token}`
      }
    };

    Axios
    .get(`${CONN}/customers?page=1`, config)
    .then((response) => {
      this.setState({
        customers: [...this.state.customers, ...response.data.data],
        page: response.data.meta.last_page,
        loading: true,
        total: response.data.meta.total
      });
      console.log("page", response)

    {
      let customers = this.state.customers;
      let ds = []

      customers.map((customer) => {
        let newRow = {
          all: customer,
          id: customer.id,
          firstname: customer.name== null ? "-": customer.name,
          email: customer.email== null ? "-": customer.email,
          phone_number: customer.mobile== null ? "-": customer.mobile,
          local: customer.local_number== null ? "-": customer.local_number
        };
        
        ds.push(newRow);
      });
      this.setState({
        dataSource: ds
      })
    }
    this.setState({loading: false})
    })
  }

  page =(value)=>{
    this.setState({loading: true})
    var token = localStorage.getItem('token')
    const config = {
      headers: {
        'Authorization': `Bearer  ${token}`
      }
    };
    Axios
    .get(`${CONN}/customers?page=${value}`, config)
    .then((response) => {
      this.setState({
        customers: response.data.data,
        page: response.data.meta.last_page,
        loading: true
      });
      console.log("page", response)

    {
      let customers = this.state.customers;
      let ds = []

      customers.map((customer) => {
        let newRow = {
          all: customer,
          id: customer.id,
          firstname: customer.name== null ? "-": customer.name,
          email: customer.email== null ? "-": customer.email,
          phone_number: customer.mobile== null ? "-": customer.mobile,
          local: customer.local_number== null ? "-": customer.local_number
        };
        
        ds.push(newRow);
      });
      this.setState({
        dataSource: ds
      })
    }
    this.setState({loading: false})
    })
  }

  start = () => {
    this.setState({ loading: true });
      this.setState({
        selectedRowKeys: [],
      });
  };

  onClick = () =>{
    console.log("Yes")
  }

  onSelectChange = selectedRowKeys => {
    //console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };



  render() {
    const titleSettings = <span>Тохиргоо</span>;
    

    const titleDownload = <span>Татаж авах</span>;
    const Download = (
      <div>
        <Button block type="primary" style={{ marginBottom: 10 }} block>
          CSV формат
        </Button>
        <Button block type="primary" block>
          JSON формат
        </Button>
      </div>
    );

    const customerMenu = (
      <Menu>
        {
          this.state.customers.map( stats=>(
            <Menu.Item>
            <Button block type="link">
              {stats.firstname}
            </Button>
          </Menu.Item>
          ))
        }
      </Menu>
    )
    

    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    function itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>Previous</a>;

      }
      if (type === 'next') {
        return <a>Next</a>;
      }
      return originalElement;
    }

    return (
      <Layout style={{ paddingTop: 48, paddingLeft: 15, paddingRight: 15, paddingBottom: 15, backgroundColor: '#ffffff' }}>
        <Header style = {{ paddingLeft: 15, paddingRight: 15, paddingBottom: 15, backgroundColor: '#ffffff' }}>
          <Row className="ticketControl">
            <ul className="ticketLeft">
              <li>
                <span className="ticketCtlBtn">
                  <Icon type="filter" />
                </span>
              </li>
              <li>
                <span className="ticketCtlBtn" onClick={this.start} disabled={!hasSelected} loading={loading}>
                  <Icon type="sync" />
                </span>
              </li>
              <li>
                <Popover placement="bottom" title={titleDownload} content={Download} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="download" />
                  </span>
                </Popover>
              </li>
              <li>
                {hasSelected ? `Сонгож авсан ${selectedRowKeys.length} тикет` : ''}
              </li>
            </ul>
          </Row>
        </Header>
        <Layout style = {{ paddingLeft: 15, paddingRight: 15, paddingBottom: 15, backgroundColor: '#ffffff' }}>
          <Content style={{ backgroundColor: '#fff', height: '100%' }}>
          <Spin spinning={this.state.loading} size="large" delay={500} tip="Ачааллаж байна... Түр хүлээнэ үү!">
            <Table dataSource={this.state.dataSource} columns={this.state.columns} size="small" className="orderTable" pagination={false} />
            {<Pagination
              total={this.state.total}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}            
              pageSize={15}
              onChange={(e=>{this.page(e)})}
              defaultCurrent={1}
              showQuickJumper 
              style={{float:'right', marginTop: '5px', marginRight: '30px'}}
            />}
          </Spin>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

export default TicketBy;
