/* eslint-disable no-unused-expressions */
import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Icon, Row, Col, Affix, Modal, Drawer, Dropdown, Button, Menu, Tabs, Table, Tag, Input, Comment, Avatar, Form, List, Tooltip, DatePicker, Spin, Checkbox, message, notification } from 'antd';
import './style.css';
import Moment from 'react-moment';
import { CONN } from '../../../../constant/types';
import dummyKnowledge from "./dummyKnowledge.json";
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import sourceViber from '../../../../assets/icons/contacts/viber.svg';
import sourcePhone from '../../../../assets/icons/contacts/call.svg';
import sourceMessenger from '../../../../assets/icons/contacts/mail.svg';
import sourceTwitter from '../../../../assets/icons/contacts/twitter.svg';
import dragIcon from '../../../../assets/icons/drag.svg';
import { KnowledgeComponent } from '../../../../components';

function getTime(time) {
  if (!isNaN(time)) {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  }
}

let dragingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

const columns = [
  {
    title: 'Баганы нэр',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '',
    dataIndex: 'key',
    key: 'key',
    render: key => <img src={dragIcon} alt="Drag Icon" />,
  },
];

const { Column } = Table;

const { TextArea } = Input;

function onChange(date, dateString) {
  console.log(date, dateString);
}

const CommentList = ({ comments }) => (
  <List
    className="modalComments" 
    id="modalScroll"
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? 'сэтгэгдэлүүд' : 'сэтгэгдэл'}`}
    itemLayout="horizontal"
    renderItem={props => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <div>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} onClick={onSubmit} />
      <Button block htmlType="submit" loading={submitting} onClick={onSubmit} type="primary" marginTop={12}>
        Сэтгэгдэл оруулах
      </Button>
    </Form.Item>
  </div>
);


const { Content } = Layout;
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilter = this.handleFilter.bind(this);
    this.state = {
      visible: false,
      loading: false,
      visibleAdd: false,
      activeTab: true,
      refreshing: false,
      modalOrder: [],
      checkedOrders: [],
      orderType: 'Шинэ',
      orders: [],
      total: null,
      showCombine: true,
      showFilter: false,
      showTable: false,
      selectedRowKeys: [],
      quantity: 1,
      show: true,      
      comments: [],
      submitting: false,
      loading: false,
      value: '',
      visibleSetting: false,
      top: 0,
      cardCheck: false,
      currentTime: null,
      duration: null,
      playAudio: false,
      pauseAudio: true,  
      filterModal: false,
      data: [
        {
          key: '1',
          name: 'Захиалагч',
        },
        {
          key: '2',
          name: 'Product'
        },
        {
          key: '3',
          name: 'Үнэ'
        },
        {
          key: '4',
          name: 'Бүсчлэл'
        },
        {
          key: '5',
          name: 'Төлөв'
        },
        {
          key: '6',
          name: 'Хариуцагч'
        },
        {
          key: '7',
          name: 'Огноо'
        },
        {
          key: '8',
          name: 'Хугацаа'
        },
        {
          key: '9',
          name: 'Төрөл'
        },
      ],
    };
    this.url = "https://callpro.mn/recordtwo.mp3";
    this.audio = new Audio(this.url);
  }

  componentDidMount() {
    this.fetchOrders();
    this.audio.addEventListener("timeupdate", e => {
      this.setState({
        currentTime: e.target.currentTime,
        duration: e.target.duration
      });
    });
  }

  componentWillUnmount() {
    this.audio.removeEventListener("timeupdate", () => {});
  }

  playAudio = () => {
    this.setState({ playAudio: true, pauseAudio: false })
    this.audio.play();
  }
  
  pauseAudio = () => {
    this.setState({ playAudio: false, pauseAudio: true })
    this.audio.pause();
  }

  fetchOrders = () => {
    const url = (`${CONN}/orders`);
    this.setState({ loading: false });
    fetch(url)
      .then(res => res.json())
      .then(res => {
        this.setState({
          orders: [...this.state.orders, ...res],
          total: res.total || null,
          error: res.error || null,
          loading: false,
          refreshing: false,
        });
      })
      .catch(error => {
        this.setState({ error, loading: false, refreshing: false });
      });
  };

  fetchKnowledge = () => {
    const url = (`${CONN}/knowledge`);
    this.setState({ loading: false });
    fetch(url)
      .then(res => res.json())
      .then(res => {
        this.setState({
          knowledgeList: [...this.state.knowledgeList, ...res],
          total: res.total || null,
          error: res.error || null,
          loading: false,
          refreshing: false,
        });
      })
      .catch(error => {
        this.setState({ error, loading: false, refreshing: false });
      });
  };  
  

  showCombine = () => {
    this.setState({
      showCombine: true,
      showTable: false,
    })
  }

  showTable = () => {
    this.setState({
      showCombine: false,
      showTable: true,
    })
  }

  showModal = (e) => {
    this.setState({
      visible: true,
      loading: true,
      knowledgeBtn: true
    },
    this.fetchModalOrder(e));
  };

  fetchModalOrder = (e) => {
    const url = (`${CONN}/orders/${e}`);
    this.setState({ loading: false });
    fetch(url)
      .then(res => res.json())
      .then(res => {
        this.setState({
          modalOrder: res,
          error: res.error || null,
          loading: false,
          refreshing: false,
        });
      })
      .catch(error => {
        this.setState({ error, loading: false, refreshing: false });
      });
  };

  showAddModal = () => {
    this.setState({
      visibleAdd: true,
    });
  };


  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
    );
  };


  handleFilter = () => {
    this.setState({
      showFilter: !this.state.showFilter,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
      knowledgeBtn: false
    });
  };

  handleCancelAddModal = () => {
    this.setState({
      visibleAdd: false
    })
  }

  handleCancelFilterModal = () => {
    this.setState({
      filterModal: false,
    });
  };

  handleAddModal = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };


  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };


  onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };  

  /* Modal Card Quantity  */

  IncrementItem = () => {
    this.setState(prevState => {
      if(prevState.quantity < 9) {
        return {
          quantity: prevState.quantity + 1
        }
      } else {
        return null;
      }
    });
}

  DecreaseItem = () => {
    this.setState(prevState => {
      if(prevState.quantity > 0) {
        return {
          quantity: prevState.quantity - 1
        }
      } else {
        return null;
      }
    });
  }

  ToggleClick = () => {
    this.setState({
      show: !this.state.show
    });
  }
  
  handleChange = (event) => {
    this.setState({quantity: event.target.value});
  }

  /* Take a comment */

  handleSubmit = () => {
    if (!this.state.value) {
      return;
    }

    this.setState({
      submitting: true,
    });

    setTimeout(() => {
      this.setState({
        submitting: false,
        value: '',
        comments: [
          {
            author: 'Г. Батбаатар',
            avatar: 'https://uinames.com/api/photos/female/7.jpg',
            content: <p>{this.state.value}</p>,
            datetime: '2019/10/01 12:33PM',
          },
          ...this.state.comments,
        ],
      });
    }, 1000);
  };

  handleChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  showColumnSetting = () => {
    this.setState({
      visibleSetting: true,
    })
  }

  handleCancelSetting = () => {
    this.setState({
      visibleSetting: false
    })
  }

  onChangeHover = (e) => {
    this.setState({
      cardCheck: e.target.checked
    })
    console.log(`checked = ${e.target.checked}`);
  }

  handleSendSettingsData = () => {
    message.success('Баганы тохиргоо амжилттай хадгалагдлаа');
    this.setState({
      visibleSetting: false
    })
  }
  
  filterModalOk = () => {
    message.success('Filter saved');
    this.setState({
      filterModal: false
    })
  }

  render() {    
    function onChangeDatePicker(date, dateString) {
      console.log(date, dateString);
    }

    const onChangeStatus = (
      <Menu>
        <Menu.Item>
          <Button block type="link" 
            onClick={() => this.setState({
              orderType: 'Хүлээж авсан'
            })}>
            Хүлээж авсан
          </Button>
        </Menu.Item>
        <Menu.Item>
        <Button block type="link" 
            onClick={() => this.setState({
              orderType: 'Бэлтгэж байна'
            })}>
            Бэлтгэж байна
          </Button>
        </Menu.Item>
        <Menu.Item>
        <Button block type="link" 
            onClick={() => this.setState({
              orderType: 'Хүргэлтэд'
            })}>
            Хүргэлтэд
          </Button>
        </Menu.Item>
      </Menu>
    );

    const menu = (
      <Menu>
        <Menu.Item>
            Edit
        </Menu.Item>
        <Menu.Item>
            Remove
        </Menu.Item>
        <Menu.Item>
            Whatever
        </Menu.Item>
      </Menu>
    );

    const orderDep = (
      <Menu>
        <Menu.Item>
          <span>
            Ticket Department
          </span>
        </Menu.Item>
        <Menu.Item>
        <span>
            Ticket Department 1
          </span>
        </Menu.Item>
        <Menu.Item>
        <span>
            Ticket Department 2
          </span>
        </Menu.Item>
      </Menu>
    );  

    const orderDepIs = (
      <Menu>
        <Menu.Item>
          <span>
            is
          </span>
        </Menu.Item>
        <Menu.Item>
        <span>
            is not
          </span>
        </Menu.Item>
      </Menu>
    );  

   this.saveFilterStatus = () => {
      this.setState({
        filterModal: true
      },
      message.success('Амжилттай хадгаллаа')
      )
    }

    this.onChangeCheck = (e) => {
      this.setState({
        checkedOrders: e
      })
      console.log('checked = ', e);
    }

    function onChange(e) {
      console.log(`checked = ${e.target.checked}`);
    }
    
    const orderDepMn = (
      <Menu>
        <Menu.Item>
          <span>
            Борлуулалтын хэлтэс
          </span>
        </Menu.Item>
        <Menu.Item>
          <span>
            Борлуулалтын хэлтэс 1
          </span>
        </Menu.Item>
      </Menu>
    );      
    const isNewRender = this.state.orders.filter(orders => orders.isNew)
    const { loading, selectedRowKeys, comments, submitting, value, orders, modalOrder } = this.state;
    const currentTime = getTime(this.state.currentTime);
    const duration = getTime(this.state.duration);
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const ColumnSettingRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`Баганы тохиргооны жагсаалт: ${selectedRowKeys}`, 'Сонгож авсан жагсаалт: ', selectedRows);
      },
      getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    };
    const hasSelected = selectedRowKeys.length > 0;
     return(
      <Layout>
        <Affix offsetTop={this.state.top}>
          <Row className="orderControl">
            <Col span={12}>
              <ul className="controlChanger">
                {
                  this.state.showCombine === true
                  ?
                  <li className="active" onClick={this.showCombine}>
                    <Icon type="appstore" style={{ fontSize: '16px', marginRight: '6px' }} /> Канбан
                  </li>
                  :
                  <li onClick={this.showCombine}>
                    <Icon type="appstore" style={{ fontSize: '16px', marginRight: '6px' }} /> Канбан
                  </li>
                }
                {
                  this.state.showTable === false
                  ?
                  <li onClick={this.showTable}>
                    <Icon type="menu" style={{ fontSize: '16px', marginRight: '6px' }} /> Жагсаалт
                  </li>
                  :
                  <li className="active" onClick={this.showTable}>
                    <Icon type="menu" style={{ fontSize: '16px', marginRight: '6px' }} /> Жагсаалт 
                  </li>
                }                           
              </ul>
            </Col>
            <Col span={12}>
              <ul className="controlRight">
                {
                  this.state.selectedRowKeys.length > 1
                  ?
                  <li>
                    <Button block className="orderCreate" onClick={this.showAddModal}>
                      <Icon type="plus" />
                      Төлөв өөрчлөх
                    </Button>
                  </li>
                  :
                  null
                }
                <li>
                  <Button block className="orderCreate" onClick={this.showAddModal}>
                    <Icon type="plus" />
                    Шинэ
                  </Button>
                </li>
                <li>
                  <Tooltip placement="bottom" title="Тохиргоо">
                    <span className="ctrlBtn" onClick={this.showColumnSetting}>
                    <Icon type="setting" />
                    </span>
                  </Tooltip>
                </li>               
                <li>
                  <Tooltip placement="bottom" title="Шинэчлэх">
                    <span className="ctrlBtn" onClick={this.start} disabled={!hasSelected} loading={loading}>
                    <Icon type="sync" />
                    </span>
                  </Tooltip>
                </li>               
                <li>
                  <Tooltip placement="bottom" title="Шалгах">
                    <span className="ctrlBtn">
                      <Icon type="check-circle" />
                    </span>
                  </Tooltip>
                </li>               
                <li>
                  <Tooltip placement="bottom" title="Шүүх">
                    <span className="ctrlBtn" onClick={this.handleFilter}>
                      <Icon type="filter" />
                    </span>
                  </Tooltip>
                </li>              
              </ul>
            </Col>
          </Row>
        </Affix>
        <Layout>
        <div>
          {
            this.state.showFilter === true
            ?
            <Layout className="filterWindow">
              <Row>
                <Col span={24} style={{ marginBottom: 8 }}>
                  <h3>Шүүж хайх</h3>
                </Col>
              </Row>
              <Row gutter={8} style={{ marginBottom: 16 }}>
                <Col span={6} className="gutter-row">
                  <div className="gutter-row">
                    <Dropdown overlay={orderDep} placement="bottomCenter">
                      <Button block style={{ width: '100%' }}>Ticket Department
                        <Icon type="down"></Icon>
                      </Button>
                    </Dropdown>
                  </div>
                </Col>
                <Col span={4} className="gutter-row">
                  <div className="gutter-row">
                    <Dropdown overlay={orderDepIs} placement="bottomCenter">
                      <Button block style={{ width: '100%' }}>Is not
                        <Icon type="down"></Icon>
                      </Button>
                    </Dropdown>
                  </div>                    
                </Col>
                <Col span={6} className="gutter-row">
                  <div className="gutter-row">
                    <Dropdown overlay={orderDepMn} placement="bottomCenter">
                      <Button block style={{ width: '100%' }}>Борлуулалтын хэлтэс
                        <Icon type="down"></Icon>
                      </Button>
                    </Dropdown>
                  </div>   
                </Col>
                <Col span={1} className="gutter-row">
                  <div className="gutter-row">
                    <Button block>
                      x
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={6} className="gutter-row">
                  <div className="gutter-row">
                    <Dropdown overlay={orderDep} placement="bottomCenter">
                      <Button block style={{ width: '100%' }}>Ticket Department
                        <Icon type="down"></Icon>
                      </Button>
                    </Dropdown>
                  </div>
                </Col>
                <Col span={4} className="gutter-row">
                  <div className="gutter-row">
                    <Dropdown overlay={orderDepIs} placement="bottomCenter">
                      <Button block style={{ width: '100%' }}>Is not
                        <Icon type="down"></Icon>
                      </Button>
                    </Dropdown>
                  </div>                    
                </Col>
                <Col span={6} className="gutter-row">
                  <div className="gutter-row">
                    <Dropdown overlay={orderDepMn} placement="bottomCenter">
                      <Button block style={{ width: '100%' }}>Борлуулалтын хэлтэс
                        <Icon type="down"></Icon>
                      </Button>
                    </Dropdown>
                  </div>   
                </Col>
                <Col span={1} className="gutter-row">
                  <div className="gutter-row">
                    <Button block>
                      x
                    </Button>
                  </div>
                </Col>
              </Row>  
              <Row gutter={8}>
                <Col span={24} className="gutter-row"  style={{ marginTop: 27 }}>
                  <div className="gutter-row">
                    <Button block className="filterBtnFill" style={{ marginRight: 12 }}>
                      <Icon type="search"></Icon>
                      Хайх
                    </Button>
                    <Button block style={{ marginRight: 12 }} className="filterBtn">
                      <Icon type="plus"></Icon>
                      Add condition
                    </Button>
                    <Button block style={{ marginRight: 12 }} className="filterBtn" onClick={this.saveFilterStatus}>
                      <Icon type="save"></Icon>
                      Шүүлтүүрийг хадгалах
                    </Button>
                  </div>   
                </Col>
              </Row>               
            </Layout>
            :
            null
          }
        </div>
      {
      this.state.showCombine === true
      ?
      <div className="orderCombine">
        <Layout>
          <div className="orderStatus">
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <div className="gutter-box">
                  <span class="orderTitle-new">
                    Шинэ 
                  </span>
                  <span className="orderCounter">30</span>
                  <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                    <Icon type="more" style={{ float: 'right', marginTop: 4 }}></Icon>
                  </Dropdown>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="gutter-box">
                  <span class="orderTitle-prepare">
                    Бэлтгэх
                  </span>
                  <span className="orderCounter">15</span>
                  <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                    <Icon type="more" style={{ float: 'right', marginTop: 4 }}></Icon>
                  </Dropdown>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="gutter-box">
                  <span class="orderTitle-ready">
                    Хүргэлт
                  </span>
                  <span className="orderCounter">13</span>
                  <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                    <Icon type="more" style={{ float: 'right', marginTop: 4 }}></Icon>
                  </Dropdown>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="gutter-box">
                  <span class="orderTitle-delivery">
                    Хүргэгдсэн
                  </span>
                  <span className="orderCounter">8</span>
                  <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                    <Icon type="more" style={{ float: 'right', marginTop: 4 }}></Icon>
                  </Dropdown>
                </div>
              </Col>
            </Row>       
          </div>
          <div className="orderList">
            <Row gutter={16}>
              <Col className="gutter-row newOrder orderScroll" span={6}>
                {orders.map(nList => (
                  nList.isNew === true
                    ?    
                    <div>
                      {
                        nList.totalQuantity < 6
                        ?
                    <div className="orderCard deadlineOrder" key={`newOrder-${nList.id}`}>
                      <div className="cardHeader">
                        <div className="sourceVendor">
                          {
                            nList.customer.map(newCust => (
                              <Row>
                                <Col span={3}>
                                  {
                                    newCust.isPhone === true 
                                    ?
                                    <img src={sourcePhone} alt="" />
                                    :
                                    <img src={sourceMessenger} alt="" />
                                  }                                                      
                                </Col>
                                <Col span={13} key={`NewList-Owner-${newCust.id}`} className="cardTopBody" onClick={(e) => this.showModal(nList.id)}>                            
                                  {
                                    newCust.phoneNumber.length > 10
                                    ?
                                    <div className="textLimit" style={{ width: 110 }}>
                                      {newCust.phoneNumber}
                                      </div>
                                    :
                                    <span className="operatorName">
                                      {newCust.phoneNumber}
                                    </span>
                                  }
                                </Col>
                                <Col span={8} style={{ paddingTop: 3 }}>
                                  <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                                    <span href="#" className="cardMore">
                                      <Icon type="more" />
                                    </span>
                                  </Dropdown>
                                  <Checkbox onChange={onChange} className="cardCheck" />
                                </Col>
                              </Row>
                            ))
                          }          
                        </div> 
                      </div>
                      <div className="cardDetail" onClick={(e) => this.showModal(nList.id)}>
                        <span className="cardDate"><Moment format="YYYY-MM-DD">{nList.date}</Moment></span>
                        <span className="cardId">{nList.id}</span> 
                        <Row>
                          <Col span={18}><span className="cardPrice">{nList.totalPrice} ₮</span></Col>
                          <Col span={6}><span className="cardQ">{nList.totalQuantity} ш</span></Col>
                        </Row>                                          
                      </div>
                      <Row className="cardFooter" onClick={(e) => this.showModal(nList.id)}>
                      {
                        nList.owner.map(newOwner => (
                        <Col span={16} key={`NewList-Owner-${newOwner.id}`} style={{ paddingLeft: 0, paddingRight: 0 }}>
                          <img src={`https://uinames.com/api/photos/male/4.jpg`} alt="" className="operatorImg" />
                          <span className="operatorName">{newOwner.name}</span>
                        </Col>
                        ))
                      }      
                        <Col span={8} style={{ textAlign: 'right'}}>
                          <span className="cardMoment" style={{  color: '#F5222D' }}>
                          <Icon type="clock-circle" style={{ marginRight: 4}} />
                            {nList.duration}
                          </span>
                        </Col>
                      </Row>
                    </div>
                    :
                    <div className="orderCard" key={`newOrder-${nList.id}`}>
                      <div className="cardHeader">
                        <div className="sourceVendor">
                          {
                            nList.customer.map(newCust => (
                              <Row>
                                <Col span={3}>
                                  {
                                    newCust.isPhone === true 
                                    ?
                                    <img src={sourcePhone} alt="" />
                                    :
                                    <img src={sourceMessenger} alt="" />
                                  }                                                      
                                </Col>
                                <Col span={13} key={`NewList-Owner-${newCust.id}`} className="cardTopBody" onClick={(e) => this.showModal(nList.id)}>                            
                                  {
                                    newCust.phoneNumber.length > 10
                                    ?
                                    <div className="textLimit" style={{ width: 110 }}>
                                      {newCust.phoneNumber}
                                      </div>
                                    :
                                    <span className="operatorName">
                                      {newCust.phoneNumber}
                                    </span>
                                  }
                                </Col>
                                <Col span={8} style={{ paddingTop: 3 }}>
                                  <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                                    <span href="#" className="cardMore">
                                      <Icon type="more" />
                                    </span>
                                  </Dropdown>
                                  <Checkbox onChange={onChange} className="cardCheck" />
                                </Col>
                              </Row>
                            ))
                          }          
                        </div> 
                      </div>
                      <div className="cardDetail" onClick={(e) => this.showModal(nList.id)}>
                        <span className="cardDate"><Moment format="YYYY-MM-DD">{nList.date}</Moment></span>
                        <span className="cardId">{nList.id}</span> 
                        <Row>
                          <Col span={18}><span className="cardPrice">{nList.totalPrice} ₮</span></Col>
                          <Col span={6}><span className="cardQ">{nList.totalQuantity} ш</span></Col>
                        </Row>                                          
                      </div>
                      <Row className="cardFooter" onClick={(e) => this.showModal(nList.id)}>
                      {
                        nList.owner.map(newOwner => (
                        <Col span={16} key={`NewList-Owner-${newOwner.id}`} style={{ paddingLeft: 0, paddingRight: 0 }}>
                          <img src={`https://uinames.com/api/photos/male/4.jpg`} alt="" className="operatorImg" />
                          <span className="operatorName">{newOwner.name}</span>
                        </Col>
                        ))
                      }      
                        <Col span={8} style={{ textAlign: 'right'}}>
                          <span className="cardMoment">
                          <Icon type="clock-circle" style={{ marginRight: 4}} />
                            {nList.duration}
                          </span>
                        </Col>
                      </Row>
                    </div>
                      }
                    </div>
                    :
                  null
                  ))}
                </Col>
                <Col className="gutter-row newOrder orderScroll" span={6}>
                {orders.map(pList => (
                  pList.isPrepare === true
                    ?    
                    <div className="orderCard" key={`processOrder-${pList.id}`}>
                      <div className="cardHeader">
                        <div className="sourceVendor">
                          {
                            pList.customer.map(pCust => (
                              <Row>
                                <Col span={3}>
                                  {
                                    pCust.isPhone === true 
                                    ?
                                    <img src={sourcePhone} alt="" />
                                    :
                                    <img src={sourceMessenger} alt="" />
                                  }                                                      
                                </Col>
                                <Col span={13} key={`NewList-Owner-${pCust.id}`} className="cardTopBody" onClick={(e) => this.showModal(pList.id)}>                            
                                  {
                                    pCust.phoneNumber.length > 10
                                    ?
                                    <div className="textLimit" style={{ width: 110 }}>
                                      {pCust.phoneNumber}
                                      </div>
                                    :
                                    <span className="operatorName">
                                      {pCust.phoneNumber}
                                    </span>
                                  }
                                </Col>
                                <Col span={8} style={{ paddingTop: 3 }}>
                                  <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                                    <span href="#" className="cardMore">
                                      <Icon type="more" />
                                    </span>
                                  </Dropdown>
                                  <Checkbox onChange={onChange} className="cardCheck" />
                                </Col>
                              </Row>
                            ))
                          }          
                        </div> 
                      </div>
                      <div className="cardDetail" onClick={(e) => this.showModal(pList.id)}>
                        <span className="cardDate"><Moment format="YYYY-MM-DD">{pList.date}</Moment></span>
                        <span className="cardId">{pList.id}</span> 
                        <Row>
                          <Col span={18}><span className="cardPrice">{pList.totalPrice} ₮</span></Col>
                          <Col span={6}><span className="cardQ">{pList.totalQuantity} ш</span></Col>
                        </Row>                                          
                      </div>
                      <Row className="cardFooter" onClick={(e) => this.showModal(pList.id)}>
                      {
                        pList.owner.map(newOwner => (
                        <Col span={16} key={`NewList-Owner-${newOwner.id}`} style={{ paddingLeft: 0, paddingRight: 0 }}>
                          <img src={`https://uinames.com/api/photos/male/4.jpg`} alt="" className="operatorImg" />
                          <span className="operatorName">{newOwner.name}</span>
                        </Col>
                        ))
                      }      
                        <Col span={8} style={{ textAlign: 'right'}}>
                          <span className="cardMoment">
                          <Icon type="clock-circle" style={{ marginRight: 4}} />
                            {pList.duration}
                          </span>
                        </Col>
                      </Row>
                    </div>
                    :
                  null
                  ))}
                </Col>                            
                <Col className="gutter-row newOrder orderScroll" span={6}>
                {orders.map(preList => (
                  preList.isReady === true
                    ?    
                    <div className="orderCard" key={`processOrder-${preList.id}`}>
                      <div className="cardHeader">
                        <div className="sourceVendor">
                          {
                            preList.customer.map(preCust => (
                            <Row>
                              <Col span={3}>
                                {
                                  preCust.isPhone === true 
                                  ?
                                  <img src={sourcePhone} alt="" />
                                  :
                                  <img src={sourceMessenger} alt="" />
                                }                                                      
                              </Col>
                              <Col span={13} key={`NewList-Owner-${preCust.id}`} className="cardTopBody" onClick={(e) => this.showModal(preList.id)}>                            
                                {
                                  preCust.phoneNumber.length > 10
                                  ?
                                  <div className="textLimit" style={{ width: 110 }}>
                                    {preCust.phoneNumber}
                                    </div>
                                  :
                                  <span className="operatorName">
                                    {preCust.phoneNumber}
                                  </span>
                                }
                              </Col>
                              <Col span={8} style={{ paddingTop: 3 }}>
                                <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                                  <span href="#" className="cardMore">
                                    <Icon type="more" />
                                  </span>
                                </Dropdown>
                                <Checkbox onChange={onChange} className="cardCheck" />
                              </Col>
                            </Row>
                            ))
                          }          
                        </div> 
                      </div>
                      <div className="cardDetail" onClick={(e) => this.showModal(preList.id)}>
                        <span className="cardDate"><Moment format="YYYY-MM-DD">{preList.date}</Moment></span>
                        <span className="cardId">{preList.id}</span> 
                        <Row>
                          <Col span={18}><span className="cardPrice">{preList.totalPrice} ₮</span></Col>
                          <Col span={6}><span className="cardQ">{preList.totalQuantity} ш</span></Col>
                        </Row>                                          
                      </div>
                      <Row className="cardFooter" onClick={(e) => this.showModal(preList.id)}>
                      {
                        preList.owner.map(newOwner => (
                        <Col span={16} key={`NewList-Owner-${newOwner.id}`} style={{ paddingLeft: 0, paddingRight: 0 }}>
                          <img src={`https://uinames.com/api/photos/male/4.jpg`} alt="" className="operatorImg" />
                          <span className="operatorName">{newOwner.name}</span>
                        </Col>
                        ))
                      }      
                        <Col span={8} style={{ textAlign: 'right'}}>
                          <span className="cardMoment">
                          <Icon type="clock-circle" style={{ marginRight: 4}} />
                            {preList.duration}
                          </span>
                        </Col>
                      </Row>
                    </div>
                    :
                  null
                  ))}
                </Col>
                <Col className="gutter-row newOrder orderScroll" span={6}>
                {orders.map(delList => (
                  delList.isDelivery === true
                    ?    
                    <div className="orderCard" key={`deliveryOrder-${delList.id}`}>
                      <div className="cardHeader">
                        <div className="sourceVendor">
                          {
                            delList.customer.map(delCust => (
                            <Row>
                              <Col span={3}>
                                {
                                  delCust.isPhone === true 
                                  ?
                                  <img src={sourcePhone} alt="" />
                                  :
                                  <img src={sourceMessenger} alt="" />
                                }                                                      
                              </Col>
                              <Col span={13} key={`NewList-Owner-${delCust.id}`} className="cardTopBody" onClick={(e) => this.showModal(delCust.id)}>                            
                                {
                                  delCust.phoneNumber.length > 10
                                  ?
                                  <div className="textLimit" style={{ width: 110 }}>
                                    {delCust.phoneNumber}
                                    </div>
                                  :
                                  <span className="operatorName">
                                    {delCust.phoneNumber}
                                  </span>
                                }
                              </Col>
                              <Col span={8} style={{ paddingTop: 3 }}>
                                <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                                  <span href="#" className="cardMore">
                                    <Icon type="more" />
                                  </span>
                                </Dropdown>
                                <Checkbox onChange={onChange} className="cardCheck" />
                              </Col>
                            </Row>
                            ))
                          }          
                        </div> 
                      </div>
                      <div className="cardDetail" onClick={(e) => this.showModal(delList.id)}>
                        <span className="cardDate"><Moment format="YYYY-MM-DD">{delList.date}</Moment></span>
                        <span className="cardId">{delList.id}</span> 
                        <Row>
                          <Col span={18}><span className="cardPrice">{delList.totalPrice} ₮</span></Col>
                          <Col span={6}><span className="cardQ">{delList.totalQuantity} ш</span></Col>
                        </Row>                                          
                      </div>
                      <Row className="cardFooter" onClick={(e) => this.showModal(delList.id)}>
                      {
                        delList.owner.map(delList => (
                        <Col span={16} key={`NewList-Owner-${delList.id}`} style={{ paddingLeft: 0, paddingRight: 0 }}>
                          <img src={`https://uinames.com/api/photos/male/2.jpg`} alt="" className="operatorImg" />
                          <span className="operatorName">{delList.name}</span>
                        </Col>
                        ))
                      }      
                        <Col span={8} style={{ textAlign: 'right'}}>
                          <span className="cardMoment">
                          <Icon type="clock-circle" style={{ marginRight: 4}} />
                            {delList.duration}
                          </span>
                        </Col>
                      </Row>
                    </div>
                    :
                  null
                  ))}
                </Col>
              </Row>     
            </div>             
          </Layout>
        </div>
        :
        <Content style={{ backgroundColor: '#fafafa', height: '100%' }}>
          <Table dataSource={orders} rowSelection={rowSelection} size="small" className="orderTable" pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50', '100']}}>
            <Column 
              title="З/Д"
              dataIndex="id"
              key="id"
              render={id => (
                <div onClick={(e) => this.showModal(id)} style={{ color: '#2F54EB', cursor: 'pointer' }}>
                  {id}
                </div> 
              )}
            />
            <Column
              title="Захиалагч"
              dataIndex="customer"
              key="customer"
              render={customer => (
                <div style={{ width: 120 }}>
                  {
                    
                    customer.map(cust => (
                      <span key={cust.id}>
                      <img src={sourceViber} alt=""/> {cust.phoneNumber}
                      </span>
                    ))

                    }
                </div>
              )}
            />
            <Column
              title="Product"
              dataIndex="items"
              key="items"
              render={items => (
                <div style={{ width: 180 }}>
                  {
                    items.map(item => (
                      <span key={item.id}>
                        {item.children}
                      </span>
                    ))
                  }
                </div>
              )}
            />
            <Column
              title="Үнэ"
              dataIndex="totalPrice"
              key="totalPrice"
              render={totalPrice => (
                <div style={{ width: 80 }}>
                  {totalPrice} ₮
                </div>
              )}
            />                 
            <Column
              title="Төлөв"
              dataIndex="isNew"
              key="isNew"
              render={isNew => (
                <div>
                  {
                    isNew === true
                    ?
                    <div className="orderBadge new">
                      Шинэ
                    </div>
                    :
                    null
                  }
                </div>)
              }
            />              
            <Column
              title="Хариуцагч"
              dataIndex="assign"
              key="assign"
              style={{ width: 80 }}
              render={assign => (
                <div style={{ width: 80 }}>
                  {
                    assign.map(assigner => (
                      <img src={`https://uinames.com/api/photos/male/${assigner.id}.jpg`} alt="" className="assignImg" />
                    ))
                  }
                </div>
              )}
            />     
            <Column
              title="Огноо"
              dataIndex="created_at"
              key="created_at"
              render={created_at => (
                <div>
                  <Moment format="YYYY-MM-DD">{created_at}</Moment>
                </div>)
              }
            />                    
            <Column
              title="Хугацаа"
              dataIndex="duration"
              key="duration"
              render={duration => (
                <div>
                  {duration}
                </div>)
              }
            />
          </Table>
        </Content>         
        }          
        </Layout>
        {/* Modal */}
        <Modal
          className="orderModal"
          width={884}
          loading={loading}
          height={961}
          title={[
          <Row>
            <Col span={15} style={{ marginLeft: 24 }}>
                <img src={sourcePhone} alt="" width={32} className="modalIcon" />
                <div style={{ width: '30%', float: 'left', marginLeft: 4 }}>
                  <span className="modalDate"><Moment format="YYYY-MM-DD">{modalOrder.date}</Moment></span>
                  <h3 className="modalId">#{modalOrder.id}</h3>
                </div> 
            </Col>
            <Col span={5}>
              <Dropdown overlay={onChangeStatus} placement="bottomCenter" trigger={['click']}>
                <Button block className="modalTopBtn">{this.state.orderType} <Icon type="down" style={{ marginLeft: 4 }} /></Button>
              </Dropdown>
            </Col>
            <Col span={2}>
              <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                <Icon type="more" style={{ marginLeft: 45, marginTop: 12 }} size={24} />
              </Dropdown>
            </Col>
          </Row>
          
          ]}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={['']}
        >
          {
            loading === true
            ?
            <Spin />
            :
            <Row>
            <Col span={16}>
              <div className="modalRow">
                <Icon type="clock-circle" style={{ float: 'left', marginTop: 5, fontSize: 18 }} />
                <Row> 
                  <Col span={8}>
                    <div className="modalDuration">
                      <span className="modalexpTag">Duration</span>
                      <div className="modalexpDate">{modalOrder.duration}</div>
                    </div>  
                  </Col>
                  <Col span={10}>
                    <div style={{ width: 1, backgroundColor: '#e8e8e8', float: 'left' }}></div>
                    <div className="modalDeadline">
                      <span className="modalDeadTag">Deadline</span>
                      <span className="modalDeadDate"><Moment format="YYYY-MM-DD">{modalOrder.deadline}</Moment></span>
                    </div> 
                  </Col>                    
                </Row> 
              </div>
              <div className="modalRow">
                <Icon type="user" style={{ float: 'left', marginTop: 4, fontSize: 18 }} />
                <Row>
                  <Col span={8}>
                    <div class="rowHeadline">
                      Хариуцагч:
                    </div>
                      {
                        modalOrder && modalOrder.owner 
                        ?
                        modalOrder.owner.map(mOwner => (
                        <div className="orderOwner">
                          <img src={`https://uinames.com/api/photos/male/5.jpg`} alt="" />
                          <span key={mOwner.id}>{mOwner.name}</span>
                        </div>
                        ))
                        :
                        null
                      }
                  </Col>
                  <Col span={8}>
                    <div class="rowHeadline">
                    Оролцогч:
                    </div>
                      {
                        modalOrder && modalOrder.assign 
                        ?
                        modalOrder.assign.map(mAssign => (
                        <div className="orderOwner">
                          <img src={`https://uinames.com/api/photos/male/6.jpg`} alt="" />
                          <span key={mAssign.id}>{mAssign.name}</span>
                        </div>
                        ))
                        :
                        null
                      }
                  </Col>                    
                  <Col span={6} style={{ paddingTop: 24 }}>
                    <div className="orderAssistAdd">
                      <div>
                        <Icon type="plus" style={{ fontSize: 14, marginRight: 3 }} />
                        <span>Нэмэх</span>
                      </div>
                    </div>  
                  </Col>                                         
                </Row>                             
              </div>                    
              <div className="modalRow">
                <Icon type="compass" style={{ float: 'left', marginTop: 4, fontSize: 18 }}  />
                <Row> 
                  <Col span={20}>
                    <div className="rowHeadline">
                      Хүргэлтийн хаяг:
                    </div>
                    <div className="orderAddress">
                      {
                        modalOrder && modalOrder.customerAddress 
                        ?
                        modalOrder.customerAddress.map(cAddress => (
                          <p>{cAddress.address}</p>
                        ))
                        :
                        null
                      }
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="modalRow">
                <Icon type="audio" style={{ float: 'left', marginTop: 4, fontSize: 18 }} />
                <Row> 
                  <Col span={20}>
                    <div className="rowHeadline">
                      Дуудлагын бичлэг
                    </div>
                    {
                      this.state.playAudio === false
                      ?
                      <div className="orderCallRec" onClick={this.playAudio}>
                        <Icon type="play-circle" className="orderCallPlay" /> 
                        <p>{currentTime}</p>
                      </div>  
                      :
                      <div className="orderCallRec" onClick={this.pauseAudio}>
                        <Icon type="pause-circle" style={{ color: '#52C41A', float: 'left', marginTop: 2 }} />
                        <p>{currentTime} / {duration}</p>
                      </div>
                    }
                  </Col>
                </Row>                  
              </div>                                  
              <Tabs defaultActiveKey="1" onChange={callback} className="orderTab">
                  <TabPane tab={`Бараа (${2})`} key="1">
                    <Row style={{ marginBottom: 12, marginLeft: 15, marginRight: 15 }}>
                      <Col span={14}></Col>
                      <Col span={10}>
                        <Button block className="modalPlusBtn">
                          <Icon type="plus"></Icon>
                          Бүтээгдэхүүн нэмэх
                        </Button>
                      </Col>
                    </Row>                   
                    {
                      modalOrder && modalOrder.items 
                      ?
                      modalOrder.items.map(modalItems => (
                        <Col span={24} className="modalOrdercard">
                            <div className="orderCardDesc">
                              <Col span={24} className="orderCardName">{modalItems.children}</Col>
                            </div>
                          <Col span={24}>
                            <Col span={6}>
                              <Col span={24} className="orderPriceOld">13,000 ₮</Col>
                              <Col span={24} className="orderPrice">{modalItems.price *this.state.quantity} ₮</Col>
                            </Col>
                            <Col span={16}>
                                <ul className="orderCalculate">
                                  <li><Button block shape="circle" onClick={this.DecreaseItem}>-</Button></li>
                                  <li><Input value={modalItems.quantity} onChange={this.handleChange} max={1} /></li>
                                  <li><Button block shape="circle" onClick={this.IncrementItem}>+</Button></li>
                                </ul>
                            </Col>
                          </Col>
                        </Col>
                      ))
                      :
                      null
                    }
                  </TabPane>
                  <TabPane tab="Сэтгэгдэл" key="2">
                    <div>
                      <Comment
                        avatar={
                          <Avatar
                            src="https://uinames.com/api/photos/female/7.jpg"
                            alt="Г. Батбаатар "
                          />
                        }
                        content={
                          <Editor
                            onChange={this.handleChange}
                            onSubmit={this.handleSubmit}
                            submitting={submitting}
                            value={value}
                          />
                        }
                      />
                      {comments.length > 0 && <CommentList comments={comments} />}
                    </div>
                  </TabPane>
                  <TabPane tab="Log" key="3">
                    {
                      modalOrder && modalOrder.log 
                      ?
                      modalOrder.log.map(modalLogs => (
                        <Row className="modalLogRow">
                          <Col span={5} className="LogrowDate"><Moment format="YYYY-MM-DD">{modalLogs.date}</Moment></Col>
                            {
                              modalLogs.owner.map(logOwner => (
                                <Col span={5} className="LogrowUser">
                                  {logOwner.name}
                                </Col>
                              ))
                            }
                          <Col span={14}>{modalLogs.description}</Col>
                        </Row>
                      ))
                      :
                      null
                    }                    
                  </TabPane>
              </Tabs>                   
            </Col>                        
            <Col span={8} class="orderSide">
              <div className="orderCust">
                  <img src="https://uinames.com/api/photos/male/7.jpg" alt="" className="orderCustImg" />
                  <h3 className="orderCustName">Шинэ хэрэглэгч</h3>
                  <span className="orderCustType">2-р түвшиний захиалагч</span> 
                  <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                    <Icon type="more" style={{ float: 'right', fontSize: 18, marginTop: -15 }} />                    
                  </Dropdown>
                  <div className="orderDivider" />
                  <ul className="orderContacts">
                    <li className="orderContactList">
                      <img className="phoneSVG" src={sourcePhone} alt="" />
                        
                      {
                        modalOrder && modalOrder.customer 
                        ?
                        modalOrder.customer.map(modalCust => (
                          <p>{modalCust.phoneNumber}</p>
                        ))
                        :
                        null
                      }  
                      <Button block className="orderCall">
                          Залгах
                      </Button>
                    </li> 
                    <li className="orderContactList">
                      <img className="phoneSVG" src={sourceViber} alt="" />
                      {
                        modalOrder && modalOrder.customer 
                        ?
                        modalOrder.customer.map(modalCust => (
                          <p>{modalCust.phoneNumber}</p>
                        ))
                        :
                        null
                      }  
                      <Button block className="orderCall">
                          Залгах
                      </Button>
                    </li>                       
                    <li>
                    <Button block className="orderAddContact" type="dashed" block>
                      Холболт нэмэх
                    </Button>
                    </li>  
                  </ul>
                  <div className="orderDivider" />
                  <div className="custHistory">
                    <h3>Өмнөх захиалгууд</h3>
                    {
                        modalOrder && modalOrder.history 
                        ?
                        modalOrder.history.map(mHistory => (
                        <div className="orderCard">
                          <div className="cardHeader">
                            {
                              mHistory.customer.map(histCust => (
                                <div className="sourceVendor modalVendor">
                                  <img src="https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Viber-20.png" alt=""/>
                                  {histCust.phoneNumber}
                                </div> 
                              ))
                            }
                          </div>
                          <div className="cardDetail" style={{ width: '100%', float: 'left' }}>
                            <Col span={24} className="cardDate"><Moment format="YYYY-MM-DD">{mHistory.date}</Moment></Col>
                            <Col span={24} className="cardId">{mHistory.id}</Col>
                            <Col span={24}>
                              <div className="orderCallRec" style={{ marginLeft: 0, marginTop: 8 }}>
                                <Icon type="play-circle" className="orderCallPlay" /> 
                                <p>02:15</p>
                              </div>
                            </Col> 
                            <Row>
                              <Col span={18}><span className="cardPrice">{mHistory.totalPrice} ₮</span></Col>
                              <Col span={6}><span className="cardQ">{mHistory.totalQuantity} ш</span></Col>
                            </Row>   
                          </div>
                          <Row className="cardFooter">
                              {
                                mHistory.owner.map(histOwner => (
                                  <Col span={16}>
                                    <img src={`https://uinames.com/api/photos/male/6.jpg`} alt={histOwner.name} className="operatorImg"></img>
                                    <span className="operatorName">{histOwner.name}</span>
                                  </Col>                                        
                                ))
                              }
                              <Col span={8} style={{ textAlign: 'right' }}>
                                <span className="cardMoment">
                                <Icon type="clock-circle" />
                                  {mHistory.duration}
                                </span>
                              </Col>
                            </Row>
                        </div>
                        ))
                        :
                        null
                      }
                  </div> 
              </div> 
            </Col>
          </Row>              
            }
        </Modal>
        <Modal
          className="createOrderModal orderModal"
          width={884}
          loading={loading}
          height={530}
          title={[
          <Row>
            <Col span={15} style={{ marginLeft: 24 }}>
                <img src="https://uinames.com/api/photos/male/5.jpg" alt="" width={32} className="modalIcon" />
                <div style={{ width: '30%', float: 'left', marginLeft: 4 }}>
                  <span className="modalDate"><Moment format="YYYY-MM-DD">2019-10-29</Moment></span>
                  <h3 className="modalId">#12345678</h3>
                </div> 
            </Col>
            <Col span={5}>
              <Dropdown overlay={onChangeStatus} placement="bottomCenter" trigger={['click']}>
                <Button block className="modalTopBtn">{this.state.orderType} <Icon type="down" style={{ marginLeft: 4 }} /></Button>
              </Dropdown>
            </Col>
            <Col span={2}>
              <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                <Icon type="more" style={{ marginLeft: 45, marginTop: 12 }} size={24} />
              </Dropdown>
            </Col>
          </Row>
          
          ]}
          visible={this.state.visibleAdd}
          onOk={this.handleOk}
          onCancel={this.handleCancelAddModal}
          footer={['']}
        >
          {
            loading === true
            ?
            <Spin />
            :
            <Row>
            <Col span={16}>
              <div className="modalRow">
                <Icon type="clock-circle" style={{ float: 'left', marginTop: 5, fontSize: 18 }} />
                <Row> 
                  <Col span={8}>
                    <div className="modalDuration">
                      <span className="modalexpTag">Duration</span>
                      <div className="modalexpDate">00:00</div>
                    </div>  
                  </Col>
                  <Col span={10}>
                    <div style={{ width: 1, backgroundColor: '#e8e8e8', float: 'left' }}></div>
                    <div className="modalDeadline">
                      <span className="modalDeadTag">Deadline</span>
                      <span className="modalDeadDate"><DatePicker onChange={onChangeDatePicker} /></span>
                    </div> 
                  </Col>                    
                </Row> 
              </div>
              <div className="modalRow">
                <Icon type="user" style={{ float: 'left', marginTop: 4, fontSize: 18 }} />
                <Row>
                  <Col span={8}>
                    <div class="rowHeadline">
                      Хариуцагч:
                    </div>
                    <div className="orderOwner">
                      <img src={`https://uinames.com/api/photos/male/5.jpg`} alt="" />
                      <span>Б. Заяа</span>
                    </div>
                  </Col>               
                  <Col span={6} style={{ paddingTop: 24 }}>
                    <div className="orderAssistAdd">
                      <div>
                        <Icon type="plus" style={{ fontSize: 14, marginRight: 3 }} />
                        <span>Нэмэх</span>
                      </div>
                    </div>  
                  </Col>                                         
                </Row>                             
              </div>                    
              <div className="modalRow">
                <Icon type="compass" style={{ float: 'left', marginTop: 4, fontSize: 18 }}  />
                <Row> 
                  <Col span={20}>
                    <div className="rowHeadline">
                      Хүргэлтийн хаяг:
                    </div>
                      <Input placeholder="Хаягаа оруулна" style={{ marginLeft: 26 }} />
                  </Col>
                </Row>
              </div>                                  
              <Tabs defaultActiveKey="1" onChange={callback} className="orderTab">
                  <TabPane tab={`Бараа (${2})`} key="1">
                    <Row style={{ marginBottom: 12, marginLeft: 15, marginRight: 15 }}>
                      <Col span={14}></Col>
                      <Col span={10}>
                        <Button block className="modalPlusBtn">
                          <Icon type="plus"></Icon>
                          Бүтээгдэхүүн нэмэх
                        </Button>
                      </Col>
                    </Row>                   
                  </TabPane>
                  <TabPane tab="Сэтгэгдэл" key="2">
                    <div>
                      <Comment
                        avatar={
                          <Avatar
                            src="https://uinames.com/api/photos/female/7.jpg"
                            alt="Г. Батбаатар "
                          />
                        }
                        content={
                          <Editor
                            onChange={this.handleChange}
                            onSubmit={this.handleSubmit}
                            submitting={submitting}
                            value={value}
                          />
                        }
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="Log" key="3">
                    <Row className="modalLogRow">
                      <Col span={5} className="LogrowDate"><Moment format="YYYY-MM-DD">2019/10/29</Moment></Col>
                            <Col span={5} className="LogrowUser">
                              Б. Заяа
                            </Col>
                      <Col span={14}>#123546 Modal үүсгэв</Col>
                    </Row>                
                  </TabPane>
              </Tabs>                   
            </Col>                        
            <Col span={8} class="orderSide">
              <div className="orderCust">
                <Row>
                  <Col>
                    <h3>Захиалагч нэмэх</h3>
                    <Input placeholder="Search Customer" />
                    <span>Хэрэглэгч олдсонгүй, шинээр үүсгэнэ үү</span>
                  </Col> 
                  <Col span={24} style={{ marginTop: 16 }}>
                    <Button block type="link" block>
                      <Icon name="plus"  /> Шинээр үүсгэх
                    </Button>
                  </Col>      
                </Row>                   
              </div>
            </Col>
          </Row>              
            }
        </Modal>                  
        <Modal
          visible={this.state.visibleSetting}
          onCancel={this.handleCancelSetting}
          title="Баганы тоо"
          className="settingsModal"
          footer={[
            <Button block key="back" onClick={this.handleCancelSetting}>
              Цуцлах
            </Button>,
            <Button block key="submit" type="primary" loading={loading} onClick={this.handleSendSettingsData}>
              Хадгалах
            </Button>,
          ]}
          >
            <DndProvider backend={HTML5Backend}>
              <Table
                size="small"
                pagination={false}
                columns={columns}
                rowSelection={ColumnSettingRowSelection}
                dataSource={this.state.data}
                components={this.components}
                onRow={(record, index) => ({
                  index,
                  moveRow: this.moveRow,
                })}
              />
            </DndProvider>
          </Modal>                     
        <Modal
          visible={this.state.filterModal}
          onOk={this.filterModalOk}
          onCancel={this.handleCancelFilterModal}
        >
          <p>Шүүлтүүр хадгалах </p>
          <Input placeholder="Нэр" />
        </Modal>          
      </Layout>
    )
  }

}

export default OrderList;
