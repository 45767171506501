import React from 'react';
import './style.css';
import { Icon, Row, Col, Button, Input, Tooltip, message } from 'antd';
import sourcePhone from '../../assets/icons/contacts/call.svg';
import missed from '../../assets/icons/phone-missed.svg';

function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(value);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      onChange(value.slice(0, -1));
    }
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const { value } = this.props;
    const title = value ? (
      <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    ) : (
      'Дугаар оруулна уу'
    );
    return (
      <Tooltip
        trigger={['focus']}
        title={title}
        placement="topLeft"
        overlayClassName="numeric-input"
      >
        <Input
          {...this.props}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder="Шилжүүлэх дугаар"
          maxLength={25}
        />
      </Tooltip>
    );
  }
}

class IncomingCall extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      incomingCall: false,
      acceptCall: false,
      transferCall: false,
      value: ''
    };
  }

  componentDidMount() {
    // Дуудлага ирэх үед доорх comment - г авч хаяаарай
    //this.incomingCall();
  }

  incomingCall = () => {
    setTimeout(() => {
      this.setState({ 
        incomingCall: true
       })
    }, 8000)
  }

  onChange = value => {
    this.setState({ value });
  };

  declineCall = () => {
    this.setState({
      incomingCall: false
    })
  }

  acceptCall = () => {
    this.setState({
      acceptCall: true,
      incomingCall: false, 
    })
  }

  endCall = () => {
    this.setState({
      acceptCall: false,
    })
  }

  transferCall = () => {
    this.setState({
      incomingCall: false,
      acceptCall: false,
      transferCall: true
    })
  }

  onPressTransfer = () => {
    message
    .loading('Уншиж байна..', 2.5)
    .then(() => message.success(`Амжилттай ${this.state.value} дугаарлуу шилжүүллээ`, 2.5))
    this.setState({
      incomingCall: false,
      incomingCall: false,
      transferCall: false,
    })
  }

  render(){
    return(
      <div>
        {
        this.state.incomingCall === true
        ?
        <div className="inComingWrap posIncoming">
          <div className="topCallArrow"></div> 
          <Row>
            <Col span={4}>
              <img src={sourcePhone} alt="" className="callIcon" />
            </Col>
            <Col span={20}>
              <p>Шинэ хэрэглэгч</p>
              <span className="number">99123456</span>
            </Col>
          </Row>
          <ul className="btnTypes mt-20">
            <li className="mr-6">
              <Button block onClick={this.transferCall}>
                Шилжүүлэх
              </Button>
            </li>
            <li className="mr-6">
              <Button block className="acceptBtn" onClick={this.acceptCall}>
                Хариулах
              </Button>
            </li>
            <li>
              <Button block className="declineBtn" onClick={this.declineCall} block>
                <img src={missed} alt=""/>
              </Button>
            </li>
          </ul>
        </div> 
        :
        null
        }
        {
        this.state.acceptCall === true
        ?
        <div className="acceptWrap posIncoming">
          <div className="topCallArrow"></div> 
          <Row>
            <Col span={4}>
              <img src={sourcePhone} alt="" className="callIcon" />
            </Col>
            <Col span={20}>
              <p>Шинэ хэрэглэгч</p>
              <span className="number">99123456</span>
              <div className="ontime">00:01</div>
            </Col>
          </Row>
          <ul className="btnTypes mt-20">
            <li className="left">
              <Button block onClick={this.transferCall}>
                Шилжүүлэх
              </Button>
            </li>
            <li className="right">
              <Button block className="cancelBtn" onClick={this.endCall}>
                Таслах
              </Button>
            </li>
          </ul>
          <div className="otherTypes mt-20">
            <Button block type="dashed" block>
              <Icon type="plus" /> Захиалга үүсгэх
            </Button>
            <Button block type="dashed" style={{marginTop: 12}} block>
              <Icon type="plus" /> Тикет үүсгэх
            </Button>
          </div>
          <div className="helperBtn">
            <Button block>
              Мэдлэгийн сан
            </Button>
          </div>
        </div> 
        :
        null
        }
        {
          this.state.transferCall === true
          ?
          <div className="inComingWrap posIncoming">
            <div className="topCallArrow"></div> 
            <Row>
              <Col span={4}>
                <img src={sourcePhone} alt="" className="callIcon" />
              </Col>
              <Col span={20}>
                <p>Шинэ хэрэглэгч</p>
                <span className="number">99123456</span>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginTop: 20 }}>
              <NumericInput 
                  value={this.state.value} 
                  onChange={this.onChange}
                  suffix={
                    <Tooltip title="Шилжүүлэх">
                      <Icon type="user" style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginTop: 20 }}>
                <Button block onClick={this.onPressTransfer} block>
                   Шилжүүлэх
                </Button>
              </Col>
            </Row>            
          </div> 
          :
          null
        }        
      </div>
      )
    }  
};

export default IncomingCall;
