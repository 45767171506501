import React from 'react';
import { Layout } from 'antd';
const { Content } = Layout;


class Example extends React.Component {
  constructor(props){
    super(props);
    this.goBack = this.goBack.bind(this); 
 }

  goBack(){
    this.props.history.goBack();
  }


  render() {
    return(
      <Layout style={{ marginTop: '48px' }}>
        <p>
          Example page
        </p>
      </Layout>
    )
  }

}

export default Example;
