import React from 'react';
import './style.css';
import { Icon, Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import { KnowledgeComponent } from '../';

class MasterMenuComponent extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      knowComponent: false,
    }
  }

  render(){
  
    const masterMenu = [{
    key: 1,
    title: "Захиалга",
    to: "/admin/order/list",
    starts: "/admin/order",
    icon: "shopping-cart",
    activeStatus: "active"
  },
  {
    key: 2,
    title: "Тикет",
    to: "/admin/ticket/list",
    starts: "/admin/ticket",
    icon: "snippets",
    activeStatus: "active-ticket"
  }]

  const otherMenus = [{
    key: `otherMenu-3`,
    title: "Тайлан",
    to: "/admin/dashboard/ticket",
    starts: "/admin/dashboard",
    icon: "pie-chart",
    activeStatus: "active-middle"
  },{
    key: `otherMenu-4`,
    title: "Хэрэглэгчид",
    to: "/admin/user",
    starts: "/admin/user",
    icon: "user",
    activeStatus: "active-mid"
  },{
    key: `otherMenu-1`,
    title: "Message",
    to: "/admin/chatbot",
    starts: "/admin/chatbot",
    icon: "message",
    activeStatus: "active"
  }]

  const bottomMenus = [
  /*{
      key: `bottomMenus-1`,
      title: "Тохиргоо",
      to: "/admin/settings",
      starts: "/admin/settings",
      icon: "setting",
      activeStatus: "active"
  },{
      key: `bottomMenus-2`,
      title: "Үйлчилгээний нөхцөл",
      to: "/admin/terms",
      starts: "/admin/terms",
      icon: "snippets",
      activeStatus: "active"
  },{
      key: `bottomMenus-3`,
      title: "Тусламж",
      to: "/admin/help",
      starts: "/admin/help",
      icon: "question-circle",
      activeStatus: "active"
  }*/
  ]

    return(
      <div className="masterMenu">
        <ul className="mmenus">
        {
            masterMenu.map(menuList => (
            <Tooltip placement="right" title={menuList.title} key={`masterMenu-${menuList.key}`} react-id={`masterMenu-${menuList.key}`}>
                <NavLink activeClassName={`${menuList.activeStatus}`} to={menuList.to}
                    isActive={(match, location) => {
                    console.log(match, location);
                    return location.pathname.startsWith(menuList.starts);
                    }}
                >
                    <li className="mmlist">
                        <Icon type={menuList.icon} />
                    </li>    
                </NavLink>
            </Tooltip>
            ))
        }
            <div className="mmlist-border"></div>                    
            {
            otherMenus.map(otherMenu => (
            <Tooltip placement="right" title={otherMenu.title} key={`masterMenu-${otherMenu.key}`} react-id={`otherMenu-${otherMenu.key}`}>
                <NavLink activeClassName={`${otherMenu.activeStatus}`} to={otherMenu.to}
                    isActive={(match, location) => {
                    console.log(match, location);
                    return location.pathname.startsWith(otherMenu.starts);
                    }}
                >
                    <li className="mmlist">
                        <Icon type={otherMenu.icon} />
                    </li>    
                </NavLink>
            </Tooltip>
            ))
          }                    
        </ul>
        <ul className="mmenus-bottom">
          {
          bottomMenus.map(bottomMenu => (
          <Tooltip placement="right" title={bottomMenu.title} key={`masterMenu-${bottomMenu.key}`} react-id={`bottomMenu-${bottomMenu.key}`}>
            <NavLink activeClassName={`${bottomMenu.activeStatus}`} to={bottomMenu.to}
                isActive={(match, location) => {
                console.log(match, location);
                return location.pathname.startsWith(bottomMenu.starts);
                }}
            >
              <li className="mmlist">
                  <Icon type={bottomMenu.icon} />
              </li>    
            </NavLink>
          </Tooltip>
            ))
          } 
        </ul>
      </div>
      )
    }
};

export default MasterMenuComponent;
