import React from 'react';
import { render } from 'react-dom';
import { Switch, Route , HashRouter as Router } from 'react-router-dom';
import './index.css';
import App from './containers/Admin/App';
import { Notfound } from './containers/Admin';
import Home from './containers/Public/Home';
import About from './containers/Public/About';
import Contact from './containers/Public/Contact';
import 'antd/dist/antd.css';

render(
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/admin" component={App} />
        <Route path="*" component={Notfound} />
      </Switch>
    </Router>
  , document.getElementById('root'),
);

