/* eslint-disable no-unused-expressions */
import React from 'react';
import { Layout, Table, Affix, Tag, Tabs, Icon, Row, Col, Upload, Collapse, Button, Input, Modal, Divider, message, Menu, Dropdown, Popover, Select, Tooltip, Timeline,DatePicker, Typography } from 'antd';
import './style.css';
import { EditorState, convertFromRaw } from 'draft-js';
import { CONN } from '../../../../constant/types';
import { Editor } from 'react-draft-wysiwyg'; 
import sourceViber from '../../../../assets/icons/contacts/viber.svg';
import sourcePhone from '../../../../assets/icons/contacts/call.svg';
import sourceMessenger from '../../../../assets/icons/contacts/mail.svg';
import sourceTwitter from '../../../../assets/icons/contacts/twitter.svg';
import dragIcon from '../../../../assets/icons/drag.svg';
import axios from 'axios';
import Moment from 'react-moment';
import update from 'react-addons-update';
import 'antd/es/date-picker/style/css';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { userInfo } from 'os';
import {TicketShow} from '../../Ticket';
import { TreeSelect } from 'antd';
import { Link } from 'react-router-dom';
import { read } from 'fs';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';

const { SHOW_PARENT } = TreeSelect;
const {Paragraph} = Typography;

const { Panel } = Collapse;

var callprorecord = "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3";
//var update = require('immutability-helper');
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Column } = Table;
const { RangePicker } = DatePicker;
const { Content } =  Layout;


//This function is used for CallRecord
function getTime(time) {
  if (!isNaN(time)) {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  }
}

const content = {"entityMap":{},"blocks":[{"key":"637gr","text":"Initialized from content state.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]};

class TicketList extends React.Component {
  constructor(props) {
    super(props);
    const contentState = convertFromRaw(content);
    this.state = {
      contentState,
      postTicket: [],
      postTicketDraft: [],
      notesArea: true,
      commentArea: false,
      showCallWrap: false,
      addNumber: false,
      addAddress: false,
      addowner: false,
      assAssign: false,
      currentTime: null,
      duration: null,
      durations: null,
      playAudio: false,
      pauseAudio: true,
      tickets: '',
      users: [],
      selectedUser: '',
      selectedOwner: '',
      validationError: '',
      owner_id: '',
      owner_name: '',   
      owner: [],
      title: '',
      address: '',
      addressNew: '',
      created_at: '',
      assignee: [],
      deadline: '',
      mobile: '',
      viber: '',
      messenger: '',
      linked: '',
      customer:'',
      topic_id:'',
      firstname:'',
      lastname:'',
      priority_id: '',
      priority_name: '',
      code:'',
      customer_id: '',
      selectedOption: '',
      statuses: [],
      topics: [],
      priorities: [],
      topic_name: '',
      status_name: '',
      status_id: '',
      urls: `${CONN}/tickets/${localStorage.getItem("code")}`,
      assign_name: '',
      selected_owner: '',
      text: '',
      text_comment: '',
      ticket_id: '',
      level: '',
      send_source: '',
      source_name: '',
      source_id: '',
      created_user_id:'',
      audio: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
      sources: [],
      message: '',
      statuscolor: 'sideDropdown',
      selectedFile: null,
      uploading: false,
      token: localStorage.getItem('token'),
      value: '',
      ticketsTemp: [],
      hits:'',
      log:[],
      owner_opt: [],
      assign_opt: [],
      assigneer: [],
      quick_response:[],
      quick_response_id: '',
      quick_response_text:'',
      departments: [],
      department_name: '',
      department_id:'',
      file: '',
      isbefore: false,
      notefrom: 'тодорхойгүй',
      noteto: 'тодорхойгүй'
    }
    this.audio = new Audio(callprorecord);
    this.bars ='1'
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
  }
//function that will be called when page is started
  componentDidMount() {
    console.log(this.bars)
  axios({
    method: 'put',
    headers: {    'Content-Type': 'application/json'  },
    url: `${CONN}/tickets/${localStorage.getItem("code")}`,
    data: {
      isNew: 0
    },
    headers: {
      'Authorization': `Bearer  ${this.state.token}`
    }
  }).then(res => {
    console.log(res)
  })
  .catch(err => {
    console.log(err)
  })

    this.fetchTickets();
  }
//function that fetchs all required data from API
  fetchTickets = () => {
    //neccessary variables
    var timeStamp = Math.floor((Date.now())  / 1000);                   // for get current date
    const url = (`${CONN}/tickets/${localStorage.getItem("code")}`);  // main url for chosen ticket API
    const url_users = `${CONN}/users`;                                  // url for user API
    const url_statuses = `${CONN}/ticket_statuses`;                     // url for status list API
    const url_topics = `${CONN}/ticket_topics`;                         // url for topic list API
    const url_priorities = `${CONN}/ticket_priorities`;                 // url for prioities list API
    this.setState({ loading: false });
    //const that declares header of http requests bears TOKEN
    const config = {
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    };
    //fetch main data for this ticket
      axios
      .get(url, config)
      .then((res)=>{
        const tick = res.data.data;
        console.log('Ticket',res.data.data)
        //set states for nessessaty data
        this.setState({
          tickets: tick,                                                  // stores all data ticket
          durations: tick.created_at,                                        // for duration from ticket created
          status_id:  tick.status !== null ? tick.status.id : '-', 
          status_name: tick.status != null ? tick.status.name : '-',       // get status of ticket
          topic_name: tick.topic !== null ? tick.topic.name: '-',           // get topic if ticket
          priority_name: tick.priority !== null ? tick.priority.name: '-',  // get priority of ticket
          deadline: tick.deadline,                                          // get deadline of ticket
          owner_name: tick.owner !== null ? tick.owner.name : '-',           // get ownername of ticket
          ticket_id: tick.id,                                               // get id of ticket
          level: tick.reply.length,                                         // get legth of reply array of ticket
          created_at: timeStamp,     
          send_source: tick.customer != null ? tick.customer.source_id:"-",                                      // get created at of ticket
          assignee: tick.assign,                                            // get array of assignee of ticket
          audio: tick.recordUrl,                                            // get callrecord url of ticket
          date: tick.deadline,                                              // get deadline of ticket
          log: res.data.data.log,                                           // get log array of ticket
          address: tick.customer != null ? tick.customer.address : '-',                              // get address of customer of ticket
          mobile: tick.customer != null ? tick.customer.mobile : '-',                      // get phone number of customer for ticket
          viber: tick.customer != null ? tick.customer.viber : '-',                          // get customer's viber of ticket
          email: tick.customer != null ? tick.customer.email : '-',                                 // get email of customer of ticket
          customer_id: tick.customer !== null ? tick.customer.id: '-', // get id of customer of ticket
          selectedUser: [],                                                  // clears selecter user array
          department_id:  tick.department !== null ? tick.department.id: '-',
          department_name: tick.department !== null ? tick.department.name: '-',
          file: ""
        });
        console.log("AUDIO", tick.recordUrl)
        this.audio = new Audio(tick.recordUrl);
        //callprorecord= tick.recordUrl;
        this.bars = "2"
        console.log(this.bars)

        this.audio.addEventListener("timeupdate", e => {
          this.setState({
            currentTime: e.target.currentTime,
            duration: e.target.duration
          });
        });

        //localStorage.setItem('customer', tick.customer.id)
        if(tick.customer.source_id == 2){
          this.setState({
            noteto: tick.customer != null ? tick.customer.mobile : '',                         // get customer phone of ticket for reply
            notefrom: 'info@callpro.mn'
          })
          this.setState({notesArea: false, commentArea: true});
                  setTimeout(() => {
                    this.setState({notesArea: true, commentArea: false});
                  }, 50);
        }
        if(tick.customer.source_id == 8){
          this.setState({
            email: tick.customer != null ? tick.customer.email : '-',                                 // get email of customer of ticket
            notefrom: '137070'
          })
          this.setState({notesArea: false, commentArea: true});
                  setTimeout(() => {
                    this.setState({notesArea: true, commentArea: false});
                  }, 50);
        }
        var before = moment(tick.created_at).isBefore('2020-03-30'); // true
        this.setState({isbefore: before})
      // get net hits by getting all tickets from this costomer
      axios
      .get(`${CONN}/ticket_customer/${res.data.data.customer.id}?page=1`, config)
      .then((response) => {
        console.log(response)
        this.setState({ hits: response.data.meta.total });
      })

      
      tick.assign.map(assign=>{
        this.add(assign.user_id)
      })
      if(tick.owner != null){
      this.add(tick.owner.id)}
      

      /*// get all users' list
      axios
      .get(url_users, config)
      .then(response => {
        console.log(response)
        this.setState({users: response.data.data})
        // add to assigned users for filtering later
      })*/
      })

      

      this.setState({statuses: JSON.parse( localStorage.getItem('statuses'))});
      this.setState({topics: JSON.parse( localStorage.getItem('topics'))});
      this.setState({priorities: JSON.parse( localStorage.getItem('priorities'))});
      this.setState({sources: JSON.parse( localStorage.getItem('sources'))});
      this.setState({departments: JSON.parse( localStorage.getItem('departments'))});
      this.setState({users: JSON.parse( localStorage.getItem('users'))});

    axios
    .get(`${CONN}/quick_response?page=1`, config)
    .then(response =>{
      console.log('hehe', response)
      this.setState({quick_response: response.data.data})
      if(response.data.meta.last_page > 1){
        for(var i=2; i<=response.data.meta.last_page; i++){
          axios
          .get(`${CONN}/quick_response?page=${i}`, config)
          .then(res =>{
            console.log('haha', response)
            this.setState({quick_response: [...this.state.quick_response, ...res.data.data]})
          })
        }
      }
    })
    
  }

  //for playing callrecord
  componentWillUnmount() {
    //this.audio.removeEventListener("timeupdate", () => {});
    this.fetchTickets();
  }

  // play call record
  playAudio = () => {
    this.setState({ playAudio: true, pauseAudio: false })
    this.audio.play();
  }

  // pause call record
  pauseAudio = () => {
    this.setState({ playAudio: false, pauseAudio: true })
    this.audio.pause();
  }

  // for note or comment switch buttons FOR NOTES
  showNotes = () => {
    this.setState({
      notesArea: true,
      commentArea: false,
    })
  }

  // change states from item names
  changeHandler = e => {
		this.setState({ [e.target.name]: e.target.value })
  }

  //quill handle
  quillHandler = e =>{
    console.log(e)
    this.setState({text: e})
  }
  
  // for note or comment switch buttons FOR COMMENTS
  showComment = () => {
    this.setState({
      commentArea: true,
      notesArea: false,
    })
  }

  // function that add users to selectedUsers state
  add = (value) =>{
    this.state.users.map(users =>{
      if(users.id == value){
        this.setState({selectedUser: [...this.state.selectedUser, users]})
      }
    })
    console.log(this.state.selectedUser)
  }

  //to send comments
  postTicket = () => {
    console.log("WhAAT",this.state)
    axios({
      method: 'post',
      url: `${CONN}/ticket_comment/${this.state.send_source}`,
      data: {
        "ticket_id": this.state.ticket_id,
        "message": this.state.text,
        "level": this.state.level,
        "is_note": this.state.commentArea,
        "is_reply": this.state.notesArea,
        "quick_response_id": this.state.quick_response_id
      },
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    })
    .then(response=> {
      console.log(response)
      message.success('Амжилттай илгээгдлээ')
      this.setState({text: ''})
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
          'Accept': 'application/json',
          'Authorization': `Bearer  ${this.state.token}`,
          'Accept-Encoding': 'gzip, deflate, br'
        }
      };
      if(this.state.file != "" || this.state.file != null){
      const data = new FormData();
      console.log("file", this.state.file , response.data.data.id)
      data.append('file', this.state.file);
      data.append('comment_id', response.data.data.id) 

      axios
      .post(`https://crm.onlime.mn/backend/api/v1/ticket_comment_attach`, data, config)
      .then(response=> {
        message.success('Хавсралт амжилттай илгээгдлээ')
        this.fetchTickets();
      })
      .catch(error => {
        console.log(error)
        message.error('Хавсралт илгээхэд алдаа гарлаа')
        this.fetchTickets();
      })}
        })
    .catch(error => {
      console.log(error)
      message.error('Коммент илгээхэд алдаа гарлаа')
    })
  }

  // to save as draft but on change it actually saved as draft
  postTicketDraft = () => {
    message.success('Амжилттай Draft болгож хадгаллаа')
  }
  
  // to change deadline
  onChange = (value, dateString) =>  {
    console.log('Formatted Selected Time: ', dateString);
    // var timeStamp = Math.floor(dateString / 1000);
    axios({
      method: 'put',
      headers: {    'Content-Type': 'application/json'  },
      url: `${CONN}/tickets/${localStorage.getItem("code")}`,
      data: {
        deadline: dateString
      },
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    }).then(res => {
      console.log(res)
      this.fetchTickets();
    })
    .catch(err => {
      console.log(err)
    })  
  }

  // to show call section
  onCallWrap = () => {
    this.setState({
        showCallWrap: !this.state.showCallWrap
    });
  };

  // to show modal that change contacts
  addNumber = () => {
    this.setState({
        addNumber: !this.state.addNumber
    });
  };

  // to show modal that change address
  addAddress = () => {
    this.setState({
        addAddress: !this.state.addAddress
    });
  };

  // to show modal that change owner
  addOwner = () => {
    this.setState({
        addOwner: !this.state.addOwner
    });
  };

  // to show modal that changes assignees
  addAssign = () => {
    this.setState({
        addAssign: !this.state.addAssign
    });
  };
  
  // function that changes owner
  handleChange = (value, label) => {
    this.setState({
      owner_name: label.props.label.name,
      owner_id: value,
      owner: label.props.label
    })

    // to delete assignee from chosen assignee from base when chosen assignee has chosen as owner
    this.addowner(value);

    axios({
      method: 'put',
      headers: {    'Content-Type': 'application/json'  },
      url: this.state.urls,
      data: {
        owner_id: value
      },
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    }).then(res => {
      console.log(res)
      this.fetchTickets();

    })
    .catch(err => {
      console.log(err)
    })
  }

  // to attach file to comment but need to improve in order to works properly
  fileAttach = (event) =>{
    console.log(event.target.files[0])
    this.setState({file: event.target.files[0]});    
  }

  fileRemove = () =>{
    this.setState({file: null});   
    console.log(this.state) 
  }

  // to delete assignee from chosen assignee drom base when chosen assignee has chosen as owner
  addowner = (value) =>{
    var i = this.state.owner_id;

    // choose assignee id when it's user_id equals to new owner
    this.state.assignee.map(assign => {
      if(value == assign.user_id) {
        axios({
          method: 'delete',
          headers: {    'Content-Type': 'application/json'  },
          url: `${CONN}/ticket_assignee/${assign.id}`,
          data: {
          },
          headers: {
            'Authorization': `Bearer  ${this.state.token}`
          }
        }).then(res => {
          console.log(res)
          this.fetchTickets();
        })
        .catch(err => {
          console.log(err)
        })
      }
    })
  }

  // is called on onSelect of assignee
  handleChange1(value, label) { 
      axios({
        method: 'post',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/ticket_assignee`,
        data: {
          ticket_id: this.state.tickets[0].id,
          user_id: value
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.fetchTickets();
      })
      .catch(err => {
        console.log(err)
      })
  }

  // to change address on base
  handleLoc = () =>{
    axios({
      method: 'put',
      headers: {    'Content-Type': 'application/json'  },
      url: `${CONN}/customers/${this.state.customer_id}`,
      data: {
        address: this.state.addressNew
      },
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    }).then(res => {
      console.log(res)
    })
    .catch(err => {
      console.log(err)
    })
    this.setState({address: this.state.addressNew, addAddress: !this.state.addAddress})
  }

  //to change contact info on base
  handleOk = () =>{
    axios({
      method: 'put',
      headers: {    'Content-Type': 'application/json'  },
      url: `${CONN}/customers/${this.state.customer_id}`,
      data: {
        mobile: this.state.mobile,
        viber: this.state.viber,
        email: this.state.email
      },
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    }).then(res => {
      console.log(res)
      this.fetchTickets();
    })
    .catch(err => {
      console.log(err)
    })

    this.setState({addNumber: !this.state.addNumber})
  }

  // to close modal that changes owner but NOW DISABLED
  closeOwner = () =>{
    this.setState({addOwner: !this.state.addOwner})
  }

  // to close modal that changes assignees
  closeAssign = () =>{
    this.setState({addAssign: !this.state.addAssign})
  }


  // main view render started but it is now that real rendering REAL starts on return
  render() {

    // imports states
    const {users, fileList, tickets} = this.state;

    // for use of call record play
    const currentTime = getTime(this.state.currentTime);
    const duration = getTime(this.state.duration);

        // to change source overlays on menu
        const onChangeSource = (
          <Menu>
            {
              this.state.sources.map(stats => (
                <Menu.Item>
                  <Button block block type="link" 
                    onClick={() => {this.setState({
                      source_id: stats.id,
                      source_name: stats.keyword
                    }),
                    axios({
                      method: 'put',
                      headers: {    'Content-Type': 'application/json'  },
                      url: `${CONN}/customers/${this.state.customer_id}`,
                      data: {
                        source_id: stats.id
                      },
                      headers: {
                        'Authorization': `Bearer  ${this.state.token}`
                      }
                    }).then(res => {
                      console.log(res)
                      this.fetchTickets();
                    })
                    .catch(err => {
                      console.log(err)
                    })

                    this.setState({source_id: stats.id})

                    }}>
                    {stats.keyword}
                  </Button>
                </Menu.Item>
              ))
            }
          </Menu>
        );

    // to change status overlays on menu
    const onChangeStatus = (
      <Menu>
       {
          this.state.statuses.map( stats=>(
            <Menu.Item>
            <Button block block type="link"
              onClick={() => { this.setState({
                status_id: stats.id,
                status_name: stats.name
              })
              axios({
                method: 'put',
                headers: {    'Content-Type': 'application/json'  },
                url: `${CONN}/tickets/${localStorage.getItem("code")}`,
                data: {
                  status_id: stats.id
                },
                headers: {
                  'Authorization': `Bearer  ${this.state.token}`
                }
              }).then(res => {
                console.log(res)
                this.fetchTickets();
              })
              .catch(err => {
                console.log(err)
              })
              }
              }>
              {stats.name}
            </Button>
          </Menu.Item>
          ))
        }
      </Menu>
    );

        // to change status overlays on menu
        const onChangeDepartment = (
          <Menu>
           {
              this.state.departments.map( deps=>(
                <Menu.Item>
                <Button block block type="link"
                  onClick={() => { this.setState({
                    department_id: deps.id,
                    department_name: deps.name
                  })
                  axios({
                    method: 'put',
                    headers: {    'Content-Type': 'application/json'  },
                    url: `${CONN}/tickets/${localStorage.getItem("code")}`,
                    data: {
                      department_id: deps.id
                    },
                    headers: {
                      'Authorization': `Bearer  ${this.state.token}`
                    }
                  }).then(res => {
                    console.log(res)
                    this.fetchTickets();
                  })
                  .catch(err => {
                    console.log(err)
                  })
                  }
                  }>
                  {deps.name}
                </Button>
              </Menu.Item>
              ))
            }
          </Menu>
        );

    // to change topic overlays on menu
    const onChangeTopic = (
      <Menu>
        {
          this.state.topics.map( stats=>(
            <Menu.Item>
            <Button block block type="link"
              onClick={() => { this.setState({
                topic_id: stats.id,
                topic_name: stats.name
              })
              axios({
                method: 'put',
                headers: {    'Content-Type': 'application/json'  },
                url: `${CONN}/tickets/${localStorage.getItem("code")}`,
                data: {
                  topic_id: stats.id
                },
                headers: {
                  'Authorization': `Bearer  ${this.state.token}`
                }
              }).then(res => {
                console.log(res)
                this.fetchTickets();
              })
              .catch(err => {
                console.log(err)
              })
              }
              }>
              {stats.name}
            </Button>
          </Menu.Item>
          ))
        }
      </Menu>
    );

    //quick_response dropdown menu
    const onquick =(
      <Menu>
        {
          this.state.quick_response.map(quick =>(
            <Menu.Item>
              <Tooltip placement='rightTop' title={quick.answer}>
                <Button block onClick={()=>{
                  this.setState({text:  this.state.text  + ' "'  + quick.answer + '"', quick_response_id: quick.id});
                  this.setState({notesArea: false, commentArea: true});
                  setTimeout(() => {
                    this.setState({notesArea: true, commentArea: false});
                  }, 50);
                  if(this.state.text.length + quick.answer.length > 160){
                    message.warning("Тэмдэглэлд 160 дээш тэмдэгт орсон байна.")
                  } 
                }
                }>
                  {quick.question}
                </Button>
              </Tooltip>
            </Menu.Item>
          ))
        }
      </Menu>
    )

    // to change priority overlays on menu
    const onChangePriority = (
      <Menu>
        {
          this.state.priorities.map( stats=>(
            <Menu.Item>
            <Button block block type="link"
              onClick={() => { this.setState({
                priority_id: stats.id,
                priority_name: stats.name,
                deadline: this.state.created_at + stats.period
              })
              axios({
                method: 'put',
                headers: {    'Content-Type': 'application/json'  },
                url: `${CONN}/tickets/${localStorage.getItem("code")}`,
                data: {
                  priority_id: stats.id
                },
                headers: {
                  'Authorization': `Bearer  ${this.state.token}`
                }
              }).then(res => {
                console.log(res)
                this.fetchTickets();
              })
              .catch(err => {
                console.log(err)
              })
              }
              }>
              {stats.name}
            </Button>
          </Menu.Item>
          ))
        }
      </Menu>
    );

    const { Option } = Select;
  
    // is called when assignee deselected on ondeselect
    const handleDeselect = (value, label) => {

      //assignee value can be come by two different types 'string' or 'integer' 
        // if 'integer' and can be used equals assignee's user_id
        if(isNaN(value) == false){
          this.state.assignee.map(assign => {
            if(value == assign.user_id) {
              axios({
                method: 'delete',
                headers: {    'Content-Type': 'application/json'  },
                url: `${CONN}/ticket_assignee/${assign.id}`,
                data: {
                },
                headers: {
                  'Authorization': `Bearer  ${this.state.token}`
                }
              }).then(res => {
                console.log(res)
                this.fetchTickets();
              })
              .catch(err => {
                console.log(err)
              })
            }
          })
        }
        // if 'string' and can be used equals assignee's name
        else{
          this.state.assignee.map(assign => {
            if(value == assign.name) {
              axios({
                method: 'delete',
                headers: {    'Content-Type': 'application/json'  },
                url: `${CONN}/ticket_assignee/${assign.id}`,
                data: {
                },
                headers: {
                  'Authorization': `Bearer  ${this.state.token}`
                }
              }).then(res => {
                console.log(res)
                this.fetchTickets();
              })
              .catch(err => {
                console.log(err)
              })
            }
          })
        }
    }    

    // to filter view of list of assignees, chosen assignees and owner will be hidden 
    const filteredOptions = this.state.users.filter(o => !this.state.selectedUser.includes(o));

    return(
      <Layout style={{ marginTop: 48, backgroundColor: '#ffffff'}}>
        {/***********************left sider START **********************/}
        <Content style={{ marginLeft: 48, position: 'fixed', width: 256, height: '100%', paddingTop: 16, borderRight: 1, borderRightColor: '#E8E8E8', borderRightStyle: 'solid'}}>
          <Row className="ticketDetailSide">
            <Col span={24} >
              <Row>
                <Col span={16} style={{align:'center', marginLeft: 50}}>
                    <h1>{
                        <span># {this.state.tickets.code}      </span>
                      } 
                      {
                      this.state.playAudio === false
                      ?
                        <Icon type="audio" onClick={this.playAudio} style={{color: "red", fontSize:"22px", alignSelf:'right'}}/>
                      :
                        <Icon type="pause-circle" onClick={this.pauseAudio} style={{ color: '#52C41A', fontSize:'22px', alignSelf:'right' }} />
                    }
                      </h1>  
                </Col>           
              </Row>
            </Col>  
            <Divider />
            <Col span={24} style={{ marginBottom: 10, marginLeft: 16}}>
            <Row>
              <Col span={16} className="sideList">
                <span className="sideHeading">
                  Төлөв
                </span> 
                <Dropdown overlay={onChangeStatus}>
                  <Button block className="sideDropdown">
                  {
                    this.state.status_name
                  } 
                    <Icon type="down" />
                  </Button>
                </Dropdown> 
              </Col>               
            </Row>                
          </Col>
            <Col span={24} style={{ marginBottom: 10, marginLeft: 16}}>
              <Row>
                <Col span={16} className="sideList">
                <span className="sideHeading">
                    Төрөл
                  </span> 
                  { 
                    /*<TreeSelect
                      className="sideDropdown3"
                      style={{ width: '100%', color: '#2F54EB', borderTopColor: '#2F54EB' }}
                      value={this.state.value}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto', color: '#2F54EB' }}
                      treeData={this.state.topics}
                      placeholder="Please select"
                      treeDefaultExpandAll
                      onChange={this.onChange1}
                  />*/}

                  <Dropdown overlay={onChangeTopic}>
                    <Button block block className="sideDropdown">
                    {
                      this.state.topic_name
                    } 
                      <Icon type="down" />
                    </Button>
                  </Dropdown> 
                </Col>               
              </Row>                
            </Col>   
            <Col span={24} style={{ marginBottom: 10, marginLeft: 16 }}>
              <Row>
                <Col span={16} className="sideList">
                <span className="sideHeading">
                    Зэрэглэл
                  </span> 
                  <Dropdown overlay={onChangePriority}>
                    <Button block block className="sideDropdown">
                    {
                      this.state.priority_name
                    } 
                      <Icon type="down" />
                    </Button>
                  </Dropdown> 
                </Col>               
              </Row>                
            </Col>        
            <Col span={24} style={{ marginBottom: 10, marginLeft: 16 }}>
              <Row>
                <Col span={16} className="sideList">
                  <span className="sideHeading">
                    Эхэлснээс хойш
                  </span> 
                  <Col span={16}>
                    <span className="sideDuration">
                      <Moment toNow style={{color:'#2F64EB'}}>{this.state.durations}</Moment></span>
                  </Col>
                  <Col span={16} className="sideList">
                  <span className="sideHeading">
                    Дуусах хугацаа
                  </span>
                    <DatePicker
                      className="sideDropdown3"
                      showTime
                      name="deadline"
                      format="YYYY-MM-DD HH:mm:ss"
                      placeholder= {this.state.date}
                      onChange={this.onChange}
                      defaultValue= {this.state.date}
                    />
                  </Col>                    
                </Col>               
              </Row>                
            </Col>   
            <Col span={24} style={{ marginBottom: 10, marginLeft: 16}}>
              <Row>
                <Col span={16} className="sideList">
                  <span className="sideHeading">
                    Хэлтэс
                  </span> 
                  <Dropdown overlay={onChangeDepartment}>
                    <Button block block className="sideDropdown">
                    {
                      this.state.department_name
                    } 
                      <Icon type="down" />
                    </Button>
                  </Dropdown> 
                </Col>               
              </Row>                
            </Col>
            <Col span={24} style={{ marginBottom: 10, marginLeft: 16 }}>
              <Row>
                <Col span={16} className="sideList">
                  <span className="sideHeading">
                    Хариуцагч:
                  </span>
                  <Select
                    className='sideDropdown3'
                    style={{ width: '193px', color:'#2F54EB' }}
                    autoFocus={true}
                    maxTagCount={1}
                    value={this.state.owner_name}
                    placeholder="Add members"
                    onChange={this.handleChange}
                    optionLabelProp="label"
                  >
                        {
                          users.map(d => (
                            <Option value={d.id} label={d}>
                              <span className="select-Assistname">{d.name}</span>
                            </Option>
                          ))
                        }
                      </Select>
                  <Col span={24} className="sideAssists">                    
                  <span className="sideHeading">
                    Оролцогч:
                    </span> 
                    <Select
                      className='sideDropdown3'
                      mode="multiple"
                      style={{ width: '100%' }}
                      autoFocus={true}
                      maxTagCount={2}
                      placeholder="Add members"
                      defaultValue={this.state.assignee.map( d=> (
                        d.name ))}
                      onDeselect={handleDeselect}
                      onSelect={this.handleChange1}
                      optionLabelProp="label"
                      style={{width:'193px'}}
                    >
                      {
                        filteredOptions.map(d => (
                      <Option value={d.id} label={d.name}>
                        <span className="select-Assistname">{d.name}</span>
                      </Option>
                        ))
                      } 
                    </Select>
                  </Col>                      
                </Col>                              
              </Row>                
            </Col>     
            {/*<Col span={24} style={{ marginBottom: 16, marginLeft: 16 }}>
              <Row>
                <Col span={16} className="sideList">
                <span className="sideHeading">
                    Дуудлагын бичлэг:
                  </span> 
                  <Col className="sidePlayer" span={20} >
                    {
                      this.state.playAudio === false
                      ?
                      <div onClick={this.playAudio}>
                        <Icon type="audio"  style={{color: "red", fontSize:"20px"}}/>
                        <span className="playerTime" style={{fontSize: '20px'}}>{currentTime}</span>
                      </div> 
                      :
                      <div onClick={this.pauseAudio}>
                        <Icon type="pause-circle" style={{ color: '#52C41A', fontSize:'20px' }} />
                        <span className="playerTime" style={{fontSize: '20px'}}>{currentTime} / {duration}</span>
                      </div>
                    }
                  </Col>
                </Col>               
              </Row>                
            </Col>*/}   
          </Row>  
        </Content>
        {/***********************left sider END **********************/}
        {/****************************** Modal section START********************************/}
          {/********CALLER BUTTONS*********/}
          {
            this.state.showCallWrap === false
            ?
            null
            :
            <div className="topCallRow">
                <div className="topCallArrow"></div> 
                <p>Холбогдох дугаараа оруулна уу!</p>
                <Input type="number" size="large" placeholder="Дугаар оруулах" style={{ marginBottom: 20 }} />
                <Button block block className="topCallBtn" block>Залгах</Button>
            </div>                                
          }
          
          {/*<Modal 
            visible={this.state.addOwner}
            onOk={this.closeOwner}
            onCancel={this.addOwner}>
                  <Col span={24} className="sideAssists">
                      <ul className="assitsList">   
                      <li>
                        <div className="selectAssists">
                        <Select
                          style={{ width: '100%' }}
                          autoFocus={true}
                          maxTagCount={1}
                          value={this.state.owner_name}
                          placeholder="Add members"
                          onChange={this.handleChange}
                          optionLabelProp="label"
                        >
                          {
                            users.map(d => (
                              <Option value={d.id} label={d}>
                            <span className="select-Assistname">{d.name}</span>
                          </Option>
                            ))
                          }
                        </Select>
                        </div>
                      </li>
                      </ul>   
                    </Col>
                        </Modal>*/}

          {/******** ADD ASSIGNEE *********/}
          <Modal 
            visible={this.state.addAssign}
            onOk={this.closeAssign}
            onCancel={this.addAssign}
            title=" ">
                    <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      autoFocus={true}
                      maxTagCount={4}
                      placeholder="Add members"
                      defaultValue={this.state.assignee.map( d=> (
                        d.name ))}
                      onDeselect={handleDeselect}
                      onSelect={this.handleChange1}
                      optionLabelProp="label"
                    >
                      {
                        filteredOptions.map(d => (
                          <Option value={d.id} label={d.name}>
                        <span className="select-Assistname">{d.name}</span>
                      </Option>
                        ))
                      } 
                    </Select>
          </Modal>

          {/********CHANGE CONTACTS*********/}
          <Modal
          visible={this.state.addNumber}
          onOk={this.handleOk}
          onCancel={this.addNumber}>
            {
              this.state.addNumber === false
              ?
              null
              :
              <div className="ticketUserContact">
                <h3>Холболт өөрчлөх</h3>
                <ul>
                  {
                    this.state.sources.map(source => 
                      (<li>
                        <Row>
                          <Col span={3} style={{ textAlign: 'center', paddingTop: 6  }}>
                          <Icon type={source.keyword} style={{ color: '#597EF7' }} />
                          </Col>
                          <Col span={15} style={{ paddingTop: 7 }}>
                            <span className="contactInfo">
                              <Input
                              name={source.keyword}
                              placeholder={source.keyword}
                              onChange={this.changeHandler}
                              type="text"
                              />
                            </span>
                          </Col>
                          <Col>
                          <div>

                          </div>
                          </Col>
                        </Row>
                        
                      </li>
                      )
                    )
                    }              
                </ul>
                <ul>
                  <li>
                    <div >
                    <div span={15} style={{ paddingTop: 7 }}>
                    <h4>Та аль хэрэгслээр мэдээлэл хүлээн авах вэ?</h4>
                    </div>
                    </div>
                    <div>
                      <div span={15} style={{ paddingTop: 7, paddingBottom: 100 }}>
                      <Dropdown overlay={onChangeSource}>
                          <Button block block className="sideDropdown">
                            {this.state.source_name}
                            <Icon type="down" />
                          </Button>
                        </Dropdown> 
                      </div>
                    </div>
                  </li>
                </ul>
              </div>                                
            }
          </Modal>

          {/********CHANGE ADDRESS*********/}
          <Modal
            visible={this.state.addAddress}
            onOk={this.handleLoc}
            onCancel={this.addAddress}>
            {this.state.addAddress === false
              ?
              null
              :
              <div className="ticketUserContact">
                <h3>Хаяг авах</h3>
                <div>
                  {
                    <Input
                    name="addressNew"
                    defaultValue={this.state.address}
                    onChange={this.changeHandler}
                    type="address"
                    /> 
                  }
                </div>
              </div>                                
            }
          </Modal> 
        {/****************************** Modal section END********************************/}
        {/****************************** main start********************************/}
        <Content style={{ marginLeft: 304, marginRight: 320, backgroundColor: '#FFFFFF' }}>
          <Affix  offsetTop={48}>
          <div className="ticketHeader">
                <h2 style={{lineHeight:'20px'}}>{
                  this.state.tickets.title  
                }</h2>
                <Divider style={{marginBottom: '0px'}}/>
          </div>
          </Affix>
          <Tabs defaultActiveKey="1" className="ticketTab" style={{ backgroundColor: '#FFFFFF' }}>
            <TabPane tab="Тэмдэглэл" key="1" className="ticketDetail" style={{ backgroundColor: '#FFFFFF' }}>
              <Layout style={{ backgroundColor: '#FFFFFF' }}>
                {
                  this.state.isbefore == true
                  ?
                  this.state.tickets[0].reply.map(reply=>(
                    reply.is_reply==1 && moment(reply.created_at).isBefore('2020-03-30') == true
                    ?
                    <Content style={{backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: 20}}>             
                      <Row className="userInfo" style={{ height: 45 }}>
                          <Col className="userAvatar" span={3} style={{ marginTop: 6 }}>
                            <Icon type="user" />
                          </Col>

                          <Col span={21} style={{ marginLeft: 8 }}>
                            <Row>
                              <Col span={24}>
                                <div className="userName">{reply.username}</div>
                              </Col>
                              <Col span={24}>
                                <span className="timeago"><Moment fromNow>{reply.updated_at}</Moment></span>
                              </Col>
                            </Row>
                          </Col>
                          <Tag style={{float: "right"}}>Тэмдэглэл</Tag>
                      </Row>
                      {reply.text.length >100
                      ?
                      <Row className="ticketWrap" >
                        <Collapse     bordered={false}>
                          <Panel header={reply.message} key="1">
                          <p>
                            <div dangerouslySetInnerHTML={{__html: reply.text}}/>
                            </p>
                          </Panel>
                        </Collapse>
                      </Row>
                      :
                      <Row className="ticketWrap" >
                            <p>
                            <div dangerouslySetInnerHTML={{__html: reply.text}}/>
                            </p>
                      </Row>
                      }
                      
                    </Content>
                    :
                    reply.is_note == 1 && moment(reply.created_at).isBefore('2020-03-30') == true
                    ?
                    <Content style={{backgroundColor: '#e6f2ff', marginBottom: 20}}>             
                      <Row className="userInfo" style={{ height: 45 }}>
                          <Col className="userAvatar" span={3} style={{ marginTop: 6 }}>
                            <Icon type="user" />
                          </Col>

                          <Col span={21} style={{ marginLeft: 8 }}>
                            <Row>
                              <Col span={24}>
                                <div className="userName">{reply.username}</div>
                              </Col>
                              <Col span={24}>
                                <span className="timeago"><Moment fromNow>{reply.updated_at}</Moment></span>
                              </Col>
                            </Row>
                          </Col>
                          <Tag color="cyan" style={{float: "right"}}>Сэтгэгдэл</Tag>
                      </Row>
                      {reply.text.length >100
                      ?
                      <Row className="ticketWrap" >
                        <Collapse     bordered={false}>
                          <Panel header={reply.message} key="1">
                          <p>
                            <div dangerouslySetInnerHTML={{__html: reply.text}}/>
                            </p>
                          </Panel>
                        </Collapse>
                      </Row>
                      :
                      <Row className="ticketWrap" >
                            <p>
                            <div dangerouslySetInnerHTML={{__html: reply.text}}/>
                            </p>
                      </Row>
                      }
                    </Content>
                    :
                    null
                  ))
                  :
                  null
                }

                {this.state.log === null
                  ?
                  <Timeline>
                    <Timeline.Item color="red">
                          <div>
                            Log хоосон байна
                          </div>
                    </Timeline.Item>
                  </Timeline>
                  :
                  <Timeline>
                    {
                      this.state.log.map(map =>{
                        <Timeline.Item color="red">
                          <div>
                            Log хоосон байна
                          </div>
                        </Timeline.Item>
                      })
                    }
                  </Timeline>
                }
                <Timeline>
                  {
                    this.state.log.map(logs => (
                      logs.action == "ADD_REPLY" && logs.new_value[0].is_reply == 1
                      ?
                      
                      <Content style={{backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: 20}}>
                      
                      <Row className="userInfo" style={{ height: 45 }}>
                          <Col className="userAvatar" span={3} style={{ marginTop: 6 }}>
                            <Icon type="user" />
                          </Col>

                          <Col span={21} style={{ marginLeft: 8 }}>
                            <Row>
                              <Col span={24}>
                                <div className="userName">{((logs.created_user_id)?logs.created_user_id.name:'No Name')}</div>
                              </Col>
                              <Col span={24}>
                                <span className="timeago"><Moment fromNow>{((logs.new_value)?logs.new_value[0].created_at:'invalid date')}</Moment></span>
                              </Col>
                            </Row>
                          </Col>
                      </Row>
                      {logs.new_value[0].text.length > 100
                      ?
                      <Row className="ticketWrap" >
                        <Collapse   style={{backgroundColor: 'rgba(0, 0, 0, 0)'}}  bordered={false}>
                          <Panel header={logs.new_value[0].message.substring(0, 150)} key="1">
                          <p>
                            <div dangerouslySetInnerHTML={{__html: logs.new_value[0].message}}/>
                            </p>
                          </Panel>
                        </Collapse>
                      </Row>
                      :
                      <Row className="ticketWrap" >
                            <p>
                            <div dangerouslySetInnerHTML={{__html: logs.new_value[0].message}}/>
                            </p>
                      </Row>
                      }
                      <Row>

                      {
                        this.state.tickets.reply.map(reply=>(
                          reply.id == logs.new_value[0].id
                          ?
                          reply.attachment.length == 0
                            ?
                            null
                            :
                            <Link style={{float: 'right'}} onClick={()=> window.open(`${reply.attachment[0].file}`, "_blank")}><Icon style={{fontSize:'20px'}} type='paper-clip'/></Link>
                          :
                          null
                        ))
                      }
                      </Row> 

                    </Content>
                      :
                      logs.action == "ADD_REPLY" && logs.new_value[0].is_note == 1
                      ?
                      <Content style={{backgroundColor: '#e6f2ff', marginBottom: 20}}>
                      <Row className="userInfo" style={{ height: 45 }}>
                          <Col className="userAvatar" span={3} style={{ marginTop: 6 }}>
                            <Icon type="user" />
                          </Col>
                          <Col span={21} style={{ marginLeft: 8 }}>
                            <Row>
                              <Col span={24}>
                                <div className="userName">{((logs.created_user_id)?logs.created_user_id.name:'No Name')}</div>
                              </Col>
                              <Col span={24}>
                                <span className="timeago"><Moment fromNow>{((logs.new_value)?logs.new_value[0].created_at:'invalid date')}</Moment></span>
                                
                              </Col>
                            </Row>
                          </Col>
                      </Row>
                      {logs.new_value[0].text.length >100
                      ?
                      <Row className="ticketWrap" >
                        <Collapse style={{backgroundColor: '#e6f2ff'}} bordered={false}>
                          <Panel header={<Paragraph ellipsis>{logs.new_value[0].message}</Paragraph>} key="1">
                          <p>
                            <div dangerouslySetInnerHTML={{__html: logs.new_value[0].message}}/>
                          </p>
                          </Panel>
                        </Collapse>
                      </Row>
                      :
                      <Row className="ticketWrap" >
                            <p>
                            <div dangerouslySetInnerHTML={{__html: logs.new_value[0].message}}/>
                            </p>
                      </Row>
                      }
                      <Row>
                      {
                        this.state.tickets.reply.map(reply=>(
                          reply.id == logs.new_value[0].id
                          ?
                          reply.attachment.length == 0
                            ?
                            null
                            :
                            <Link style={{float: 'right'}} onClick={()=> window.open(`${reply.attachment[0].file}`, "_blank")}><Icon style={{fontSize:'20px'}} type='paper-clip'/></Link>
                          :
                          null
                        ))
                      }
                      </Row>
                    </Content>
                      :
                      logs.action == 'CLOSED'
                      ?
                      <Timeline.Item color="red" dot={<Icon type='close-circle' />}>
                        <div> <b>{logs.action}</b> by <b>{logs.created_user_id.name}</b> at <b>{logs.created_at}</b></div>
                      </Timeline.Item>
                      :
                      logs.action == 'CREATED'
                      ?
                    <Timeline.Item color="green" dot={<Icon type='check-circle' />}>
                        <div> <b>{logs.action}</b> by <b>{logs.created_user_id==null?"Automatically":logs.created_user_id.name}</b> at <b>{logs.created_at}</b></div>
                      </Timeline.Item>
                      :
                      logs.action == 'ADD_ASSIGNEE'
                      ?
                      <Timeline.Item color="blue" dot={<Icon type='user-group-add' />}>
                        <div> <b>{logs.action}</b> : to <b>{logs.new_value !== null ? logs.new_value.name : "-"}</b> by <b>{logs.created_user_id.name}</b> at <b>{logs.created_at}</b></div>
                      </Timeline.Item>
                      :
                      logs.action == 'REMOVE_ASSIGNEE'
                      ?
                      <Timeline.Item color="red" dot={<Icon type='user-group-delete' />}>
                        <div> <b>{logs.action}</b> : from <b>{logs.new_value !== null ? logs.new_value.name : "-"}</b> by <b>{logs.created_user_id.name}</b> at <b>{logs.created_at}</b></div>
                      </Timeline.Item>
                      :
                      <Timeline.Item color="blue">
                        <div> <b>{logs.action}</b> : from <b>{logs.old_value !== null ? logs.old_value.name: "undefined"} </b> to <b>{logs.new_value !== null ? logs.new_value.name : "-"}</b> by <b>{logs.created_user_id.name}</b> at <b>{logs.created_at}</b></div>
                      </Timeline.Item>
                    ))
                  }
                </Timeline>      
              </Layout>
            </TabPane>
          </Tabs>
          <div style={{ backgroundColor: '#FFFFFF', marginTop: 44 , marginLeft: 44, marginRight: 44 }}>
            <div style={{ flex: 1, marginBottom: 20 }}>
              <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
                <li style={{ marginRight: 10, float: 'left' }}>
                  {
                    this.state.notesArea === true
                    ?
                    <Button onClick={this.showNotes} className="btnActive">Хариу илгээх</Button>
                    :
                    <Button onClick={this.showNotes}>Хариу илгээх</Button>
                  }
                </li>
                <li>
                  {
                    this.state.commentArea === true
                    ?
                    <Button onClick={this.showComment} className="btnActive">Дотоод тэмдэглэл</Button>
                    :
                    <Button onClick={this.showComment}>Дотоод тэмдэглэл</Button>
                  }
                </li>
              </ul>
            </div>
            {
              this.state.notesArea !== false
              ?
              <div>
                {
                    this.state.send_source == 8 || 9
                    ?
                    <div> 
                      <Input
                        name="notefrom"
                        size="large"
                        className="ticketWrap-input input-from"
                        placeholder="<brandon@compnaydomain.com>"
                        prefix={<span>Хэнээс: </span>}
                        readOnly
                        defaultValue={this.state.notefrom}
                        onChange={this.changeHandler} 
                      />
                      <Input
                        name="noteto"
                        size="large"
                        className="ticketWrap-input"
                        placeholder="Хүлээн авагчийн утасны дугаар"
                        prefix={<span>Хэнд: </span>}
                        width="48"
                        defaultValue={this.state.mobile}
                        onChange={this.changeHandler} 
                        />    
                      
                      <TextArea name="text" maxlength="160" wrapperClassName="ticketWrapper"
                        editorClassName="ticket-editorWrapper"
                        onChange={this.changeHandler} 
                        defaultValue={this.state.text}
                        rows="4" cols="75"
                        allowClear
                        autoFocus
                        >
                      </TextArea>   
                    </div>
                    :
                    <div> 
                      <Input
                        name="notefrom"
                        size="large"
                        className="ticketWrap-input input-from"
                        placeholder="<brandon@compnaydomain.com>"
                        prefix={<span>Хэнээс: </span>}
                        readOnly
                        defaultValue={this.state.notefrom}
                        onChange={this.changeHandler} 
                      />
                      <Input
                        name="noteto"
                        size="large"
                        className="ticketWrap-input"
                        placeholder="Хүлээн авагчийн утасны дугаар"
                        prefix={<span>Хэнд: </span>}
                        width="48"
                        defaultValue={this.state.email}
                        onChange={this.changeHandler} 
                        />    
                      <ReactQuill value={this.state.text} name='text'
                        modules={{
                          toolbar: {
                              container: [
                                  [{ header: [] }, { font: [] }],
                                  [{ size: [] }],
                                  ['bold', 'italic', 'underline'],
                                  [{'background':[]}, {'color':[]}],
                                  [{ list: 'ordered' }, { list: 'bullet' }, {'align': []}],
                                  ['link', 'video'],
                                  ['clean']                        
                                ],
                            }
                        }}
                  formats={this.formats}
                  onChange={this.quillHandler} 
                  theme="snow"/>
                  </div>
                
                }
              </div>
              :
              null
            }
            {
              this.state.commentArea !== false
              ?
              <div>
                {
                    this.state.tickets.customer.source_id == 8 || 9
                    ?
                    <div>
                      <Input
                        name="notefrom"
                        size="large"
                        className="ticketWrap-input input-from"
                        placeholder="<brandon@compnaydomain.com>"
                        prefix={<span>Хэнээс: </span>}
                        defaultValue={this.state.notefrom}
                        readOnly
                        onChange={this.changeHandler} 
                      />
                      <Input
                        name="noteto"
                        size="large"
                        className="ticketWrap-input"
                        placeholder="Хүлээн авагчийн утасны дугаар"
                        prefix={<span>Хэнд: </span>}
                        width="48"
                        defaultValue={this.state.mobile}
                        onChange={this.changeHandler} 
                        />    
                      
                      <TextArea name="text" maxlength="160" wrapperClassName="ticketWrapper"
                        editorClassName="ticket-editorWrapper"
                        onChange={this.changeHandler}
                        defaultValue={this.state.text}
                        rows="4" cols="75"
                        allowClear
                        >
                      </TextArea>   
                    </div>
                    :
                    <div> 
                      <Input
                        name="notefrom"
                        size="large"
                        className="ticketWrap-input input-from"
                        placeholder="<brandon@compnaydomain.com>"
                        prefix={<span>Хэнээс: </span>}
                        readOnly
                        defaultValue={this.state.notefrom}
                        onChange={this.changeHandler} 
                      />
                      <Input
                        name="noteto"
                        size="large"
                        className="ticketWrap-input"
                        placeholder="Хүлээн авагчийн утасны дугаар"
                        prefix={<span>Хэнд: </span>}
                        width="48"
                        defaultValue={this.state.mobile}
                        onChange={this.changeHandler} 
                        />    
                      <ReactQuill value={this.state.text} name='text'
                        modules={{
                          toolbar: {
                              container: [
                                  [{ header: [] }, { font: [] }],
                                  [{ size: [] }],
                                  ['bold', 'italic', 'underline'],
                                  [{'background':[]}, {'color':[]}],
                                  [{ list: 'ordered' }, { list: 'bullet' }, {'align': []}],
                                  ['link', 'video'],
                                  ['clean']                        
                                ],
                            }
                        }}
                  formats={this.formats}
                  onChange={this.quillHandler} 
                  theme="snow"/>
                  </div>
                }
              </div>
              :
              null
            }
            <ul className="ticketWrap-buttons">
              <li style={{ marginRight: 8 }}>
                <input type= "file" name = "file" onChange={this.fileAttach}/>
              </li>
              {/*<li>
                <Upload onChange={this.fileAttach} onRemove={this.fileRemove}>
                  <Button>
                    <Icon type={'paper-clip'}/> Хавсралт
                  </Button>
                </Upload>
              </li>*/}
              <li style={{ marginRight: 8 }}>
                <Button block  onClick={this.postTicketDraft}>
                  <Icon type="save" />
                  Түр хадгалах
                </Button>
              </li>   
              <li style={{ marginRight: 8 }}>
                <Dropdown overlay={onquick}>
                  <Button block>
                    <Icon type="thunderbolt" />
                    Түргэн хариултууд
                  </Button>
                </Dropdown>
              </li>                 
              <li style={{ float: 'right'}}>
                <Button block  type="primary" onClick={this.postTicket}>
                    Илгээх
                </Button>
              </li>                    
            </ul>
          </div>
        </Content>   
        {/****************************** main END********************************/}
        {/****************************** rigth sider START********************************/}
        <Content style={{ position: 'fixed', height: '100%', right: 0, width: 320, paddingLeft: 24, paddingRight: 24, borderLeft: 1, borderLeftColor: '#E8E8E8', borderLeftStyle: 'solid'  }}>
          <Row className="ticketUserDetail">
            <Col className="userAvatar" span={3}>
              <Icon type="user" />
            </Col>
            <Col span={18}>
              <Row>
                <Col span={24}>
                  <h3 className="userName">
                    {
                        this.state.tickets.customer == null && this.state.tickets.customer == null
                        ?
                        <div>
                         Нэр тодорхойгүй
                        </div>
                        :
                        <div>
                          {this.state.tickets.customer.name}
                        </div>
                    }
                  </h3>
                </Col>
                <Col span={24}>
                  <span className="userStatus">
                    <Link to={{
                    pathname: `/admin/ticket/customer/${this.state.customer_id}`,
                    state: {id: this.state.customer_id}
                    }} target="_blank">
                      Бусад тикет: {this.state.hits}
                    </Link>
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={3}>
              <Icon type="more" style={{ float: 'right', fontSize: 24, color: '#60698E', marginTop: 10 }} />
            </Col>
          </Row>
          <Row className="ticketUserContact">
            <h3>Холбоо барих</h3>
            <ul>
              <li>
                {
                    this.state.tickets.customer != null
                    ?
                    <Row>
                      <Col span={3} style={{ textAlign: 'center', paddingTop: 6  }}>
                        <img src={sourcePhone} alt=""/>
                      </Col>
                      <Col span={15} style={{ paddingTop: 7 }}>
                        <span className="contactInfo">
                          
                            <div>
                              {this.state.tickets.customer.mobile}
                            </div>
                        </span>
                      </Col>
                      <Col span={6}>
                        <Button block block size="medium" className="contactBtn" onClick={this.onCallWrap}>Залгах</Button>
                      </Col>
                    </Row>
                    :
                    <Row>
                      <Col span={3} style={{ textAlign: 'center', paddingTop: 6  }}>
                        <img src={sourcePhone} alt=""/>
                      </Col>
                      <Col span={15} style={{ paddingTop: 7 }}>
                        Мэдээлэл байхгүй.
                      </Col>
                    </Row>
                
                }
              </li>
              <li>
                {

                  this.state.tickets.customer != null 
                    ?
                    <Row>
                      <Col span={3} style={{ textAlign: 'center', paddingTop: 6  }}>
                        <img src={sourceViber} alt=""/>
                      </Col>
                      <Col span={15} style={{ paddingTop: 7 }}>
                        <span className="contactInfo">
                        
                            <div>
                              {this.state.tickets.customer.viber}
                            </div>

                        </span>
                      </Col>
                      <Col span={6}>
                        <Button block block size="medium" className="contactBtn" onClick={this.onCallWrap}>Залгах</Button>
                      </Col>
                    </Row>
                    :
                    <Row>
                      <Col span={3} style={{ textAlign: 'center', paddingTop: 6  }}>
                        <img src={sourceViber} alt=""/>
                      </Col>
                      <Col span={15} style={{ paddingTop: 7 }}>
                        Мэдээлэл байхгүй.
                      </Col>
                    </Row>
                }
              </li>
              <li>
                {
                    this.state.tickets.customer != null
                    ?
                    <Row>
                      <Col span={3} style={{ textAlign: 'center', paddingTop: 6  }}>
                        <img src={sourceMessenger} alt=""/>
                      </Col>
                      <Col span={15} style={{ paddingTop: 7 }}>
                        <span className="contactInfo">
                          {
                              <div>
                                {this.state.tickets.customer.email}
                              </div>
                          }
                        </span>
                      </Col>
                      <Col span={6}>
                        <Button block block size="medium" className="contactBtn">Залгах</Button>
                      </Col>
                    </Row>
                    :
                    <Row>
                      <Col span={3} style={{ textAlign: 'center', paddingTop: 6  }}>
                        <img src={sourceMessenger} alt=""/>
                      </Col>
                      <Col span={15} style={{ paddingTop: 7 }}>
                        Мэдээлэл байхгүй.
                      </Col>
                    </Row>
                }
              </li>                
            </ul>
            <Button block block type="dashed" size="medium" block onClick={this.addNumber}>
              <Icon type="plus" style={{ float: 'left', marginTop: 4 }} /> Холболт нэмэх
            </Button>
          </Row>
          <Row className="ticketUserAddress">
            <h3>Хаяг</h3>
            <ul>
              <li>
                {this.state.address}
              </li>
            </ul>
            <Button block block type="dashed" size="medium" block onClick={this.addAddress}>
              <Icon type="plus" style={{ float: 'left', marginTop: 4 }} /> Хаяг засах
            </Button>              
          </Row>
          <Row className="ticketRelated">
            <h3>Холбоотой тикетүүд</h3>
          </Row>
        </Content>   
        {/****************************** rigth sider END********************************/}     
      </Layout>
    )
  }
}

export default TicketList;
