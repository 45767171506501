import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Card, Row, Col, Avatar, Switch, Icon, Upload, message, Button, Input, Modal, List, Spin } from 'antd';
import { CONN } from '../../../constant/types';
import axios from 'axios';
import { validate } from 'indicative/validator';
import ImageUploader from 'react-images-upload';
import InfiniteScroll from 'react-infinite-scroller';
import './style.css';
var token = localStorage.getItem('token')
const { Content } = Layout;

function onChange(checked) {
  console.log(`switch to ${checked}`);
}

const image = {
  name: 'img',
  action: `${CONN}/user_update`,
  headers: {
    'Authorization': `Bearer  ${token}`
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

console.log('props', image)
class AdminProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      change_name: false,
      change_email: false,
      change_password: false,
      errors: [],
      user: [],
      pictures: [],
      file: null
    }
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() { 
    this.fetchUser();
  }

  fetchUser = () => {
    var token = localStorage.getItem('token');
        const config = {
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      };
      axios
      .get(`${CONN}/user_profile`, config)
      .then((res) => {
        console.log('res.data', res)
        this.setState({
          user: res.data.user,
          name: res.data.user.name,
          email: res.data.user.email,
          password: res.data.user.password
      })
      })
  }

  changeName = () => {
    this.setState({
      change_name: !this.state.change_name
    })
  }

  changeEmail = () => {
    this.setState({
      change_email: !this.state.change_email
    })
  }

  changePassword = () => {
    this.setState({
      change_password: !this.state.change_password
    })
  }

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  changeHandlerEmail = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  changeHandlerPass = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onDrop(picture, e) {
    console.log('picture-e', e)
    console.log('picture', picture)
    console.log('state-n file', this.state.file)
    axios({
      method: 'POST',
      headers: {    'Content-Type': 'application/json'  },
      url: `${CONN}/user_update`,
      data: {
        image: e
      },
      headers: {
        'Authorization': `Bearer  ${token}`
      }
    }).then(res => {
      console.log(res)
    })
    .catch(err => {
      console.log(err)
    })
    this.setState({
        pictures: this.state.pictures.concat(picture),
    });

  }

  handleUsername = (e) =>{
    e.preventDefault()
    const data = this.state; 
    const rules = {
      nameNew: 'string'
    };
    
    const messages = {
      required: `this {{ field }} is required `,
      'email.emailNew': 'the email is invalid'
    }
    validate( data, rules, messages )
      .then(() => {
        console.log('success');
        axios({
          method: 'POST',
          headers: {    'Content-Type': 'application/json'  },
          url: `${CONN}/user_update`,
          data: {
            name: this.state.nameNew
          },
          headers: {
            'Authorization': `Bearer  ${token}`
          }
        }).then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
        this.setState({name: this.state.nameNew, change_name: !this.state.change_name})
      })
      .catch(errors => {
        console.log(errors);
        const formattedErrors = {}
        errors.forEach( error => formattedErrors[error.field] = error.message)
        this.setState({
          errors: formattedErrors
        })
      })
  }

  handleEmail = (e) =>{
    console.log('token', token)
    e.preventDefault()
    console.log('Here input data',this.state);
    const data = this.state; 
    const rules = {
      emailNew: 'email'
    };
    validate(data, rules)
      .then(() => {
        axios({
          method: 'POST',
          headers: {    'Content-Type': 'application/json'  },
          url: `${CONN}/user_update`,
          data: {
            email: this.state.emailNew
          },
          headers: {
            'Authorization': `Bearer  ${token}`
          }
        }).then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
        this.setState({email: this.state.emailNew, change_email: !this.state.change_email})
      })
      .catch(errors => {
        console.log(errors);
      })
  }

  handlePassword = (e) =>{
    e.preventDefault()
    console.log('Here input data',this.state);
    const data = this.state; 
    const rules = {
      passwordNew: 'string|min:6|regex:(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])'
    };
    validate(data, rules)
      .then(() => {
        console.log('success');
        const { passwordNew, passwordNew2 } = this.state;
        if(passwordNew !== passwordNew2){
          message.warning('Hey password are dont match each others');
          this.setState({password: this.state.passwordNew, change_password: this.state.change_password})
        }else {
          message.success('Hey password match duude');
          axios({
            method: 'POST',
            headers: {    'Content-Type': 'application/json'  },
            url: `${CONN}/user_update`,
            data: {
              password: this.state.passwordNew
            },
            headers: {
              'Authorization': `Bearer  ${token}`
            }
          }).then(res => {
            console.log(res)
          })
          .catch(err => {
            console.log(err)
          })
          this.setState({password: this.state.passwordNew, change_password: !this.state.change_password})
        }
      })
      .catch(errors=> {
        console.log(errors);
      })
  }

  render() {
    console.log('image', this.state.pictures)
    return(
      <div className="profilePage" style={{backgroundColor:"#FFF"}}>
        <Content className="profileHeaderWrap" style={{backgroundColor:"#FFF"}}>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <h1 style={{ width: 720, margin: '0 auto' }}>Профайл</h1>
          </div>
          <Card style={{ width: 720, margin: '0 auto' }}>
            <Row className="profileLists">
              <Col span={4}>
                <span className="profileType">Photo</span>
              </Col>
              <Col span={14}>
                <Avatar src="https://cdn.business2community.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640.png" className="profileUserImage" />
              </Col>
            </Row>
            <Row className="profileLists">
              <Col span={4}>
                <span className="profileType">Нэр</span>
              </Col>
              <Col span={14}>
                {this.state.name}
              </Col>
              <Col span={6}>
                <Link className="profileLink" onClick={this.changeName}>
                  Өөрчлөх
                </Link>
                <Modal
                  visible={this.state.change_name}
                  onOk={this.handleUsername}
                  onCancel={this.changeName}>
                  {this.state.changeName === false
                    ?
                    null
                    :
                    <div className="ticketUserContact">
                      <h3>Хэрэглэгчийн нэр</h3>
                      <div>
                        {
                          <Input
                          name="nameNew"
                          placeholder="Хэрэглэгчийн нэр"
                          onChange={this.changeHandler}
                          type="text"
                          /> 
                        }
                      </div>
                    </div>                                
                  }
                </Modal>
              </Col>
            </Row>
            <Row className="profileLists">
              <Col span={4}>
                <span className="profileType">Имайл</span>
              </Col>
              <Col span={14}>
                {this.state.email}
              </Col>
            </Row>       
            <Row className="profileLists">
              <Col span={4}>
                <span className="profileType">Нууц үг</span>
              </Col>
              <Col span={14}>
                <span>********</span>
              </Col>
              <Col span={6}>
                <Link className="profileLink" onClick={this.changePassword}>
                  Шинэ нууц үг
                </Link>
                <Modal
                  visible={this.state.change_password}
                  onOk={this.handlePassword}
                  onCancel={this.changePassword}>
                  {this.state.changePassword === false
                    ?
                    null
                    :
                    <div className="ticketUserContact">
                      <h3>Нууц үг сэргээх</h3>
                      <div>
                        {
                          <Input
                          name="passwordNew"
                          placeholder="Нууц үгээ оруулна уу."
                          onChange={this.changeHandler}
                          type="password"
                          />
                        }
                      </div>
                      <div>
                        {
                          <Input
                          name="passwordNew2"
                          placeholder="Нууц үгээ дахин оруулна уу."
                          onChange={this.changeHandlerPass}
                          type="password"
                          />
                        }
                      </div>
                    </div>                                
                  }
                </Modal>
              </Col>
            </Row>             
          </Card>
          {/*<div style={{ marginTop: 16 }}>
            <Card style={{ width: 720, margin: '0 auto' }}>         
              <Row className="profileLists">
                <Col span={10}>
                  <span className="profileType">Идэвхтэй</span>
                </Col>
                <Col span={4}>
                </Col>
                <Col span={10}>
                  <Switch defaultChecked onChange={onChange} style={{ float: 'right' }} />
                </Col>
              </Row>             
            </Card>   
                </div>  */}    
        </Content>
      </div>
    )
  }

}

export default AdminProfile;
