import React, {Component} from 'react';
import { Row, Col, Button, Icon } from 'antd';
import './style.css'

class KeyPadComponent extends Component {

    render() {
      return (
      <Row gutter={16} className="keyPadList">
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="1" shape="circle" onClick={e => this.props.onClick(e.target.name)}>1</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="2" shape="circle" onClick={e => this.props.onClick(e.target.name)}>2</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="3" shape="circle" onClick={e => this.props.onClick(e.target.name)}>3</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="4" shape="circle" onClick={e => this.props.onClick(e.target.name)}>4</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="5" shape="circle" onClick={e => this.props.onClick(e.target.name)}>5</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="6" shape="circle" onClick={e => this.props.onClick(e.target.name)}>6</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="7" shape="circle" onClick={e => this.props.onClick(e.target.name)}>7</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="8" shape="circle" onClick={e => this.props.onClick(e.target.name)}>8</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="9" shape="circle" onClick={e => this.props.onClick(e.target.name)}>9</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="*" shape="circle" onClick={e => this.props.onClick(e.target.name)}>*</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="0" shape="circle" onClick={e => this.props.onClick(e.target.name)}>0</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="#" shape="circle" onClick={e => this.props.onClick(e.target.name)}>#</Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>

        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn">
            <Button block name="call" shape="circle" className="makeCall" onClick={e => this.props.onClick(e.target.name)} shape="circle" >
              <Icon type="phone" />
            </Button>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className="gutter-box keyPadbtn clearBtn">
            <Button block name="CE" shape="circle"  onClick={e => this.props.onClick(e.target.name)} shape="circle" >
              <Icon type="arrow-left" />
            </Button>
          </div>
        </Col>
      </Row>
      );
    }
}


export default KeyPadComponent;
