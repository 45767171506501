import React from 'react';
import './style.css';
import { Icon } from 'antd';
import { NavLink } from 'react-router-dom';

class SiderTicketDetailComponent extends React.Component{
    
    state = {
        showMenu: false,
      };
    
    render(){
        const { showMenu } = this.state;
        return(
            <div className="sideTicketDetail" style={{ width: 256 }}>
            <h3 className="siderHeader">
                Ticket Detail
            </h3>                             
        </div>
        )
    }

};

export default SiderTicketDetailComponent;
