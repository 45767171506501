import React from 'react';
import './style.css';
import { withRouter, Link } from 'react-router-dom';
import { Icon, Row, Col, Badge, Layout, Table , Modal ,Popover, Tag, Switch, Spin} from 'antd';
import { Form, Input, Button, Checkbox, Dropdown, Tooltip, Menu, Select, message, Typography } from 'antd';
import Moment from 'react-moment';
import ResultComponent from "./ResultComponent";
import KeyPadComponent from "./KeyPadComponent";
import sourcePhone from '../../assets/icons/contacts/call.svg';
import sourceNotification from '../../assets/icons/no-notification.svg';
import TeslaLogo from '../../assets/tesla.png'
import io from 'socket.io-client';

import AgoraRTC from "agora-rtc-sdk";
import RTC_Client from './RTCAgora';

import axios from 'axios';
import image from '../../assets/blank-profile-picture-973460_640.png'
import { CONN } from '../../constant/types';
import missed from '../../assets/icons/phone-missed.svg';
import TicketList from '../../containers/Admin/Ticket/TicketShow';
const { Column } = Table;
const { Content, Sider } = Layout;
const { Paragraph } = Typography;


const { TextArea } = Input;
const { Option } = Select;

const token = localStorage.getItem('token')
const localNumber = localStorage.getItem('local_number')
let array_status=[];
let array_users =[];
const notificationData = [
  {
    key: "n-1",
    data: "Call Pro CRM хэвийн ажиллаж байгааг шалгаж байна.",
    time: "2019-10-01T17:53:49+08:00"
  },
  {
    key: "n-2",
    data: "Call Pro CRM хэвийн ажиллаж байгааг deploy хийхээс өмнө шалгах хэрэгтэй.",
    time: "2019-10-01T17:53:49+08:00"
  },
  {
    key: "n-3",
    data: "ReactJS болон UI нь Ant Framework дээр бүтээсэн.",
    time: "2019-10-01T17:53:49+08:00"
  },
  {
    key: "n-4",
    data: "Жинхэнэ дата ирэх үед Redux хийвэл их зүгээр.",
    time: "2019-10-01T17:53:49+08:00"
  },
  {
    key: "n-5",
    data: "CSS нь илүү түгээмэл учир CSS ашиглав.",
    time: "2019-10-01T17:53:49+08:00"
  }
]

class HeaderComponent extends React.Component {

  playSound(sound) {
    var audio = document.getElementById('playAudio');
    if (sound == 'play') {
      audio.play();
    } else {
      audio.pause();
    }
  }

  showModal = () => {
    this.setState({
      visibleTicketModal: true,
    });
  };

  //setState үйлдлийг хийх
  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleOk = () => {

    if (this.state.ticket_title != '' && this.state.ticket_owner_id && this.state.ticket_status_id) {

      const config = {
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      };

      const me = this;
      const ticket_code = this.state.auto_ticket.code;

      const data = {
        title: this.state.ticket_title,
        owner_id: this.state.ticket_owner_id,
        status_id: this.state.ticket_status_id,
        topic_id: this.state.ticket_topic_id,
        priority_id: this.state.ticket_priority_id,
        user_id: this.state.auto_ticket.customer.id
      }
      const data2 = {
        ticket_id: this.state.auto_ticket.id,
        message: this.state.ticket_reply,
        level: 0,
        is_note: 0,
        is_reply: 1
      }

      message.info("Уншиж байна.");

      axios.post(`${CONN}/ticket_comment/mobile`, data2, config)
        .then(response => {
          console.log('TICKETCOMMENT', response);
        })
        .catch(err => {
          console.log('AXIOS ERRORRRRR2', err);
        })

      axios.put(`${CONN}/tickets/${ticket_code}`, data, config)
        .then(response => {
          console.log('TICKETPUT', response);

          me.props.history.push({
            pathname: `/admin/ticket/show/${ticket_code}`,
            state: { code: ticket_code }
          });

          message.success("Хадгалагдлаа.");
          this.setState({ticket_title: '', ticket_owner_id: '', ticket_status_id: '', ticket_topic_id: '', ticket_priority_id: '', ticket_reply: ''})        })
        .catch(err => {
          console.log('AXIOS ERRORRRRR', err);
        })

      this.setState({ loadingTicketModal: false, visibleTicketModal: false });

      // this.setState({ visibleTicketModal: true });
      // setTimeout(() => {
      //   this.setState({ loadingTicketModal: false, visibleTicketModal: false });
      // }, 3000);
    } else {
      alert('Та талбаруудаа бөглөнө үү');
    }
  };

  handleCancel = () => {
    this.setState({ visibleTicketModal: false });
  };

  fetchNotification = () =>{
    const config = {
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    };

    setTimeout(() => {
      axios
      .get(`https://crm.onlime.mn/backend/api/v1/notification`, config)
      .then(response =>{
        this.setState({notification: response.data.data,
          notification_count: response.data.meta.total
        })
      })
      console.log()
    }, 60000);

    
  }

  //Хэрэгцээт мэдээллүүдийг API-аас авах
  fetchTicketInfo = () => {
    const config = {
      headers: {
        'Authorization': `Bearer  ${token}`
      }
    };

    this.setState({statuses: JSON.parse( localStorage.getItem('statuses'))});
     this.setState({topics: JSON.parse( localStorage.getItem('topics'))});
     this.setState({priorities: JSON.parse( localStorage.getItem('priorities'))});
     this.setState({sources: JSON.parse( localStorage.getItem('sources'))});
     this.setState({departments: JSON.parse( localStorage.getItem('departments'))});
     this.setState({users: JSON.parse( localStorage.getItem('users'))});

    //Хэрэглэгчдийн мэдээллийг авах
    /*axios
      .get(`${CONN}/users`, config)
      .then(response => {
        console.log('USERS', response);
        this.setState({ users: response.data.data })
        //console.log(this.state.users)
      })*/
/*
    //Төлөвийн мэдээллийг авах
    axios
      .get(`${CONN}/ticket_statuses`, config)
      .then(response => {
        this.setState({ statuses: response.data })
        //console.log(this.state.statuses)
      })
    //Төрөлийн мэдээллийг авах
    axios
      .get(`${CONN}/ticket_topics`, config)
      .then(response => {
        console.log('TOPICS', response.data)
        this.setState({ topics: response.data })
        //console.log(this.state.topics)
      })
    //Түвшний мэдээллийг авах
    axios
      .get(`${CONN}/ticket_priorities`, config)
      .then(response => {
        this.setState({ priorities: response.data })
        //console.log(this.state.priorities)
      })*/
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  timer = () => {
    setInterval(() => {
      const { seconds, minutes } = this.state

      if (seconds < 59) {
        this.setState(({ seconds }) => ({
          seconds: seconds + 1
        }))
      } else {
        this.setState({
          seconds: 0,
          minutes: minutes + 1
        })
      }
    }, 1000)
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };




  start = () => {
    this.setState({
      selectedRowKeys: [],
      loading: false,
    });
  };

  componentDidMount() {

    var aus = null;
    var vis = null;
    var me = this;

    AgoraRTC.getDevices(function (devices) {
      let videoHtml = "";
      let audioHtml = "";

      console.log('DEVICES', devices);
      devices.forEach(function (item) {
        if (item.kind === "audioinput") {
          // audioHtml +=
          //     "<option value=" + item.deviceId + ">" + item.label + "</option>";
          aus = item.deviceId;


          me.setState({
            aus: aus
          });
          console.log('AUS', aus);
        }
        if (item.kind === "videoinput") {
          // videoHtml +=
          //     "<option value=" + item.deviceId + ">" + item.label + "</option>";
          vis = item.deviceId;


          me.setState({
            vis: vis
          });
          console.log('VIS', vis);
        }
      });
      // $("#videoDevice").html(videoHtml);
      // $("#audioDevice").html(audioHtml);
      // resolve();
    });

    const socketUrl = 'https://socket.callpro.mn';
    const socket = io(socketUrl, {
      path: '/callpro',
      secure: true
    });

    this.setState({
      socket: socket
    });

    socket.on('connect', function () {
      console.log('Socket CONNECTED...', socket);
      socket.emit('room', localNumber);
    });

    socket.on('message', msg => {
      console.log('SocketWeb:MESSAGE:', msg);

      if (msg.type == "incoming") {
        console.log('SocketWeb:INCOMING', JSON.stringify(msg));

        this.setState({
          inBoundToken: msg.channelToken,
          inBoundChannel: msg.agoraChannel,
          oponentNumber: msg.callerNumber,
          inBoundAppId: msg.appId,
          inBoundHash: msg.hash
        })

        if (msg.callType == 'sip') {
          console.log('InBound:INCOMING, Type:SIP');

          this.setState({
            isRinging: true,
            incomingCallUsername: 'Хайж байна...'
          });
          this.playSound('play');


          var me = this;
          axios({
            method: 'GET',
            url: `${CONN}/customer_search/${this.state.oponentNumber}`,
            headers: {
              'Authorization': `Bearer  ${token}`
            }
          })
            .then(function (response) {
              var r = response.data.data;
              console.log('IT IS MEEEEEEEEEEEE', r)

              axios
              .get(`${CONN}/ticket_customer/${r[0].id}?page=1`, config)
              .then((response) => {
                console.log(response)
                  {
                    let tickets = response.data.data;
                    let ds = []
        
                    tickets.map((ticket) => {
                      let assignees = '';
                        ticket.assign.map(assignee=>{
                          assignees = `${assignees} ${assignee.name};`
                        })
                      let newRow = {
                        id: ticket.id,
                        code: ticket.code,
                        title: ticket.title,
                        customer: ticket.customer !== null ? ticket.customer.name: '-',
                        owner: ticket.owner !== null ? ticket.owner.name: '-',
                        topic: ticket.topic !== null ? ticket.topic.name : '-',
                        status: ticket.status !== null ? ticket.status.name : '-',
                        created_at: ticket.created_at,
                        deadline: ticket.deadline,
                        assignee: assignees
                      };
                      
                      ds.push(newRow);
                    });
        
                    me.setState({
                      Source: ds
                    })
                  }
              })

              me.setState({
                incomingCallUsername: r.firstname + ' ' + r.lastname
              })
            })
            .catch(function (error) {
              console.log(error);
            })
            .finally(function () {
              // always executed
            });

          if (aus == null) {
            console.log('AUDIO RESOURCE NOT FOUND');
          }

        } else if (msg.callType == 'rtc') {
          console.log('InBound:INCOMING, Type:RTC');
        }
      }

      if (msg.type == "bye") {
        console.log('SocketWeb:BYE', JSON.stringify(msg));

        this.setState({
          isRinging: false
        });
        this.playSound('stop');
        this.endCall();
        // stopSeconds();

        // socket.emit('leave', msg.channelName);

        console.log('InBound:BYE');
      }
    });

    socket.on('disconnect', function () {
      console.log('Socket DISCONNECTED!!!');
    });

    var demo = new RTC_Client('callpro')
    demo.initialize();
    var token = localStorage.getItem('token')
    const config = {
      headers: {
        'Authorization': `Bearer  ${token}`
      }
    };

    
    this.fetchNotification();
    this.fetchTicketInfo();
    this.fetchUser();
  }
  
  onChangeColumnCheckbox(e) {
    // console.log(`checked = ${e.target.checked}`);
    // console.log(`value = ${e.target.value}`);

    let val = e.target.value;
    let checked = e.target.checked;
    let isAvailableAdd = true;

    const { columns } = this.state;

    columns.map((col) => {
      if (col.key == val) {
        isAvailableAdd = false;
      }
    })

    if (isAvailableAdd && checked) {
      let newColumn = {
        dataIndex: val,
        key: val,
        title: ''
      }

      switch (val) {
        case 'code':
          newColumn.title = 'Дугаар'
          break;
        case 'customer':
          newColumn.title = 'Харилцагч'
          break;
        case 'title':
          newColumn.title = 'Тайлбар'
          break;
        case 'owner':
          newColumn.title = 'Хариуцагч'
          break;
        case 'topic':
          newColumn.title = 'Төрөл'
          break;
        case 'status':
          newColumn.title = 'Төлөв'
          break;
        case 'assignee':
          newColumn.title = 'Оролцогч'
          break;
        case 'created_at':
          newColumn.title = 'Огноо'
          break;
        case 'deadline':
          newColumn.title = 'Дуусах хугацаа'
          break;

        default:
          break;
      }

      this.setState({
        columns: [...columns, newColumn]
      })
    }

    if(!checked) {
      let cols = []
      columns.map((col)=>{
        if(col.key != val){
          cols.push(col);
        }
      })

      this.setState({
        columns: cols
      })
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      code: 0,
      isRinging: false,
      incomingCallUsername: 'Шинэ хэрэглэгч',
      visibleTicketModal: false,
      loadingTicketModal: false,
      topics: [],
      statuses: [],
      priorities: [],
      users: [],
      selectedRowKeys: [],
      showMenu: false,
      showCallWrap: false,
      showProfileWrap: false,
      makeCall: false,
      notifications: notificationData,
      showNotification: false,
      result: "",
      auto_ticket: null,
      minutes: 0,
      seconds: 0,
      searchValue: '',
      loading: false,
      message: '',
      results: {},
      pager: 1,
      name: '',
      email: '',
      Source: [],
      notification:[],
      notification_count: '',
      count: 20,
      SearchTicket: '',
      token: localStorage.getItem('token'),
      departments:[],
      searching: false,
      columns: [
        {
          title: "Subject",
          dataIndex: 'all',
          key: 'all',
          render: all =>(
            <div onClick={()=>(this.props.history.push({
              pathname: `/admin/ticket/show`}), localStorage.setItem("code", all.code), window.location.reload())} style={{cursor:'pointer'}}>
            <Row>
              {
                all.is_new == 0
                ? <span  style={{fontSize: '16px', fontWeight:'bold'}}>
                  <span style={{color: "#1890ff"}}>
                    {all.code}
                  </span> - {all.title.substring(0,80)}
                </span>
                :<Badge dot><span onClick={()=>(this.props.history.push({
                  pathname: `/admin/ticket/show`}), localStorage.setItem("code", all.code))} style={{fontSize: '16px', fontWeight:'bold', cursor:'pointer'}}><span style={{color: "#1890ff"}}>
            {all.code}
          </span> - {all.title.substring(0,80)}
                </span>
                </Badge>
              }

              
              <span style={{float: 'right'}}>
              {
                all.status != null
                ?
                <Tooltip title={all.status.name}>
                  <Icon type='right-circle' style={{fontSize: '16px', color: all.status.color, backgroundColor: all.status.color, borderRadius:"50%"}}></Icon>
                </Tooltip>
                :
                <Tooltip title='тодорхойгүй'>
                  <Icon type='right-circle' style={{fontSize: '16px', color: all.status.color, backgroundColor: all.status.color, borderRadius:"50%"}}></Icon>
                </Tooltip>
              }
              {
                all.deadline == null
                ? <Tag style={{marginLeft: '10px'}}>Дуусах хугацаа</Tag>
                : <Tag color='gold' style={{marginLeft: '10px'}}>{all.deadline}</Tag>
              }

              </span>
              <span style={{marginRight: '10px', float: 'right'}}>
              {
                all.source != null
                ?
                <Tooltip title={all.source.name}>
                  <Icon type={all.source.icon} theme='filled' style={{fontSize: '16px', color: 'rgba(89, 89, 89, 0.7)'}}></Icon>
                </Tooltip>
                :
                <Tooltip title='Тодорхойгүй'>
                  <Icon type='question-circle' theme='filled' style={{fontSize: '16px', color: 'rgba(89, 89, 89, 0.7)'}}></Icon>
                </Tooltip>
              }
              </span>
            </Row>
            <Row>
              {
                all.reply.length == 0
                ?
                <span><Icon type="message"/> </span>
                :
                <span><Icon type="message"/> {all.reply[all.reply.length-1].message.substring(0, 150)} - {all.reply.length}</span>
              }
            </Row>
            <Row style={{marginTop:'5px'}}>
              {
                all.topic == null
                ?
                <Tag>Төрөл</Tag>
                :
                <Tag color="#f50">{all.topic.name}</Tag>
              }
              {
                all.priority == null
                ?
                <Tag>Зэрэглэл</Tag>
                :
                <Tag color={all.priority.color}>{all.priority.name}</Tag>
              }
              <span style={{float:'right'}}>
                {
                  all.customer == null
                  ? <Tag>Харилцагч</Tag>
                  : <Tag color="orange">{all.customer.name==null?all.customer.mobile:all.customer.name}</Tag>
                } 
                {
                  all.owner == null
                  ? <Tag>Хариуцагч</Tag>
                  : <Tag color="blue">{all.owner.name}</Tag>
                }
                {
                  all.department == null
                  ? <Tag>Хэлтэс</Tag>
                  : <Tag color="green">{all.department.name}</Tag>
                }
              </span>

            </Row>
            </div>
          )
        },
      ],
      dataSource: [],
      visible: false
    };
    this.cancel = '';
    this.base = this.state;
  }


  toggleMenu() {
    this.setState({
      showMenu: !this.state.showMenu
    });
  };
  
  onCallWrap = () => {
    this.setState({
      showCallWrap: !this.state.showCallWrap
    });
  };

  onClick = button => {

    if (button === "call") {
      this.calculate()
    }

    else if (button === "C") {
      this.reset()
    }
    else if (button === "CE") {
      this.backspace()
    }

    else {
      this.setState({
        result: this.state.result + button
      })
    }
  };

  calculate = () => {
    try {
      this.setState({
        result: (eval(this.state.result) || "") + ""
      })
    } catch (e) {
      this.setState({
        result: "error"
      })

    }
  };

  onProfileWrap = () => {
    this.setState({
      showProfileWrap: !this.state.showProfileWrap
    })
  };

  calculate = () => {
    try {
      this.setState({
        makeCall: true,
        showCallWrap: false,
        result: (eval(this.state.result) || "") + ""
      })
    } catch (e) {
      this.setState({
        result: "error"
      })
    }
  };
  
  tableChange = (value) =>{
    this.setState({
      pager: value.current,
      count: value.pageSize
    })
  }

  openNotification = () => {
    this.setState({
      showNotification: !this.state.showNotification,
    });
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
    })
  }

  reset = () => {
    this.setState({
      result: ""
    })
  };

  backspace = () => {
    this.setState({
      result: this.state.result.slice(0, -1)
    })
  };

  endCall = () => {
    this.setState({
      makeCall: false,
      isRinging: false,
      result: [],
      minutes: 0,
      seconds: 0,
    })
    
    this.setState({ticket_title: '', ticket_owner_id: '', ticket_status_id: '', ticket_topic_id: '', ticket_priority_id: '', ticket_reply: ''})

    clearInterval(this.timer);

    var demo = new RTC_Client('callpro');
    demo.hangUp('inbound', this.state.inBoundChannel, this.state.inBoundHash, this.state.socket)
  }

  acceptCall = () => {
    var demo = new RTC_Client('callpro');
    if (this.state.aus) {
      var agoraClient = demo.answer('sip', this.state.inBoundAppId, this.state.inBoundChannel, this.state.inBoundToken, this.state.inBoundHash, this.state.socket, this.state.aus, this.state.vis)
      this.playSound('stop');

      this.setState({
        makeCall: true,
        isRinging: false
      })

      this.timer();
    } else {
      alert('Audio төхөөрөөмж олдсонгүй')
    }
  }

  transferCall = () => {
    alert('Хийж амжаагүй байна!');
  }

  openNewTicketModalByIncomingCall = () => {
    var me = this;

    const data = {
      type: 'mobile',
      mobile: this.state.oponentNumber
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
    axios.post(`${CONN}/ticket_auto`, data, {
      headers: headers
    }).then(function (response) {
      console.log('AUTOTICKET', response);

      me.setState({
        auto_ticket: response.data.data
      })

      console.log('AUTOTICKET1111', me.state.auto_ticket);
    }).catch(function (error) {
      console.log('AXIOS ERR', error);
    }).finally(function () {

    });

    this.showModal();
  }

  logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('local_number');
    localStorage.removeItem('user_id');
    localStorage.removeItem('priorities');
    localStorage.removeItem('persist:root');
    localStorage.removeItem('user_name');
    localStorage.removeItem('departments');
    localStorage.removeItem('statuses');
    localStorage.removeItem('topics');
    localStorage.removeItem('users');
    localStorage.removeItem('sources');
    localStorage.removeItem('sb_id');


    this.props.history.push({
      pathname: '/',
    });
  }
  search = (value) =>{
    this.setState({searching: true})
    this.setState({searchValue:value})
    const config = {
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    };
    console.log('url:', `${CONN}/ticket_search/${value}`)
    axios
    .get(`${CONN}/ticket_search/${value}`, config)
    .then((res) => {
      console.log(res)
      this.setState({tickets: res.data.data})
      this.setState({visible: true});
      this.setState({searching: false})
      if(res.data.meta.last_page > 1){
        for(var i=2; i<=res.data.meta.last_page; i++){
          axios
            .get(`${CONN}/tickets?page=${i}`, config)
            .then(response =>{
              this.setState({
                tickets: [...this.state.tickets, ...response.data.data],
                loading: false,
                refreshing: false,
              })
              console.log("tic", response.data.data)
              //filter.push(response.data.data.owner.name)
              let tickets = this.state.tickets;
              let ds = []

              tickets.map((ticket) => {
                let assignees = '';
                ticket.assign.map(assignee=>{
                  assignees = `${assignees} ${assignee.name},`
                })
                let newRow = {
                  all: ticket,
                  id: ticket.id,
                  code: ticket.code,
                  title: ticket.title,
                  customer: ticket.customer !== null ? ticket.customer.firstname: '-',
                  owner: ticket.owner !== null ? ticket.owner.name: '-',
                  topic: ticket.topic !== null ? ticket.topic.name : '-',
                  status: ticket.status !== null ? ticket.status.name : '-',
                  created_at: ticket.created_at,
                  deadline: ticket.deadline,
                  assignee : assignees
                };
                
                ds.push(newRow);
              });

              this.setState({
                dataSource: ds
              })
                    })
                  }}else{
                    let tickets = this.state.tickets;
                    let ds = []
    
                    tickets.map((ticket) => {
                      let assignees = '';
                ticket.assign.map(assignee=>{
                  assignees = `${assignees} ${assignee.name},`
                })
                      let newRow = {
                        all: ticket,
                        id: ticket.id,
                        code: ticket.code,
                        title: ticket.title,
                        customer: ticket.customer !== null ? ticket.customer.firstname: '-',
                        owner: ticket.owner !== null ? ticket.owner.name: '-',
                        topic: ticket.topic !== null ? ticket.topic.name : '-',
                        status: ticket.status !== null ? ticket.status.name : '-',
                        created_at: ticket.created_at,
                        deadline: ticket.deadline,
                        assignee: assignees
                      };
                      
                      ds.push(newRow);
                    });
    
                    this.setState({
                      dataSource: ds
                    })
                  }
    })
    .catch(error =>{
      console.log('error', error)
    })
  }
  visible = () =>{
    this.setState({visible: !this.state.visible})
  }

  fetchUser = () => {
    var token = localStorage.getItem('token');
        const config = {
        headers: {
          'Authorization': `Bearer  ${token}`
        }
      };
      axios
      .get(`${CONN}/user_profile`, config)
      .then((res) => {
        console.log('res.data', res)
        this.setState({
          name: res.data.user.name,
          email: res.data.user.email,
      })
      })
  }

  ticketStats = (value) =>{
    console.log(value)

    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          status_id: value,
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.search(this.state.searchValue);
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  ticketOwners = (value, label) =>{
    console.log(value)
    this.setState({ticketOwners: label.props.label.name})

    console.log(this.state.selectedRowKeys.length)

    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          owner_id: value,
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.setState({ticketOwners:''})
        this.search(this.state.searchValue);
      })
      .catch(err => {
        console.log(err)
      })
    }
    
  }

  ticketDepartments = (value) =>{
    console.log(value)

    console.log(this.state.selectedRowKeys.length)

    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          department_id: value,
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.setState({ticketOwners:''})
        this.search(this.state.searchValue);
      })
      .catch(err => {
        console.log(err)
      })
    }
    
  }

  ticketTopics = (value) =>{
    console.log(value)

    console.log(this.state.selectedRowKeys.length)

    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          topic_id: value,
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.setState({ticketOwners:''})
        this.search(this.state.searchValue);
      })
      .catch(err => {
        console.log(err)
      })
    }
    
  }

  ticketPriorities = (value) =>{
    console.log(value)

    console.log(this.state.selectedRowKeys.length)

    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          priority_id: value,
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.setState({ticketOwners:''})
        this.search(this.state.searchValue);
      })
      .catch(err => {
        console.log(err)
      })
    }
    
  }

  ticketAssignees =(value) =>{
    console.log(value)

    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      console.log(this.state.tickets[code+page].id, value)
      axios({
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        url: `${CONN}/ticket_assignee`,
        //url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          ticket_id: this.state.tickets[code+page].id,
          user_id: value
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.search(this.state.searchValue);
      })
      .catch(err => {
        console.log(err)
      })
    }

  }

  removeAssignee =()=>{
    console.log('yes')
    for(var i=0; i<=this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      if(this.state.tickets[code+page] != null){
      this.state.tickets[code+page].assign.map(assign=>{
        axios({
          method: 'delete',
          headers: {'Content-Type': 'application/json'},
          url: `${CONN}/ticket_assignee/${assign.id}`,
          //url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
          headers: {
            'Authorization': `Bearer  ${this.state.token}`
          }
        }).then(res => {
          console.log(res)
          this.search(this.state.searchValue);
        })
        .catch(err => {
          console.log(err)
        })
      })}
    }
  }
  sendMessage = () =>{
    var comment;
    var note;
    if(this.state.toogle==true){
      comment=false;
      note=true;
    }else{
      comment=true;
      note=false;
    }
    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        url: `${CONN}/ticket_comment/1`,
        //url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          "ticket_id": this.state.tickets[code+page].id,
          "message": this.state.message,
          "is_note": comment,
          "is_reply": note,
          "level": 0,
          "quick_response_id": ""

        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.search(this.state.searchValue);
      })
      .catch(err => {
        console.log(err)
      })
    }
  }


  render() {

    const Settings = (
      <div>
        <ul>
          <li><input type="checkbox" disabled checked={true} onChange={(e) => this.onChangeColumnCheckbox(e)} value="code" /> Дугаар</li>
          <li><input type="checkbox" disabled checked={true} onChange={(e) => this.onChangeColumnCheckbox(e)} value="customer" /> Харилцагч</li>
          <li><input type="checkbox" disabled checked={true} onChange={(e) => this.onChangeColumnCheckbox(e)} value="title" /> Тайлбар</li>
          <li><input type="checkbox" disabled checked={true} onChange={(e) => this.onChangeColumnCheckbox(e)} value="owner" /> Хариуцагч</li>
          <li><input type="checkbox" disabled checked={true} onChange={(e) => this.onChangeColumnCheckbox(e)} value="status" /> Төлөв</li>
          <li><input type="checkbox" onChange={(e) => this.onChangeColumnCheckbox(e)} value="topic" /> Төрөл</li>
          <li><input type="checkbox" onChange={(e) => this.onChangeColumnCheckbox(e)} value="assignee" /> Оролцогч</li>
          <li><input type="checkbox" onChange={(e) => this.onChangeColumnCheckbox(e)} value="created_at" /> Огноо</li>
          <li><input type="checkbox" onChange={(e) => this.onChangeColumnCheckbox(e)} value="deadline" /> Дуусах хугацаа</li>
        </ul>
      </div>
    );

    const { minutes, seconds } = this.state

    const { selectedRowKeys } = this.state;


    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const Actions = (
      <div style={{width: '150px'}}>
        {
          this.state.statuses.map(stats =>(
            <Button block type="primary" style={{ marginBottom: 10 }} onClick={()=>this.ticketStats(stats.id)}>
              {stats.name}
            </Button>
          ))
        }
          
      </div>
    )

    this.fetchNotification()

    const menuList = [
      {
        key: "hLink_1",
        location: "chat",
        name: "Ажлын чат",
      }, {
        key: "hLink_2",
        location: "knowledge",
        name: "Мэдлэгийн сан",
      }, {
        key: "hLink_3",
        location: "report",
        name: "Тайлан",
      }, {
        key: "hLink_4",
        location: "members",
        name: "Гишүүд",
      }, {
        key: "hLink_5",
        location: "Users",
        name: "Хэрэглэгчид",
      }];

    const Owner = (
      <Select
        style={{ width: '100%' }}
        autoFocus={true}
        maxTagCount={1}
        value={this.state.ticketOwners}
        placeholder="Add members"
        onChange={this.ticketOwners}
        optionLabelProp="label"
      >
        {
          this.state.users != null
          ?
          this.state.users.map(d => (
            <Option value={d.id} label={d}>
          <span className="select-Assistname">{d.name}</span>
        </Option>
          ))
          :
          null
        }
      </Select>
    )

    const Assignee =(
      <div>
      <Select
        //mode="multiple"
        style={{marginTop: '10px', width: '100%', alignText: "center"}}
        autoFocus={true}
        maxTagCount={1}
        placeholder="Add members"
        value={"Оролцогч нэмэх"}
        //onDeselect={handleDeselect}
        onSelect={this.ticketAssignees}
        optionLabelProp="label"
      >
        {
          this.state.users != null
          ?
          this.state.users.map(d => (
            <Option value={d.id} label={d.name}>
          <span className="select-Assistname">{d.name}</span>
        </Option>
          ))
          :
          null
        } 
      </Select>
      <Button onClick={this.removeAssignee} style={{marginTop: '10px', width: '100%', alignText: 'left'}}><Icon type='usergroup-delete'/> Оролцогчдийг хасах</Button>
      </div>
    )

    const Message = (
      <div>
        <Switch checkedChildren='Хариулт' unCheckedChildren='Тэмдэглэл' defaultChecked onChange={()=>this.setState({toogle: !this.state.toogle})} style={{width:'90px', marginBottom:'10px'}}/>
        <Input.TextArea name='message' onChange={this.changeHandler} style={{marginBottom:'10px'}}/>
        <Button onClick={this.sendMessage} size='small' style={{alignSelf:'right', marginBottom: '10px'}}>Илгээх</Button>
      </div>
    )

    const onChangePriority1 = (
      <Menu>
        {this.state.priorities != null
        ?
          this.state.priorities.map( stats=>(
            <Menu.Item>
            <Button block block type="link"
              onClick={() => this.ticketPriorities(stats.id)
              }>
              {stats.name}
            </Button>
          </Menu.Item>
          ))
          :
          null
        }
      </Menu>
    );

    const onChangeTopic1 = (
      <Menu>
        {
          this.state.topics.map( stats=>(
            <Menu.Item>
            <Button block block type="link"
              onClick={() => this.ticketTopics(stats.id)
              }>
              {stats.name}
            </Button>
          </Menu.Item>
          ))
        }
      </Menu>
    );
    const onChangeDepartment1 = (
      <Menu>
       {
         this.state.departments!=null
         ?
          this.state.departments.map( deps=>(
            <Menu.Item>
            <Button block block type="link"
              onClick={() => { 
                this.ticketDepartments(deps.id)
              }
              }>
              {deps.name}
            </Button>
          </Menu.Item>
          ))
          :
          null
        }
      </Menu>
    );

    const Department = (
      <Dropdown overlay={onChangeDepartment1}>
      <Button block block className="sideDropdown">
      {
        this.state.department_name
      } 
        <Icon type="down" />
      </Button>
    </Dropdown> 
    )

    const Topic =(
      <Dropdown overlay={onChangeTopic1}>
        <Button block block className="sideDropdown">
        {
          this.state.topic_name
        } 
          <Icon type="down" />
        </Button>
      </Dropdown> 
    )

    const Priority =(
      <div>
      <Dropdown overlay={onChangePriority1}>
        <Button block block className="sideDropdown">
        {
          this.state.priority_name
        } 
          <Icon type="down" />
        </Button>
      </Dropdown> 
      </div>
    )

    const titleDownload = <span>Татаж авах</span>;
    const Download = (
      <div>
        <Button block type="primary" style={{ marginBottom: 10 }} >
          CSV формат
        </Button>
        <Button block type="primary" >
          JSON формат
        </Button>
      </div>
    );

    return (
      <Layout>
      {
                this.state.makeCall === true
                  ?
                  <div className="acceptWrap posIncoming" style={{heigth:'200px'}}>
                    <div className="topCallArrow"></div>
                    <Row>
                      <Col span={4}>
                        <img src={sourcePhone} alt="" className="callIcon" />
                      </Col>
                      <Col span={20}>
                        <p>{this.state.incomingCallUsername}</p>
                        <span className="number">{this.state.result}</span>
                        <div className="ontime">{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>
                      </Col>
                    </Row>
                    <ul className="btnTypes mt-20">
                      <li className="left">
                        <Button block onClick={this.transferCall}>
                          Шилжүүлэх
                          </Button>
                      </li>
                      <li className="right">
                        <Button block className="cancelBtn" onClick={this.endCall}>
                          Таслах
                          </Button>
                      </li>
                    </ul>
                    <div className="otherTypes mt-20">
                      <Button block type="dashed" block>
                        <Icon type="plus" /> Захиалга үүсгэх
                          </Button>
                      <Button block type="dashed" onClick={this.openNewTicketModalByIncomingCall} style={{ marginTop: 12 }} block>
                        <Icon type="plus" /> Тикет үүсгэх
                          </Button>
                    </div>
                    <div style={{marginTop: '140px'}}>
                      <Table dataSource={this.state.Source} onChange={this.tableChange} rowSelection={rowSelection} columns={this.state.columns} scroll={{ y: 240 }} size="small" className="orderTable" pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50', '100'] }} />
                    </div>
                  </div>
                  :
                  null
              }
      <div>
        <audio id="playAudio" hidden src="/ring.mp3" type="audio/mpeg" loop></audio>
        <Modal
          visible={this.state.visibleTicketModal}
          title={this.state.oponentNumber + " дугаартай хэрэгч дээр үүссэн тикет - #" + (this.state.auto_ticket && typeof this.state.auto_ticket.code !== undefined ? this.state.auto_ticket.code : '----------')}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button block key="back" onClick={this.handleCancel}>
              Болих
            </Button>,
            <Button block key="submit" type="primary" loading={this.state.loadingTicketModal} onClick={this.handleOk}>
              Хадгалах
            </Button>,
          ]}
        >
          <div className="form-group">
            <Input
              value={this.state.ticket_title}
              name="ticket_title"
              onChange={this.changeHandler}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Тикетийн гарчиг"
            />
          </div>
          <div className="form-group">
            <TextArea
              value={this.state.ticket_reply}
              name="ticket_reply"
              onChange={this.changeHandler}
              rows={4}
              placeholder="Тайлбар текст"
            />
          </div>
          <div className="form-group">
            <Select
            value={this.state.ticket_owner_id}
              onChange={value => {
                this.setState({
                  ticket_owner_id: value
                })
              }}
              defaultValue="--- Хариуцагч сонгох ---" style={{ width: 250 }}>
              {
                this.state.users != null
                ?
                this.state.users.map(stats => (
                  <Option value={stats.id}>
                    {stats.name}
                  </Option>
                ))
                :
                null
              }
            </Select>
          </div>
          <div className="form-group">
            <Select
              value={this.state.ticket_topic_id}
              onChange={value => {
                this.setState({
                  ticket_topic_id: value
                })
              }}
              defaultValue="--- Сэдэв сонгох---" style={{ width: 250 }}>
              {
                this.state.topics.map(stats => (
                  <Option value={stats.id}>
                    {stats.name}
                  </Option>
                ))
              }
            </Select>
          </div>
          <div className="form-group">
            <Select
              value={this.state.ticket_status_id}
              onChange={value => {
                this.setState({
                  ticket_status_id: value
                })
              }}
              defaultValue="--- Төлөв сонгох---" style={{ width: 250 }}>
              {
                this.state.statuses.map(stats => (
                  <Option value={stats.id}>
                    {stats.name}
                  </Option>
                ))
              }
            </Select>
          </div>
          <div className="form-group">
            <Select
              value={this.state.ticket_priority_id}
              onChange={value => {
                this.setState({
                  ticket_priority_id: value
                })
              }}
              defaultValue="--- Зэрэглэл сонгох---" style={{ width: 250 }}>
              {
                this.state.priorities.map(stats => (
                  <Option value={stats.id}>
                    {stats.name}
                  </Option>
                ))
              }
            </Select>
          </div>
        </Modal>
        <Modal
          visible={this.state.visible}
          onOk={this.visible}
          onCancel={this.visible}
          width='auto'
        ><Layout style={{ paddingTop: 48, paddingLeft: 15, paddingRight: 15, paddingBottom: 15, backgroundColor: '#ffffff' }}>
<Row className="ticketControl" style={{backgroundColor: 'rgb(255, 255, 255)'}}>
          <Col span={5}>
            <ul className="ticketLeft">
              <li>
              {/*console.log(this.state.priority_time, "1", this.state.created_at, "2", this.state.priority_time + this.state.created_at)*/}
                <Popover placement="bottomLeft" title={titleDownload} content={Download} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="filter" />
                  </span>
                </Popover>
              </li>
              <li>
                <span className="ticketCtlBtn" onClick={this.start} disabled={!hasSelected} >
                  <Icon type="sync" />
                </span>
              </li>
              <li>
                <Popover placement="bottom" title={titleDownload} content={Download} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="download" />
                  </span>
                </Popover>
              </li>
             {/* <li>
                <a href="#" className="ticketCtlBtn">
                  <Icon type="more" />
                </a>
              </li>*/}
            </ul>
          </Col>
          <Col span={19}>
            <ul className="ticketRight">
              <li>
                <Button block className="ticketNew" onClick={this.newTicket}>
                  <Icon type="plus" />
                  Шинэ
                </Button>
              </li>
{              /*<li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Багана өөрчлөх'>
                <Popover placement="bottom" title={titleSettings} content={Settings} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="table" />
                  </span>
                </Popover>
                </Tooltip>
              </li>*/}
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Төлөв өөрчлөх'>
                <Popover placement="bottom" title='Төлөв өөрчлөх' content={Actions} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="check-circle"/>
                  </span>
                </Popover>
                </Tooltip>
                </li>
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Хариуцагч өөрчлөх'>
                <Popover placement="bottom" title='Хариуцагч өөрчлөх' content={Owner} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="user-add"/>
                  </span>
                </Popover>
                </Tooltip>
              </li>
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Оролцогчид өөрчлөх'>
                <Popover placement="bottom" title='Оролцогчид өөрчлөх' content={Assignee} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="usergroup-add" onclick/>
                  </span>
                </Popover>
                </Tooltip>
              </li>
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Хариу'>
                <Popover placement="bottom" title='Хариу илгээх' content={Message} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="rollback" onclick/>
                  </span>
                </Popover>
                </Tooltip>
              </li>
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Төрөл'>
                <Popover placement="bottom" title='Төрөл өөрчлөх' content={Topic} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="bulb" onclick/>
                  </span>
                </Popover>
                </Tooltip>
              </li>
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Зэрэглэл'>
                <Popover placement="bottom" title='Зэрэглэл өөрчлөх' content={Priority} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="ordered-list" onclick/>
                  </span>
                </Popover>
                </Tooltip>
              </li>
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Хэлтэс'>
                <Popover placement="bottom" title='Хэлтэс өөрчлөх' content={Department} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="apartment" onclick/>
                  </span>
                </Popover>
                </Tooltip>
              </li>
              <li>
                {hasSelected ? `Сонгож авсан ${selectedRowKeys.length} тикет` : ''}
              </li>
            </ul>
          </Col>
        </Row>
        <Content style={{ backgroundColor: '#FFF', height: '100%' }}>
          <Table dataSource={this.state.dataSource} onChange={this.tableChange} rowSelection={rowSelection} columns={this.state.columns} size="small" className="orderTable" pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50', '100'] }} />
        </Content>
        <Content>
         
        </Content>
      </Layout></Modal>

        <header className="headerCom">
          <Row>
            <Col span={4}>
              <div>
                <Link >
                  <img src={TeslaLogo} alt="" className="companyLogo" />
                </Link>
                <Link >
                  <h3 className="companyTitle">CRM Onlime</h3>
                </Link>
              </div>
            </Col>  
            <Col span={9} style={{float:'right'}}>
              <div style={{float:'right'}}>
                <img src={image} alt="" onClick={this.onProfileWrap} className="userProfileImg"/>
                  {
                    this.state.showProfileWrap === true
                    ?
                    <div className="topProfile" style={{marginTop: '48px'}}>
                      <Row className="topProfileWrap">
                        <Col span={8}><img src={image} alt="" className="topProfileImg" /></Col>
                        <Col span={16} className="topProfileInfo">
                          <p>{this.state.name}</p>
                            <span className="topProfileMail">{this.state.email}</span>
                          <Link className="topProfileBtn" to="/admin/profile">Профайл</Link>
                        </Col>
                      </Row>
                      <Row className="topProfileBottom">
                        <Button block size="small" style={{ float: 'right', backgroundColor:'#1890FF', color:'#FFFFFF'}} onClick={this.logout}>Гарах</Button>
                      </Row>
                    </div>
                    :
                    null
                  }
              </div>
              <div style={{float:'right', marginTop:'15px'}}>
                <Tooltip placement="bottomLeft" title='Тохиргоо'>
                  <Icon className="headerBell" style={{marginRight: '20px', fontSize:'18px'}} type='setting'/>
                </Tooltip>
              </div>
              <div style={{float:'right', marginTop:'15px'}}>  
                <Tooltip placement="bottomLeft" title='Тусламж'>
                  <Icon className="headerBell" style={{marginRight: '20px', fontSize:'18px'}} type='question-circle'/>
                </Tooltip>
              </div>
              <div style={{float:'right', marginRight: '20px', marginTop:'15px'}}>
                    <Badge count={this.state.notification_count}>
                      <Icon type="bell"  className="headerBell" onClick={this.openNotification}/>
                    </Badge>
                    {
                      this.state.showNotification === false
                        ?
                        null
                        :
                        <div className="notificationBox">
                          <Row className="notifWrap">
                            {
                              this.state.notification === undefined
                                ?
                                <Col span={24}>
                                  <Row>
                                    <Col span={24} style={{ textAlign: 'center', margin: '0 auto', marginTop: 20 }}>
                                      <img src={sourceNotification} alt="" className="notifIcon" />
                                    </Col>
                                    <Col span={24} style={{ textAlign: 'center', margin: '0 auto', marginTop: 15 }}>
                                      <p style={{ color: '#262626' }}>No new notification</p>
                                    </Col>
                                  </Row>
                                </Col>
                                :
                                <Col span={24}>
                                  <Row>
                                    {
                                      this.state.notification.map(nData => (
                                        <Col span={24} style={{ textAlign: 'left', margin: '0 auto', marginBottom: 10 }} key={nData.key} className="notifList">
                                          <p style={{ marginBottom: 0 }}><b><span onClick={()=>(this.props.history.push({
                                                                              pathname: `/admin/ticket/show`}), localStorage.setItem("code", nData.code), window.location.reload())} style={{color: "blue", cursor:'pointer'}}>
                                                                        {nData.code}
                                                                      </span></b></p>
                                          <p style={{ marginBottom: 0 }}>{nData.title}</p>
                                          <span className="notifTime"><Moment format="YYYY-MM-DD HH:mm">{nData.updated_at}</Moment></span>
                                        </Col>
                                      ))
                                    }
                                  </Row>
                                </Col>
                            }
                          </Row>
                        </div>
                    }
              </div>
              <div style={{float:'right', marginRight:'20px'}}>
              <Button className={"headerCallBtn" + (this.state.isRinging ? ' headerCallBtn-ringing' : '')} onClick={this.onCallWrap}>
                <Icon type="phone" />
                Дуудлага {localStorage.getItem('local_number')}
                <div id="agora_local"></div>
                <div id="video"></div>
              </Button>
              {
                this.state.showCallWrap === false
                  ?
                  null
                  :
                  <div className="topCallRow">
                    <div className="topCallArrow"></div>
                    <p>Холбогдох дугаараа оруулна уу!</p>
                    <ResultComponent result={this.state.result} />
                    <KeyPadComponent onClick={this.onClick} />
                  </div>
              }
              
              {
                this.state.isRinging === true
                  ?
                  <div className="inComingWrap posIncoming">
                    <div className="topCallArrow"></div>
                    <Row>
                      <Col span={4}>
                        <img src={sourcePhone} alt="" className="callIcon" />
                      </Col>
                      <Col span={20}>
                        <p>{this.state.incomingCallUsername}</p>
                        <span className="number">{this.state.oponentNumber}</span>
                      </Col>
                    </Row>
                    <ul className="btnTypes mt-20">
                      <li className="mr-6">
                        <Button block onClick={this.transferCall}>
                          Шилжүүлэх
                    </Button>
                      </li>
                      <li className="mr-6">
                        <Button block className="acceptBtn" onClick={this.acceptCall}>
                          Хариулах
                    </Button>
                      </li>
                      <li>
                        <Button block className="declineBtn" onClick={this.endCall} block>
                          <img src={missed} alt="" />
                        </Button>
                      </li>
                    </ul>
                  </div>
                  :
                  null
              }
              </div>
              </Col>
              <Col span={10} style={{float:'right'}}>
                <Spin spinning={this.state.searching}>
              <Input.Search  name="searchTicket" type="text" placeholder="Хайх" className="topSearch" onSearch={this.search}  enterButton  style={{width:'450px', float:'right'}}/>
              </Spin>
            </Col>
          </Row>
        </header>
        {/*this.state.showMenu ?
          <div className="showMenu">
            <div className="menuArrow"></div>
            <ul className="menuList">
              {menuList.map(mlist => (
                <li>
                  <Link top="/profile">
                    {mlist.name}
                  </Link>
                </li>)
              )}
              <div className="menuDivider"></div>
              <li>
                <Link to="/terms">
                  Үйлчилгээний нөхцөл
                </Link>
              </li>
              <li>
                <Link to="/help">
                  Тусламж
                </Link>
              </li>
            </ul>
          </div>
          : null
       */ }
      </div>
      </Layout>
    )
  }
};

export default withRouter(HeaderComponent);