import React from 'react';
import { Layout, Icon, Card, Col,Row, Avatar} from 'antd';
import './style.css';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import axios from 'axios'
import { CONN } from '../../../constant/types';

const gridStyle = {
  width: '20%',
  textAlign: 'center',
  cursor:'pointer'
};
const { Meta } = Card;
const {Content} = Layout;

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     users:[]
    }
  }

  componentDidMount(){
    const token = localStorage.getItem('token');
    axios({
      method: 'GET',
      url: `${CONN}/users`,
      headers: {
        'Authorization': `Bearer  ${token}`
      }
    })
      .then(res =>{
        console.log(res)
        this.setState({users:res.data.data})
        if(res.data.meta.last_page > 1){
          for(var i=2; i<=res.data.meta.last_page; i++){
            axios({
              method: 'GET',
              url: `${CONN}/users?page=${i}`,
              headers: {
                'Authorization': `Bearer  ${token}`
              }
            })
              .then(response =>{
                console.log(response)
                this.setState({
                  tickets: [...this.state.users, ...response.data.data],
                })
              })
            }
        }
      })
      .catch(err=>{
        console.log(err)
      })
  }

  render() {
     return(
      <Layout style={{ paddingTop: 48, paddingLeft: 48, paddingRight: 0, paddingBottom: 15, backgroundColor: '#ffffff' }}>
        <Card title="Хэрэглэгчид">
          {
            this.state.users.map(user=>(
          <Card.Grid style={gridStyle}>
            <Meta
              avatar={
                <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
              }
              title={user.name}
            description={<div><div>{user.email}</div><div>{user.mobile==null?"-":user.mobile}</div><div>{user.local_number==null?"-":user.local_number}</div></div>}
            />
          </Card.Grid>  
            ))
          }
          
        </Card>
      </Layout>
    )
  }

}

export default Users;
