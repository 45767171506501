import React from 'react';
import { Layout, Cascader, Icon, Row, Col, Modal, Dropdown, Button, Menu, Tabs, Table, Tag, Input, Comment, Avatar, Form, List, Alert } from 'antd';
import './style.css';
import { NavLink } from 'react-router-dom';

class SiderTicketUserComponent extends React.Component{
    
    state = {
        showMenu: false,
      };
    
    render(){
        const { showMenu } = this.state;
        
        const custHistory = [
        {
            key: "ticketHistory_1",
            number: "88974586",
            date: "2019-08-16",
            id: "153426",
            price: "46,000₮",
            quantity: '5ш',
        },
        {
            key: "ticketHistory_2",
            number: "88346212",
            date: "2019-08-17",
            id: "163526",
            price: "56,000₮",
            quantity: '16ш',
        }];  

        const menu = (
            <Menu>
              <Menu.Item>
                  Edit
              </Menu.Item>
              <Menu.Item>
                  Remove
              </Menu.Item>
              <Menu.Item>
                  Whatever
              </Menu.Item>
            </Menu>
          );
        return(
        <div className="sideTicketDetail" style={{ width: 320 }}>
            <div className="orderCust">
                <img src="https://uinames.com/api/photos/male/10.jpg" alt="" className="orderCustImg" />
                <h3 className="orderCustName">Шинэ хэрэглэгч</h3>
                <span className="orderCustType">Нийт хандалт</span> 
                <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                  <Icon type="more" style={{ float: 'right', fontSize: 18, marginTop: -15 }} />                    
                </Dropdown>
                <div className="orderDivider" />
                <ul className="orderContacts">
                  <li className="orderContactList">
                    <div className="phoneSVG">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="url(#paint0_linear)"/>
                        <path d="M15.6567 13.7422C15.3364 13.5235 14.9145 13.5274 14.5981 13.7461L14.1371 14.0625C13.6254 14.4141 12.9457 14.3946 12.4535 14.0157C11.9887 13.6602 11.5473 13.2774 11.1293 12.8711C10.7231 12.4532 10.3403 12.0079 9.9848 11.5469C9.60589 11.0547 9.58636 10.375 9.93792 9.86333L10.2543 9.40239C10.4731 9.08208 10.4731 8.66411 10.2582 8.3438L8.94574 6.4063C8.58246 5.87114 7.87543 5.69145 7.30121 5.99223C6.14496 6.5977 5.65668 7.99223 6.18793 9.18755C6.94964 10.9063 7.97699 12.4766 9.21527 13.8516L10.1489 14.7852C11.5239 16.0235 13.0942 17.0508 14.8129 17.8125C16.0043 18.3438 17.4028 17.8555 18.0082 16.6993C18.309 16.125 18.1293 15.418 17.5942 15.0547L15.6567 13.7422Z" fill="white"/>
                        <defs>
                        <linearGradient id="paint0_linear" x1="22" y1="2" x2="2" y2="22" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#61D36C"/>
                        <stop offset="1" stop-color="#30B945"/>
                        </linearGradient>
                        </defs>
                      </svg>
                    </div>  
                    <p>99123456</p>
                    <Button block className="orderCall">
                        Залгах
                    </Button>
                  </li> 
                  <li className="orderContactList">
                    <div className="phoneSVG">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#6F3FAA"/>
                        <path d="M17.2891 9.02734L17.2852 9.01563C17.0195 7.93359 15.8125 6.77734 14.707 6.53516L14.6953 6.53125C12.9063 6.19141 11.0898 6.19141 9.30469 6.53125L9.29297 6.53516C8.1875 6.77734 6.98438 7.93359 6.71484 9.01563L6.71094 9.02734C6.37891 10.5352 6.37891 12.0664 6.71094 13.5742L6.71484 13.5859C6.97266 14.6211 8.08594 15.7266 9.15234 16.0313V17.2383C9.15234 17.6758 9.68359 17.8906 9.98828 17.5742L11.2109 16.3047C11.4766 16.3203 11.7422 16.3281 12.0078 16.3281C12.9102 16.3281 13.8086 16.2422 14.7031 16.0742L14.7148 16.0703C15.8203 15.8281 17.0273 14.6719 17.293 13.5898L17.2969 13.5781C17.6211 12.0664 17.6211 10.5352 17.2891 9.02734ZM16.3242 13.3555C16.1445 14.0586 15.2305 14.9375 14.5039 15.0977C13.5508 15.2773 12.5938 15.3555 11.6367 15.3281C11.6172 15.3281 11.5977 15.3359 11.5859 15.3477C11.4492 15.4883 10.6953 16.2617 10.6953 16.2617L9.74609 17.2344C9.67578 17.3086 9.55469 17.2578 9.55469 17.1563V15.1602C9.55469 15.1289 9.53125 15.0977 9.5 15.0938C8.77344 14.9336 7.85938 14.0547 7.67969 13.3516C7.38281 11.9883 7.38281 10.6055 7.67969 9.24219C7.85938 8.53906 8.77344 7.66016 9.5 7.5C11.1602 7.18359 12.8477 7.18359 14.5078 7.5C15.2344 7.66016 16.1484 8.53906 16.3281 9.24219C16.6211 10.6094 16.6211 11.9922 16.3242 13.3555Z" fill="white"/>
                        <path d="M13.5822 14.2774C13.469 14.2422 13.3635 14.2188 13.2658 14.1797C12.2424 13.754 11.2971 13.2071 10.551 12.3672C10.1252 11.8907 9.79317 11.3477 9.51192 10.7774C9.37911 10.5079 9.26583 10.2266 9.15255 9.94537C9.04708 9.69146 9.20333 9.42584 9.36348 9.23443C9.51583 9.05474 9.71114 8.91802 9.92208 8.81255C10.0861 8.73443 10.2502 8.7774 10.3713 8.91802C10.633 9.22271 10.8713 9.53912 11.0666 9.89068C11.1877 10.1055 11.1525 10.3711 10.9377 10.5157C10.8869 10.5508 10.8361 10.5938 10.7893 10.6329C10.7463 10.668 10.7072 10.7032 10.676 10.7501C10.6213 10.836 10.6213 10.9415 10.6525 11.0352C10.9182 11.7696 11.3674 12.3399 12.1057 12.6446C12.2229 12.6954 12.344 12.7501 12.4768 12.7344C12.7033 12.7071 12.7775 12.4571 12.9377 12.3282C13.094 12.1993 13.2932 12.1993 13.4611 12.3047C13.6291 12.4102 13.7932 12.5235 13.9533 12.6407C14.1135 12.754 14.2697 12.8633 14.4182 12.9922C14.5588 13.1172 14.6096 13.2774 14.5275 13.4493C14.383 13.7579 14.1721 14.0157 13.8674 14.1797C13.7815 14.2344 13.6799 14.2501 13.5822 14.2774C13.469 14.2461 13.6799 14.2501 13.5822 14.2774Z" fill="white"/>
                        <path d="M12.0038 8.46094C13.3436 8.5 14.4452 9.38672 14.6796 10.7148C14.7186 10.9414 14.7342 11.1719 14.7538 11.4023C14.7616 11.5 14.7069 11.5898 14.6014 11.5898C14.4921 11.5898 14.4452 11.5 14.4374 11.4062C14.4217 11.2148 14.4139 11.0234 14.3866 10.8359C14.246 9.83594 13.453 9.01172 12.4569 8.83594C12.3085 8.80859 12.1561 8.80078 12.0038 8.78516C11.9061 8.77344 11.7811 8.76953 11.7616 8.64844C11.7421 8.54688 11.828 8.46875 11.9217 8.46484C11.9491 8.46094 11.9764 8.46094 12.0038 8.46094Z" fill="white"/>
                        <path d="M14.0389 11.1015C14.035 11.1171 14.035 11.1562 14.0272 11.1952C13.992 11.328 13.7889 11.3476 13.742 11.2109C13.7264 11.1718 13.7264 11.1249 13.7264 11.082C13.7264 10.7968 13.6639 10.5156 13.5193 10.2695C13.3709 10.0156 13.1482 9.80071 12.8826 9.6718C12.7225 9.59368 12.5506 9.5468 12.3748 9.51555C12.2967 9.50383 12.2225 9.49602 12.1443 9.4843C12.0506 9.47258 11.9998 9.41399 12.0076 9.32024C12.0115 9.2343 12.074 9.1718 12.1678 9.17961C12.4764 9.19524 12.7732 9.26165 13.0467 9.41008C13.6014 9.70305 13.9217 10.1679 14.0115 10.789C14.0154 10.8163 14.0232 10.8437 14.0232 10.8749C14.0311 10.9413 14.035 11.0116 14.0389 11.1015C14.0389 11.1171 14.035 11.0116 14.0389 11.1015Z" fill="white"/>
                        <path d="M13.2068 11.0702C13.0936 11.0741 13.035 11.0116 13.0233 10.9062C13.0154 10.8359 13.0076 10.7616 12.992 10.6913C12.9568 10.5546 12.8865 10.4257 12.7693 10.3437C12.7147 10.3046 12.6522 10.2734 12.5897 10.2577C12.5076 10.2343 12.4217 10.2421 12.3397 10.2187C12.2498 10.1952 12.2029 10.1249 12.2147 10.0429C12.2264 9.96868 12.3006 9.90618 12.3826 9.91399C12.9022 9.95305 13.2733 10.2187 13.3279 10.832C13.3318 10.8749 13.3357 10.9218 13.3279 10.9609C13.3084 11.0312 13.2576 11.0663 13.2068 11.0702C13.0936 11.0702 13.2576 11.0663 13.2068 11.0702Z" fill="white"/>
                        <path d="M13.5823 14.2774C13.68 14.25 13.469 14.2461 13.5823 14.2774V14.2774Z" fill="white"/>
                        <path d="M14.0392 11.1016C14.0366 11.0118 14.0392 11.1172 14.0392 11.1016V11.1016Z" fill="white"/>
                      </svg>
                    </div>  
                    <p>99123456</p>
                    <Button block className="orderCall">
                        Залгах
                    </Button>
                  </li>                       
                  <li className="orderContactList">
                    <div className="phoneSVG">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="url(#paint0_linear)"/>
                        <path d="M5.44531 8.11328V15.8789L9.23438 11.9805L5.44531 8.11328Z" fill="white"/>
                        <path d="M18.5547 8.11328V15.8789L14.7852 11.9961L18.5547 8.11328Z" fill="white"/>
                        <path d="M5.73438 7.76172H18.2461L13.3242 12.8789C12.9727 13.2422 12.4922 13.4492 11.9844 13.4492C11.4766 13.4492 10.9961 13.2422 10.6445 12.8789L5.73438 7.76172Z" fill="white"/>
                        <path d="M13.6094 13.1523C13.1875 13.5898 12.5977 13.8438 11.9883 13.8438C11.3789 13.8438 10.7891 13.5938 10.3672 13.1523L9.53516 12.2852L5.73438 16.2383H18.2461L14.4453 12.2812L13.6094 13.1523Z" fill="white"/>
                        <defs>
                        <linearGradient id="paint0_linear" x1="22" y1="2" x2="2" y2="22" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FE9705"/>
                        <stop offset="1" stop-color="#FF6C00"/>
                        </linearGradient>
                        </defs>
                      </svg>
                    </div>  
                    <p>99123456</p>
                    <Button block className="orderCall">
                        Залгах
                    </Button>
                  </li> 
                  <li>
                  <Button block className="orderAddContact" type="dashed" block>
                    Холболт нэмэх
                  </Button>
                  </li>  
                </ul>
                <div className="orderDivider" />
                <div className="custHistory">
                  <h3>Өмнөх тикетүүд</h3>
                  {custHistory.map(custList => (
                    <div className="orderCard">
                      <div className="cardHeader">
                        <div className="sourceVendor">
                          <img src="https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Viber-20.png" alt=""/>
                          {custList.number}
                        </div> 
                      </div>
                      <div className="cardDetail" style={{ width: '100%', float: 'left' }}>
                        <Col span={24} className="cardDate">{custList.date}</Col>
                        <Col span={24} className="cardId">{custList.id}</Col>
                        <Col span={24}>
                          IP утас ажиллахгүй байна. Интернет холболтоо шалгасан ч холболт орж ирэхгүй байна...
                        </Col>                                
                      </div>
                      <Row className="ticketCardFooter">
                          <Col span={24}>
                            <Alert message="Шийдсэн" type="info" />
                          </Col>
                        </Row>
                    </div>
                  ))}
                </div> 
            </div>                            
        </div>
        )
    }

};

export default SiderTicketUserComponent;
