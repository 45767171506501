import React from 'react';
import {
  Layout,
  Icon,
  Row,
  Col,
  Button,
  Table,
  Popover,
  message,
  Modal,
  Input,
  Dropdown,
  Select,
  Menu,
  Switch,
  DatePicker,
  Spin,
  Tag,
  Tooltip,
  Badge
} from 'antd';
import { Link } from 'react-router-dom';
import './style.css';
import moment from 'moment';
import Moment from 'react-moment';
import { CONN } from '../../../../constant/types';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import sourceViber from '../../../../assets/icons/contacts/viber.svg';
import sourcePhone from '../../../../assets/icons/contacts/call.svg';
import sourceMessenger from '../../../../assets/icons/contacts/mail.svg';
import sourceTwitter from '../../../../assets/icons/contacts/twitter.svg';
import dragIcon from '../../../../assets/icons/drag.svg';
import { Checkbox } from 'antd'
import { Pagination } from 'antd';
import axios from 'axios';
import {TicketDashboard} from '../../Ticket';

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
const { Column } = Table;
const { Content } = Layout;
const { Option } = Select;
let array_status=[];
let array_users =[];
class TicketShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTicket: false,
      tickets: [],
      page: '',
      pages:'1',
      users: [],
      selectedRowKeys: [],
      token: localStorage.getItem('token'),
      columns: [
        {
          title: "Subject",
          dataIndex: 'all',
          key: 'all',
          render: all =>(
            <div onClick={()=>(this.props.history.push({
              pathname: `/admin/ticket/show`}), localStorage.setItem("code", all.code))} style={{cursor:'pointer'}}>
            <Row>
              {
                all.is_new == 0
                ? <span  style={{fontSize: '16px', fontWeight:'bold'}}>
                  <span style={{color: "#1890ff"}}>
                    {all.code}
                  </span> - {all.title.substring(0,80)}
                </span>
                :<Badge dot><span onClick={()=>(this.props.history.push({
                  pathname: `/admin/ticket/show`}), localStorage.setItem("code", all.code))} style={{fontSize: '16px', fontWeight:'bold', cursor:'pointer'}}><span style={{color: "#1890ff"}}>
            {all.code}
          </span> - {all.title.substring(0,80)}
                </span>
                </Badge>
              }

              
              <span style={{float: 'right'}}>
              {
                all.status != null
                ?
                <Tooltip title={all.status.name}>
                  <Icon type='right-circle' style={{fontSize: '16px', color: all.status.color, backgroundColor: all.status.color, borderRadius:"50%"}}></Icon>
                </Tooltip>
                :
                <Tooltip title='тодорхойгүй'>
                  <Icon type='right-circle' style={{fontSize: '16px', color: all.status.color, backgroundColor: all.status.color, borderRadius:"50%"}}></Icon>
                </Tooltip>
              }
              {
                all.deadline == null
                ? <Tag style={{marginLeft: '10px'}}>Дуусах хугацаа</Tag>
                : <Tag color='gold' style={{marginLeft: '10px'}}>{all.deadline}</Tag>
              }

              </span>
              <span style={{marginRight: '10px', float: 'right'}}>
              {
                all.source != null
                ?
                <Tooltip title={all.source.name}>
                  <Icon type={all.source.icon} theme='filled' style={{fontSize: '16px', color: 'rgba(89, 89, 89, 0.7)'}}></Icon>
                </Tooltip>
                :
                <Tooltip title='Тодорхойгүй'>
                  <Icon type='question-circle' theme='filled' style={{fontSize: '16px', color: 'rgba(89, 89, 89, 0.7)'}}></Icon>
                </Tooltip>
              }
              </span>
            </Row>
            <Row>
              {
                all.reply.length == 0
                ?
                <span><Icon type="message"/> </span>
                :
                <span><Icon type="message"/> {all.reply[all.reply.length-1].message.substring(0, 150)} - {all.reply.length}</span>
              }
            </Row>
            <Row style={{marginTop:'5px'}}>
              {
                all.topic == null
                ?
                <Tag>Төрөл</Tag>
                :
                <Tag color="#f50">{all.topic.name}</Tag>
              }
              {
                all.priority == null
                ?
                <Tag>Зэрэглэл</Tag>
                :
                <Tag color={all.priority.color}>{all.priority.name}</Tag>
              }
              <span style={{float:'right'}}>
                {
                  all.customer == null
                  ? <Tag>Харилцагч</Tag>
                  : <Tag color="orange">{all.customer.name==null?all.customer.mobile:all.customer.name}</Tag>
                } 
                {
                  all.owner == null
                  ? <Tag>Хариуцагч</Tag>
                  : <Tag color="blue">{all.owner.name}</Tag>
                }
                {
                  all.department == null
                  ? <Tag>Хэлтэс</Tag>
                  : <Tag color="green">{all.department.name}</Tag>
                }
              </span>

            </Row>
            </div>
          )
        }
      ],
      dataSource: [],

      /**************************TicketNew states START ***********************/
      priorities:[],
      statuses: [],
      topics: [],
      sources: [],
      assignee:[],
      assignee_name: [],
      customers: [],
      
      phone: '',
      viber: '',
      messenger: '',
      twitter: '',
      mail:'',

      
      status_id:'',
      status_name: '',
      topic_id:'',
      topic_name: '',
      priority_id: '',
      priority_name:'',
      priority_time: '',
      source_id: '',
      source_name: '',
      customer_id: '',
      assign: '',

      firstname:'',
      lastname:'',
      ticketOwners:'',

      owner_id: '',
      owner_name:'Хариуцагч сонгох',
      owner: [],

      title: '',
      code:'',
      id:'',
      address: '',
      created_at: '',
      deadline: '',
      newCust: false,
      hide: false,
      date: '',
      pager: 1,
      count: 15,
      loading: true,
      total: '',
      name:'',

      departments: [],
      department_id: '',

      message: '',
      toogle: true

      /**************************TicketNew states END ***********************/
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
  }

  componentDidMount() {
    console.log("hello")
    localStorage.removeItem('code')
    const url = (`${CONN}/tickets?page=1`);
    var timeStamp = Math.floor(Date.now());
    var timeStamp1= timeStamp + 7200000;
    var formatted = moment(timeStamp).format("MMMM Do YYYY, h:mm:ss a");
    var formatted1 = moment(timeStamp1).format("MMMM Do YYYY, h:mm:ss a");


    this.setState({created_at: timeStamp})
    var token = localStorage.getItem('token')
    const config = {
      headers: {
        'Authorization': `Bearer  ${token}`
      }
    };
    
    axios
      .get(`${CONN}/ticket_deadline?page=1`, config)
      .then((response) => {
        this.setState({
          loading: true,
          tickets:  response.data.data,
          refreshing: false,
          page: response.data.meta.last_page,
          total: response.data.meta.total
        });
        console.log(response)
          {
            let tickets = this.state.tickets;
            let ds = []

            tickets.map((ticket) => {
              let assignees = '';
                ticket.assign.map(assignee=>{
                  assignees = `${assignees} ${assignee.name};`
                })
              let newRow = {
                all: ticket,
                id: ticket.id,
                code: ticket.code,
                title: ticket.title,
                customer: ticket.customer != null ? ticket.customer.name != null ? ticket.customer.name : '-' : '-',
                comment: ticket.reply,
                owner: ticket.owner !== null ? ticket.owner.name: '-',
                topic: ticket.topic !== null ? ticket.topic.name : '-',
                status: ticket.status !== null ? ticket.status : '-',
                created_at: moment(ticket.created_at).format("YYYY MM DD, HH:mm:ss"),
                deadline: ticket.deadline,
                assignee: assignees,
                source: ticket.source
              };
              ds.push(newRow);
            });
            this.setState({
              dataSource: ds
            })
          }
        this.setState({loading:false})
      })

    axios
    .get(`${CONN}/ticket_statuses`, config)
    .then((res) => {
      if(array_status.length <= 0){
      res.data.map(map =>(
        array_status.push({text:map.name, value: map.name})
      ))}
    })
    

    console.log('statuses',JSON.parse( localStorage.getItem('statuses')))
     this.setState({statuses: JSON.parse( localStorage.getItem('statuses'))});
     this.setState({topics: JSON.parse( localStorage.getItem('topics'))});
     this.setState({priorities: JSON.parse( localStorage.getItem('priorities'))});
     this.setState({sources: JSON.parse( localStorage.getItem('sources'))});
     this.setState({departments: JSON.parse( localStorage.getItem('departments'))});
     this.setState({users: JSON.parse( localStorage.getItem('users'))});
    console.log(JSON.parse( localStorage.getItem('users')))

    /*axios
    .get(`${CONN}/users?page=1`, config)
    .then((response) => {
      this.setState({
        users: [...this.state.users, ...response.data.data],
        page: response.data.meta.last_page
      });
      //console.log("page", response)

      if(response.data.meta.last_page > 1){
        for(var i=2; i<=response.data.meta.last_page; i++){
          axios
            .get(`${CONN}/users?page=${i}`, config)
            .then(response =>{
              this.setState({
                users: [...this.state.users, ...response.data.data],
              })
              });
              }}
              if(array_users.length <= 0){
              this.state.users.map(map=>(
                array_users.push({text: map.name, value: map.name})
              ))}
    })*/
    /**************** TicketNew Fetch START ****************/

    //Төлөвийн мэдээллийг авах
    /*axios
    .get(`${CONN}/ticket_statuses`,config)
    .then(response => {
      this.setState({statuses: response.data})
    })
    //Төрөлийн мэдээллийг авах
    axios
    .get(`${CONN}/ticket_topics`,config)
    .then(response => {
      this.setState({topics: response.data})
    })
    //Түвшний мэдээллийг авах
    axios
    .get(`${CONN}/ticket_priorities`,config)
    .then(response => {
      this.setState({priorities: response.data})
    })
    //Source_id мэдээллийг авах
    axios
    .get(`${CONN}/sources`,config)
    .then(response => {
      this.setState({sources: response.data})
    })

    //getting departments
    axios
    .get(`${CONN}/department`, config)
    .then((res) => {
      //console.log(res);
      this.setState({departments: res.data.data})
    })*/

    /*axios
    .get(`${CONN}/customers?page=1`, config)
    .then((response) => {
      this.setState({
        customers: [...this.state.customers, ...response.data.data],
        page: response.data.meta.last_page
      });

      if(response.data.meta.last_page > 1){
        for(var i=2; i<=response.data.meta.last_page; i++){
          axios
            .get(`${CONN}/customers?page=${i}`,config)
            .then(response =>{
              this.setState({
                customers: [...this.state.customers, ...response.data.data] })
              });
              }}
            });*/
    

    /**************** TicketNew Fetch END ****************/
  }

  page =(value)=>{
    this.setState({loading: true})
    var token = localStorage.getItem('token')
    const config = {
      headers: {
        'Authorization': `Bearer  ${token}`
      }
    };
    this.setState({pages: value})
    axios
      .get(`${CONN}/ticket_deadline?page=${value}`, config)
      .then((response) => {
        this.setState({
          loading: true,
          tickets:  response.data.data,
          refreshing: false,
          assign:'',
          page: response.data.meta.last_page
        });
          {
            let tickets = this.state.tickets;
            let ds = []

            tickets.map((ticket) => {
              let assignees = '';
                ticket.assign.map(assignee=>{
                  assignees = `${assignees} ${assignee.name};`
                })
              let newRow = {
                all: ticket,
                id: ticket.id,
                code: ticket.code,
                title: ticket.title,
                customer: ticket.customer != null ? ticket.customer.name != null ? ticket.customer.name : '-' : '-',
                comment: ticket.reply,
                owner: ticket.owner !== null ? ticket.owner.name: '-',
                topic: ticket.topic !== null ? ticket.topic.name : '-',
                status: ticket.status !== null ? ticket.status : '-',
                created_at: moment(ticket.created_at).format("YYYY MM DD, HH:mm:ss"),
                deadline: ticket.deadline,
                assignee: assignees,
                source: ticket.source
              };
              
              ds.push(newRow);
            });

            this.setState({
              dataSource: ds
            })
          }
        this.setState({loading:false})
      })
  }

  start = () => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
      this.page(this.state.pages);
    };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  ticketNew = e => {
    e.preventDefault()
    this.props.history.push({
      pathname: '/admin/ticket/new',
      state: {code: this.state.code}
    });
  }
  
  tableChange = (value) =>{
    this.setState({
      pager: value.current,
      count: value.pageSize
    })
  }


  handleChange = (value, label) => {
    this.setState({
      owner_name: label.props.label.name,
      owner_id: value,
      owner: label.props.label
    })
    if(this.state.owner !== null){
      //this.setState({users: [...this.state.users, ... this.state.owner]})
      var array = [...this.state.users];
      array.push(this.state.owner)
      var index = array.indexOf(label.props.label)
      if (index !== -1) {
      array.splice(index, 1);
      this.setState({users: array})
    }
    }else
    {
    var array = [...this.state.users];
    var index = array.indexOf(label.props.label)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({users: array})
    }
  }
    //this.removeItm()
  }
  handleChange2 = (value) => {
    console.log(value)
    this.setState({
      customer_id: value
    })
  }

  //Оролцогчийн нэр сонгоход ID өөрчлөх
  handleChange1(value, label) {
    this.setState({assignee: value })
    //this.setState({assignee_names: label.props.label})
  }

  /********* TicketNew Functions START ***********/
  //setState үйлдлийг хийх
  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  deadline = (value, dateString) => {
    this.setState({deadline: dateString})
  }

  newTicket = () => {
    this.setState({newTicket: !this.state.newTicket})
  }

  search =(value)=>{
    console.log(this.state)
    const config = {
      headers: {
        'Authorization': `Bearer  ${this.state.token}`
      }
    };
    console.log('url:', `${CONN}/department_search/${value}`)
    axios
    .get(`${CONN}/customer_search/${value}`, config)
    .then((res) => {
      console.log(res)
      this.setState({
        customers: res.data.data,
      })
      //this.setState({visible: !this.state.visible});
      if(res.data.meta.last_page > 1){
        for(var i=2; i<=res.data.meta.last_page; i++){
          axios
            .get(`${CONN}/customer_search/${value}?page=${i}`, config)
            .then(response =>{
              this.setState({
                customers: [...this.state.customers, ...response.data.data],
              })
            })
          }}
    })
    .catch(error =>{
      console.log('error', error)
    })
  }

  postTicket = () => {
    if(this.state.hide === false)
    {
      axios({
        method: 'post',
        url: `${CONN}/tickets`,
        header: {
          "Accept": 'application/json',
          "Content-Type": 'application/json',
        },
        data: {
          title: this.state.title,
          status_id: this.state.status_id,
          topic_id: this.state.topic_id,
          priority_id: this.state.priority_id,
          user_id: this.state.customer_id,
          owner_id: this.state.owner_id,
          deadline: this.state.deadline
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      })
      .then(response => {
        this.setState({id: response.data.data.id})
        if(response.status == 200) message.success("Тикет амжилттай үүслээ.")
                      //Оролцогч үүсгэх *Олон оролцогч нэмж болох тул давталт ашиглав*
                      for(var i=0; i<this.state.assignee.length; i++){
                        axios({
                          method: 'post',
                          url: `${CONN}/ticket_assignee`,
                          data: {
                            user_id: this.state.assignee[i],
                            ticket_id: this.state.id
                          },
                          headers: {
                            'Authorization': `Bearer  ${this.state.token}`
                          }
                        })
                        .then(response=> {
                          //console.log(response)
                          if (response.status == 200) {
                            message.success("Оролцогчид амжилттай үүслээ.")
                            this.newTicket();
                            this.page()

                          }
                        })
                        //Оролцогч үүсгэх алдаа
                        .catch(error => {
                          console.log(error)
                          message.error("Алдаа гарлаа... Дахин оролдоно уу! - Оролцогч үүсгэх")
                          //console.log(this.state)
                        })
                      }
      })
      .catch(error => {
        console.log(error)
        message.error("Алдаа гарлаа... Дахин оролдоно уу! - Тикет үүсгэх үүсгэх")
        //console.log(this.state)
      })
    }else{
      console.log(this.state)
      axios({
        method: 'post',
        url: `${CONN}/register`,
        data: {
          name: this.state.name,
          mobile: this.state.phone,
          source_id: this.state.source_id,
          viber: this.state.viber,
          address: this.state.address,
          email: this.state.mail,
          twitter: this.state.twitter,
          messenger: this.state.messenger,
          password: "password",
          password_confirmation: "password"
        },
        headers: {
          'Accept': `application/json`,
           //'Authorization': `Bearer  ${this.state.token}`
        }
      })
      .then(response => {
        //console.log(response.data.data.id)
        if(response.status == 200) message.success("Хэрэглэгч амжилттай үүслээ.")
        this.setState({customer_id: response.data.user.id})
        console.log(response.data.user.id)
          
          //Тикет үүсгэх
          axios({
            method: 'post',
            url: `${CONN}/tickets`,
            header: {
              "Accept": 'application/json',
              "Content-Type": 'application/json',
            },
            data: {
              title: this.state.title,
              status_id: this.state.status_id,
              topic_id: this.state.topic_id,
              priority_id: this.state.priority_id,
              user_id: response.data.user.id,
              owner_id: this.state.owner_id,
              deadline: this.state.deadline,
              created_at: this.state.created_at
            },
            headers: {
              'Authorization': `Bearer  ${this.state.token}`
            }
          })
          .then(response => {
            console.log(response)
            this.setState({id: response.data.data.id})
            //console.log(response)
            //console.log(this.state)
            if(response.status == 200) message.success("Тикет амжилттай үүслээ.")
              
              //Оролцогч үүсгэх *Олон оролцогч нэмж болох тул давталт ашиглав*
              for(var i=0; i<this.state.assignee.length; i++){
                axios({
                  method: 'post',
                  url: `${CONN}/ticket_assignee`,
                  data: {
                    user_id: this.state.assignee[i],
                    ticket_id: response.data.data.id
                  },
                  headers: {
                    'Authorization': `Bearer  ${this.state.token}`
                  }
                })
                .then(response=> {
                  //console.log(response)
                  if (response.status == 200 && i<=this.state.assignee.length) {
                    message.success("Оролцогчид амжилттай үүслээ.")
                    this.newTicket();
                    window.location.reload();
                  }
                })
                //Оролцогч үүсгэх алдаа
                .catch(error => {
                  console.log(error)
                  message.error("Алдаа гарлаа... Дахин оролдоно уу! - Оролцогч үүсгэх")
                  //console.log(this.state)
                })
              }
          })
          //Тикет үүсгэх алдаа
          .catch(error => {
            console.log(error)
            if(error == 404){
            message.warning('Алдаа гарлаа... Дахин оролдоно уу! - Тикет үүсгэх');
            }else message.warning('Алдаа гарлаа... Дахин оролдоно уу! - Тикет үүсгэх');
          });
      })
      //Харилцагч үүсгэх алдаа
      .catch(error => {
        console.log(error)
        if(error == 404){
        message.warning('Алдаа гарлаа... Дахин оролдоно уу! - Хэрэглэгч үүсгэх');
        }else message.warning('Алдаа гарлаа... Дахин оролдоно уу! - Хэрэглэгч үүсгэх');
      });
    }
  }



  hide= ()=>{
    this.setState({hide: !this.state.hide, newCust:!this.state.newCust })
  }
  newCust=()=>{
    this.setState({newCust: !this.state.newCust})
  }
// ************* Actions START ***************
/*
  ticketClose = () =>{
    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          status_id: 3
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  ticketOpen = () =>{
    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          status_id: 2
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  ticketPass = (value) =>{
    console.log(value)
    
    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          status_id: 1,
          department_id: value
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  ticketCome = () =>{
    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          status_id: 29
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
    }
  }*/
  // ************* Actions END *******************

  removeItm = () => {
    var array = [...this.state.users];
    var index = array.indexOf(this.state.owner)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({users: array})
    }

  }

  /********* TicketNew Functions END ***********/


  onChangeColumnCheckbox(e) {
    // console.log(`checked = ${e.target.checked}`);
    // console.log(`value = ${e.target.value}`);

    let val = e.target.value;
    let checked = e.target.checked;
    let isAvailableAdd = true;

    const { columns } = this.state;

    columns.map((col) => {
      if (col.key == val) {
        isAvailableAdd = false;
      }
    })

    if (isAvailableAdd && checked) {
      let newColumn = {
        dataIndex: val,
        key: val,
        title: ''
      }

      switch (val) {
        case 'code':
          newColumn.title = 'Дугаар'
          break;
        case 'customer':
          newColumn.title = 'Харилцагч'
          break;
        case 'title':
          newColumn.title = 'Тайлбар'
          break;
        case 'owner':
          newColumn.title = 'Хариуцагч'
          break;
        case 'topic':
          newColumn.title = 'Төрөл'
          break;
        case 'status':
          newColumn.title = 'Төлөв'
          break;
        case 'assignee':
          newColumn.title = 'Оролцогч'
          break;
        case 'created_at':
          newColumn.title = 'Огноо'
          break;
        case 'deadline':
          newColumn.title = 'Дуусах хугацаа'
          break;

        default:
          break;
      }

      this.setState({
        columns: [...columns, newColumn]
      })
    }

    if(!checked) {
      let cols = []
      columns.map((col)=>{
        if(col.key != val){
          cols.push(col);
        }
      })

      this.setState({
        columns: cols
      })
    }
  }

  ticketStats = (value) =>{
    console.log(value)

    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          status_id: value,
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.page(this.state.pages);
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  ticketOwners = (value, label) =>{
    console.log(value)
    this.setState({ticketOwners: label.props.label.name})

    console.log(this.state.selectedRowKeys.length)

    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          owner_id: value,
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.setState({ticketOwners:''})
        this.page(this.state.pages);
      })
      .catch(err => {
        console.log(err)
      })
    }
    
  }

  ticketDepartments = (value) =>{
    console.log(value)

    console.log(this.state.selectedRowKeys.length)

    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          department_id: value,
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.setState({ticketOwners:''})
        this.page(this.state.pages);
      })
      .catch(err => {
        console.log(err)
      })
    }
    
  }

  ticketTopics = (value) =>{
    console.log(value)

    console.log(this.state.selectedRowKeys.length)

    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          topic_id: value,
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.setState({ticketOwners:''})
        this.page(this.state.pages);
      })
      .catch(err => {
        console.log(err)
      })
    }
    
  }

  ticketPriorities = (value) =>{
    console.log(value)

    console.log(this.state.selectedRowKeys.length)

    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'put',
        headers: {    'Content-Type': 'application/json'  },
        url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          priority_id: value,
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.setState({ticketOwners:''})
        this.page(this.state.pages);
      })
      .catch(err => {
        console.log(err)
      })
    }
    
  }

  ticketAssignees =(value) =>{
    console.log(value)

    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      console.log(this.state.tickets[code+page].id, value)
      axios({
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        url: `${CONN}/ticket_assignee`,
        //url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          ticket_id: this.state.tickets[code+page].id,
          user_id: value
        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.page(this.state.pages);
      })
      .catch(err => {
        console.log(err)
      })
    }

  }

  removeAssignee =()=>{
    console.log('yes')
    for(var i=0; i<=this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      if(this.state.tickets[code+page] != null){
      this.state.tickets[code+page].assign.map(assign=>{
        axios({
          method: 'delete',
          headers: {'Content-Type': 'application/json'},
          url: `${CONN}/ticket_assignee/${assign.id}`,
          //url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
          headers: {
            'Authorization': `Bearer  ${this.state.token}`
          }
        }).then(res => {
          console.log(res)
          this.page(this.state.pages);
        })
        .catch(err => {
          console.log(err)
        })
      })}
    }
  }
  sendMessage = () =>{
    var comment;
    var note;
    if(this.state.toogle==true){
      comment=false;
      note=true;
    }else{
      comment=true;
      note=false;
    }
    for(var i=0; i<this.state.selectedRowKeys.length; i++){
      console.log(i)
      var code= this.state.selectedRowKeys[i];
      var page = ((this.state.pager - 1)*this.state.count)
      axios({
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        url: `${CONN}/ticket_comment/1`,
        //url: `${CONN}/tickets/${this.state.tickets[code+page].code}`,
        data: {
          "ticket_id": this.state.tickets[code+page].id,
          "message": this.state.message,
          "is_note": comment,
          "is_reply": note,
          "level": 0,
          "quick_response_id": ""

        },
        headers: {
          'Authorization': `Bearer  ${this.state.token}`
        }
      }).then(res => {
        console.log(res)
        this.page(this.state.pages);
      })
      .catch(err => {
        console.log(err)
      })
    }
  }
  render() {
    const titleSettings = <span>Тохиргоо</span>;

    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

     // to change status overlays on menu
     const onChangeDepartment = (
      <Menu>
       {
          this.state.departments.map( deps=>(
            <Menu.Item>
            <Button block block type="link"
              onClick={()=>this.ticketPass(deps.id)}>
              {deps.name}
            </Button>
          </Menu.Item>
          ))
        }
      </Menu>
    );

    //console.log(this.state.deadline, "created: ", this.state.created_at )
    
    const Settings = (
      <div>
        <ul>
          <li><input type="checkbox" disabled checked={true} onChange={(e) => this.onChangeColumnCheckbox(e)} value="code" /> Дугаар</li>
          <li><input type="checkbox" disabled checked={true} onChange={(e) => this.onChangeColumnCheckbox(e)} value="customer" /> Харилцагч</li>
          <li><input type="checkbox" disabled checked={true} onChange={(e) => this.onChangeColumnCheckbox(e)} value="title" /> Тайлбар</li>
          <li><input type="checkbox" disabled checked={true} onChange={(e) => this.onChangeColumnCheckbox(e)} value="owner" /> Хариуцагч</li>
          <li><input type="checkbox" disabled checked={true} onChange={(e) => this.onChangeColumnCheckbox(e)} value="status" /> Төлөв</li>
          <li><input type="checkbox" onChange={(e) => this.onChangeColumnCheckbox(e)} value="topic" /> Төрөл</li>
          <li><input type="checkbox" onChange={(e) => this.onChangeColumnCheckbox(e)} value="assignee" /> Оролцогч</li>
          <li><input type="checkbox" onChange={(e) => this.onChangeColumnCheckbox(e)} value="created_at" /> Огноо</li>
          <li><input type="checkbox" onChange={(e) => this.onChangeColumnCheckbox(e)} value="deadline" /> Дуусах хугацаа</li>
        </ul>
      </div>
    );

    const Actions = (
      <div style={{width: '150px'}}>
        {
          this.state.statuses.map(stats =>(
            <Button block type="primary" style={{ marginBottom: 10 }} onClick={()=>this.ticketStats(stats.id)}>
              {stats.name}
            </Button>
          ))
        }
          
      </div>
    )

    const Owner = (
      <Select
        style={{ width: '100%' }}
        autoFocus={true}
        maxTagCount={1}
        value={this.state.ticketOwners}
        placeholder="Add members"
        onChange={this.ticketOwners}
        optionLabelProp="label"
      >
        {
          this.state.users.map(d => (
            <Option value={d.id} label={d}>
          <span className="select-Assistname">{d.name}</span>
        </Option>
          ))
        }
      </Select>
    )

    const Assignee =(
      <div>
      <Select
        //mode="multiple"
        style={{marginTop: '10px', width: '100%', alignText: "center"}}
        autoFocus={true}
        maxTagCount={1}
        placeholder="Add members"
        value={"Оролцогч нэмэх"}
        //onDeselect={handleDeselect}
        onSelect={this.ticketAssignees}
        optionLabelProp="label"
      >
        {
          this.state.users.map(d => (
            <Option value={d.id} label={d.name}>
          <span className="select-Assistname">{d.name}</span>
        </Option>
          ))
        } 
      </Select>
      <Button onClick={this.removeAssignee} style={{marginTop: '10px', width: '100%', alignText: 'left'}}><Icon type='usergroup-delete'/> Оролцогчдийг хасах</Button>
      </div>
    )

    const Message = (
      <div>
        <Switch checkedChildren='Хариулт' unCheckedChildren='Тэмдэглэл' defaultChecked onChange={()=>this.setState({toogle: !this.state.toogle})} style={{width:'90px', marginBottom:'10px'}}/>
        <Input.TextArea name='message' onChange={this.changeHandler} style={{marginBottom:'10px'}}/>
        <Button onClick={this.sendMessage} size='small' style={{alignSelf:'right', marginBottom: '10px'}}>Илгээх</Button>
      </div>
    )

    const onChangePriority1 = (
      <Menu>
        {
          this.state.priorities.map( stats=>(
            <Menu.Item>
            <Button block block type="link"
              onClick={() => this.ticketPriorities(stats.id)
              }>
              {stats.name}
            </Button>
          </Menu.Item>
          ))
        }
      </Menu>
    );

    const onChangeTopic1 = (
      <Menu>
        {
          this.state.topics.map( stats=>(
            <Menu.Item>
            <Button block block type="link"
              onClick={() => this.ticketTopics(stats.id)
              }>
              {stats.name}
            </Button>
          </Menu.Item>
          ))
        }
      </Menu>
    );
    const onChangeDepartment1 = (
      <Menu>
       {
          this.state.departments.map( deps=>(
            <Menu.Item>
            <Button block block type="link"
              onClick={() => { 
                this.ticketDepartments(deps.id)
              }
              }>
              {deps.name}
            </Button>
          </Menu.Item>
          ))
        }
      </Menu>
    );

    const Department = (
      <Dropdown overlay={onChangeDepartment1}>
      <Button block block className="sideDropdown">
      {
        this.state.department_name
      } 
        <Icon type="down" />
      </Button>
    </Dropdown> 
    )

    const Topic =(
      <Dropdown overlay={onChangeTopic1}>
        <Button block block className="sideDropdown">
        {
          this.state.topic_name
        } 
          <Icon type="down" />
        </Button>
      </Dropdown> 
    )

    const Priority =(
      <div>
      <Dropdown overlay={onChangePriority1}>
        <Button block block className="sideDropdown">
        {
          this.state.priority_name
        } 
          <Icon type="down" />
        </Button>
      </Dropdown> 
      </div>
    )

    const titleDownload = <span>Татаж авах</span>;
    const Download = (
      <div>
        <Button block type="primary" style={{ marginBottom: 10 }} >
          CSV формат
        </Button>
        <Button block type="primary" >
          JSON формат
        </Button>
      </div>
    );

    const filter = (
      <div>
        <Button block type="primary" style={{ marginBottom: 10 }} >
          CSV формат
        </Button>
        <Button block type="primary" >
          JSON формат
        </Button>
      </div>
    )

    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    function itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>Previous</a>;

      }
      if (type === 'next') {
        return <a>Next</a>;
      }
      return originalElement;
    }

    /************* TicketNew Functions START **************/
      //Төлөв өөрчлөх хэсэг
      const onChangeStatus = (
        <Menu>
          {
            this.state.statuses.map(stats => (
              <Menu.Item>
                <Button block type="link" 
                  onClick={() => {this.setState({
                    status_id: stats.id,
                    status_name: stats.name
                  })}
                  }>
                  {stats.name}
                </Button>
              </Menu.Item>
            ))
          }
        </Menu>
      );

      //Төрөл өөрчлөх хэсэг
      const onChangeTopic = (
        <Menu>
          {
            this.state.topics.map(stats => (
              <Menu.Item>
                <Button block type="link" 
                  onClick={() => this.setState({
                    topic_id: stats.id,
                    topic_name: stats.name
                  })}>
                  {stats.name}
                </Button>
              </Menu.Item>
            ))
          }
        </Menu>
      );

      //Түвшин өөрчлөх
      const onChangePriority = (
        <Menu>
          {
            this.state.priorities.map(stats => (
              <Menu.Item>
                <Button block type="link" 
                  onClick={() => this.setState({
                    priority_id: stats.id,
                    priority_name: stats.name,
                    priority_time: stats.period
                  })
                  }>
                  {stats.name}
                </Button>
              </Menu.Item>
            ))
          }
        </Menu>
      );


      //SourceId өөрчлөх
      const onChangeSource = (
        
        <Menu>
          {
            this.state.sources.map(stats => (
              <Menu.Item>
                <Button block type="link" 
                  onClick={() => this.setState({
                    source_id: stats.id,
                    source_name: stats.keyword
                  })
                  }>
                  {stats.name}
                </Button>
              </Menu.Item>
            ))
          }
        </Menu>
      );

     

      
    /************* TicketNew Functions End **************/

    return (
      <Layout style={{ paddingTop: 48, paddingLeft: 15, paddingRight: 15, paddingBottom: 15, backgroundColor: '#ffffff' }}>
        <Row className="ticketControl" style={{backgroundColor: 'rgb(255, 255, 255)'}}>
          <Col span={5}>
            <ul className="ticketLeft">
              <li>
              {/*console.log(this.state.priority_time, "1", this.state.created_at, "2", this.state.priority_time + this.state.created_at)*/}
                <Popover placement="bottomLeft" title={titleDownload} content={Download} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="filter" />
                  </span>
                </Popover>
              </li>
              <li>
                <span className="ticketCtlBtn" onClick={this.start} disabled={!hasSelected} >
                  <Icon type="sync" />
                </span>
              </li>
              <li>
                <Popover placement="bottom" title={titleDownload} content={Download} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="download" />
                  </span>
                </Popover>
              </li>
             {/* <li>
                <a href="#" className="ticketCtlBtn">
                  <Icon type="more" />
                </a>
              </li>*/}
            </ul>
          </Col>
          <Col span={19}>
            <ul className="ticketRight">
              <li>
                <Button block className="ticketNew" onClick={this.newTicket}>
                  <Icon type="plus" />
                  Шинэ
                </Button>
              </li>
{              /*<li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Багана өөрчлөх'>
                <Popover placement="bottom" title={titleSettings} content={Settings} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="table" />
                  </span>
                </Popover>
                </Tooltip>
              </li>*/}
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Төлөв өөрчлөх'>
                <Popover placement="bottom" title='Төлөв өөрчлөх' content={Actions} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="check-circle"/>
                  </span>
                </Popover>
                </Tooltip>
                </li>
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Хариуцагч өөрчлөх'>
                <Popover placement="bottom" title='Хариуцагч өөрчлөх' content={Owner} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="user-add"/>
                  </span>
                </Popover>
                </Tooltip>
              </li>
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Оролцогчид өөрчлөх'>
                <Popover placement="bottom" title='Оролцогчид өөрчлөх' content={Assignee} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="usergroup-add" onclick/>
                  </span>
                </Popover>
                </Tooltip>
              </li>
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Хариу'>
                <Popover placement="bottom" title='Хариу илгээх' content={Message} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="rollback" onclick/>
                  </span>
                </Popover>
                </Tooltip>
              </li>
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Төрөл'>
                <Popover placement="bottom" title='Төрөл өөрчлөх' content={Topic} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="bulb" onclick/>
                  </span>
                </Popover>
                </Tooltip>
              </li>
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Зэрэглэл'>
                <Popover placement="bottom" title='Зэрэглэл өөрчлөх' content={Priority} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="ordered-list" onclick/>
                  </span>
                </Popover>
                </Tooltip>
              </li>
              <li>
                <a href="#">
                </a>
                <Tooltip placement='top' title ='Хэлтэс'>
                <Popover placement="bottom" title='Хэлтэс өөрчлөх' content={Department} trigger="click">
                  <span className="ticketCtlBtn">
                    <Icon type="apartment" onclick/>
                  </span>
                </Popover>
                </Tooltip>
              </li>
              <li>
                {hasSelected ? `Сонгож авсан ${selectedRowKeys.length} тикет` : ''}
              </li>
            </ul>
          </Col>
        </Row>
        <Content style={{ backgroundColor: '#ffffff', height: '100%' }}>
        <Spin spinning={this.state.loading} size="large" delay={50} tip="Ачааллаж байна... Түр хүлээнэ үү!">
          <Table dataSource={this.state.dataSource} columns={this.state.columns} rowSelection={rowSelection} size="small" className="orderTable" onChange={this.tableChange}     pagination={false}/>
          {<Pagination
            total={this.state.total}
            showTotal={(total, range) => `${range[0]}-${range[1]} харуулж байна. Нийт ${total} тикет`}            
            pageSize={15}
            onChange={(e=>{this.page(e)})}
            current={this.state.pages}
            showQuickJumper 
            style={{float:'right', marginTop: '5px', marginRight: '80px'}}
          />}
        </Spin>
        </Content>

        <Content>

        {/**************** TicketNew Modal START *****************/}

         <Modal
         visible={this.state.newTicket}
         onOk={this.postTicket}
         onCancel={this.newTicket}>
            <h2>Тикет үүсгэх</h2>
                <h2>
                       <Input
                       name="title"
                       placeholder="Гарчиг"
                       onChange={this.changeHandler}
                       type="text"/>
                </h2>
                <span className="sideHeading">
                     Төрөл
                    </span> 
                    <div>
                    <Dropdown overlay={onChangeTopic}>
                      <Button block className="sideDropdown">
                        {this.state.topic_name} 
                        <Icon type="down" style={{float: "right"}}/>
                      </Button>
                    </Dropdown>
                    </div>
                    <span className="sideHeading">
                     Төлөв
                    </span> 
                    <div>
                    <Dropdown overlay={onChangeStatus}>
                      <Button block className="sideDropdown open">
                        {this.state.status_name}
                        <Icon type="down" style={{float: "right"}}/>
                      </Button>
                    </Dropdown> 
                    </div>
                    
                    <span className="sideHeading">
                     Зэрэглэл
                    </span> 
                    <div>
                    <Dropdown overlay={onChangePriority}>
                      <Button block className="sideDropdown">
                        {this.state.priority_name}
                        <Icon type="down" style={{float: "right"}}/>
                      </Button>
                    </Dropdown> 
                    </div>
                    
                    <span className="sideHeading">
                      Дуусах хугацаа
                    </span>
                    <div>
                    <DatePicker
                        className="sideDropdown3"
                        showTime
                        name="deadline"
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={this.deadline}
                      />
                    </div>
                    <span className="sideHeading">
                     Хариуцагч:
                    </span>
                      <Select
                        style={{ width: '100%' }}
                        autoFocus={true}
                        maxTagCount={1}
                        value={this.state.owner_name}
                        placeholder="Add members"
                        onChange={this.handleChange}
                        optionLabelProp="label"
                      >
                        {
                          this.state.users.map(d => (
                            <Option value={d.id} label={d}>
                          <span className="select-Assistname">{d.name}</span>
                        </Option>
                          ))
                        }
                      </Select>
                    
                                        
                    <span className="sideHeading">
                      Оролцогч:
                     </span> 
                     <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      autoFocus={true}
                      maxTagCount={4}
                      placeholder="Add members"
                      onChange={this.handleChange1}
                      optionLabelProp="label"
                    >
                      {
                        this.state.users.map(d => (
                          <Option value={d.id} label={d.name}>
                        <span className="select-Assistname">{d.name}</span>
                      </Option>
                        ))
                      } 
                    </Select>
                    
                    <h2>Харилцагчийн мэдээлэл</h2>
                    <Col>  
                    <span className="sideHeading">
                      Шинэ хэрэглэгч үүсгэх
                    </span>
                    <Col><Switch defaultChecked={false} onChange={this.hide} /></Col>
                    </Col>
                    {this.state.hide === false
                    ?
                    <Col>
                    <span className="sideHeading">
                     Харилцагч
                    </span>

                      <Input.Search style={{marginBottom: '10px'}} placeholder="Хэт олон илэрц байгаа тул эхлээд 'ТОДОРХОЙ' хайна уу!" onSearch={this.search} enterButton/>

                      <Select
                        style={{ width: '100%' }}
                        autoFocus={true}
                        maxTagCount={1}
                        //value={this.state.customer_name}
                        placeholder="Хайсны дараа эндээс сонгоно уу? Мөн энд id -р хайх боломжтой."
                        onChange={this.handleChange2}
                        optionLabelProp="label"
                      >
                        {
                          this.state.customers.map(d => (
                          <Option value={d.id} label={d.name}>
                          <span className="select-Assistname">{d.name} / {d.email}</span>
                        </Option>
                          ))
                        }
                      </Select>
                    </Col>
                    :
                    <div>
                    <Col>
                    <span className="sideHeading">
                    Хэрэглэгчийн нэр
                    </span>
                    <Input name="name" type="text" onChange={this.changeHandler}></Input>
                    </Col>
                    <Col>
                    <span className="sideHeading">
                     Утасны дугаар
                    </span>
                    <Input name="phone" type="number" onChange={this.changeHandler}></Input>
                    </Col>
                    <Col>
                    <span className="sideHeading">
                     Цахим шуудангийн хаяг
                    </span>
                    <Input name="mail" type="email" onChange={this.changeHandler}></Input>
                    </Col>
                    <Col>
                    <span className="sideHeading">
                     Ваибар дугаар
                    </span>
                    <Input name="viber" type="number" onChange={this.changeHandler}></Input>
                    </Col>
                    <Col>
                    <span className="sideHeading">
                     Твиттер хаяг
                    </span>
                    <Input name="twitter" type="text" onChange={this.changeHandler}></Input>
                    </Col>
                    <Col>
                    <span className="sideHeading">
                     Мессенжер хаяг
                    </span>
                    <Input name="messenger" type="text" onChange={this.changeHandler}></Input>
                    </Col>
                    <Row >
                    <Col span={15} style={{ paddingTop: 7 }}>
                    <span>Та аль хэрэгслээр мэдээлэл хүлээн авах вэ?</span>
                    </Col>
                    </Row>
                    <Row>
                      <Col span={15} style={{ paddingTop: 7 }}>
                      <Dropdown overlay={onChangeSource}>
                          <Button block className="sideDropdown open">
                            {this.state.source_name}
                            <Icon type="down" />
                          </Button>
                        </Dropdown> 
                      </Col>
                    </Row>
                    </div>
                    }
         </Modal>
        

        {/**************** TicketNew Modal END *****************/}

        </Content>
      </Layout>
    )
  }
}

export default TicketShow;
